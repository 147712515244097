import cloneDeep from 'lodash/cloneDeep';
import * as equal from 'fast-deep-equal/es6';
import { IDxEditorToolbarItem, Operator, TFilterExpr, TFilterGrid } from '@interfaces/devextreme';
import { SelectionChangedEvent } from 'devextreme/ui/tag_box';
import * as Factory from '@factories/document.factory';
import { DateTime } from 'luxon';
import isMobile from 'ismobilejs';
import { concatMap, Observable, of, Subject, toArray } from 'rxjs';
import {
  IAgenda,
  ICustomDialog,
  IQueryObject,
  TCardPeriod,
  TEffectivePermission,
  TFieldConfigurationType
} from '@interfaces/siam';
import { custom } from 'devextreme/ui/dialog';
import * as uuid from 'uuid';
import { getCurrentUser } from './user.factory';
import { UserNotification } from '@interfaces/userNotification';
import { snakeCase as loSnakeCase } from 'lodash';
import { TreeViewItem } from '@interfaces/fieldAgenda';

export const clone = <T>(source: T): T => cloneDeep(source);

export const isFastDeepEqual = (x: unknown, y: unknown): boolean => equal(x, y);

export const isDate = (needle: unknown): boolean => {
  if (typeof needle === 'object') {
    return Object.prototype.toString.call(needle) === '[object Date]';
  }
  return false;
};

/**
 * get string length
 *
 * @param str string
 * @param target HTML element
 * @param cssClass string
 */
export const getStringLength = (str: string, target: HTMLElement | Element, cssClass?: string): number => {
  if (!target) {
    target = document.body;
  }
  const newElement = document.createElement('span');
  newElement.classList.add(cssClass ? cssClass : null);
  newElement.style.cssText =
    'display:inline-block; visibility: hidden; clip-path: circle(0); opacity: 0; white-space: nowrap;';
  newElement.innerHTML = str;
  target.appendChild(newElement);
  const elementRect = newElement.getBoundingClientRect();
  target.removeChild(newElement);
  return Math.ceil(elementRect.width);
};

/**
 * Transforms any string to two_words
 *
 * @param str
 */
export const snakeCase = (str: string): string => {
  return loSnakeCase(str);
};

/**
 * replace Umlauts in sting
 *
 * @param str
 */
export const replaceUmlaut = (str: string): string => {
  //replace all lower Umlauts
  let strResult = str.replace(/ü/g, 'ue').replace(/ö/g, 'oe').replace(/ä/g, 'ae').replace(/ß/g, 'ss');

  //first replace all capital umlaute in a non-capitalized context (e.g. Übung)
  strResult = strResult
    .replace(/Ü(?=[a-zäöüß ])/g, 'Ue')
    .replace(/Ö(?=[a-zäöüß ])/g, 'Oe')
    .replace(/Ä(?=[a-zäöüß ])/g, 'Ae');

  //now replace all the other capital umlaute
  strResult = strResult.replace(/Ü/g, 'UE').replace(/Ö/g, 'OE').replace(/Ä/g, 'AE');

  return strResult;
};

/**
 * Creates an array of values by running each element of `array` thru `iteratee`.
 * The iteratee is invoked with three arguments: (value, index, array).
 *
 * @since 5.0.0
 * @category Array
 * @param array The array to iterate over.
 * @param iteratee The function invoked per iteration.
 * @returns Returns the new mapped array.
 * @example
 *
 * function square(n) {
 *   return n * n
 * }
 *
 * map([4, 8], square)
 * // => [16, 64]
 */
const mapArray = <T>(array: unknown[], iteratee: (...args: unknown[]) => T): T[] => {
  let index = -1;
  const length = array == null ? 0 : array.length;
  const result = new Array(length) as T[];

  while (++index < length) {
    result[index] = iteratee(array[index], index, array);
  }
  return result;
};

/**
 * Creates an array of values by running each property of `object` thru
 * `iteratee`. The iteratee is invoked with three arguments: (value, key, object).
 *
 * @since 5.0.0
 * @category Object
 * @param object The object to iterate over.
 * @param iteratee The function invoked per iteration.
 * @returns Returns the new mapped array.
 * @example
 *
 * function square(n) {
 *   return n * n
 * }
 *
 * map({ 'a': 4, 'b': 8 }, square)
 * // => [16, 64] (iteration order is not guaranteed)
 */
const mapObject = <T>(object: Record<string, unknown>, iteratee: (...args: unknown[]) => T): T[] => {
  const props = Object.keys(object);
  const result = new Array(props.length) as T[];

  props.forEach((key, index) => {
    result[index] = iteratee(object[key], key, object);
  });
  return result;
};

export const mapper = <T>(source: unknown, iteratee: (...args: unknown[]) => T): T[] => {
  if (Array.isArray(source)) {
    return mapArray<T>(source, iteratee);
  } else if (typeof source === 'object') {
    return mapObject<T>(source as Record<string, unknown>, iteratee);
  }
  return [];
};

export const sortBy = <K extends string, T extends Record<K, string>>(items: T[], field: K): T[] =>
  items.sort((a: T, b: T) => {
    if (!Object.prototype.hasOwnProperty.call(a, field) || !Object.prototype.hasOwnProperty.call(b, field)) {
      return -1;
    }
    return a[field].toLowerCase()?.localeCompare(b[field].toLowerCase());
  });

// after selecting a brand, we want to clear the user's text and close the dropdown
export const onBrandSelectionChanged = (e: SelectionChangedEvent) => {
  if (e.addedItems.length > 0) {
    const input = e.component.field() as HTMLInputElement;
    if (input?.value?.length) {
      input.value = ''; // clear the text
      e.component.close();
    }
  }
};

export const editorToolbarItems = (): IDxEditorToolbarItem[] => [
  { name: 'undo', options: { text: 'Rückgängig', hint: 'Rückgängig' } },
  { name: 'redo', options: { text: 'Wiederherstellen', hint: 'Wiederherstellen' } },
  { name: 'separator' },
  { name: 'bold', options: { text: 'Fett', hint: 'Fett' } },
  { name: 'italic', options: { text: 'Kursiv', hint: 'Kursiv' } },
  { name: 'strike', options: { text: 'Durchgestrichen', hint: 'Durchgestrichen' } },
  { name: 'underline', options: { text: 'Unterstreichen', hint: 'Unterstreichen' } },
  { name: 'separator' },
  { name: 'alignLeft', options: { text: 'Links ausrichten', hint: 'Links ausrichten' } },
  { name: 'alignCenter', options: { text: 'Mittig ausrichten', hint: 'Mittig ausrichten' } },
  { name: 'alignRight', options: { text: 'Rechts ausrichten', hint: 'Rechts ausrichten' } },
  { name: 'alignJustify', options: { text: 'Blocksatz', hint: 'Blocksatz' } },
  { name: 'separator' },
  { name: 'orderedList', options: { text: 'nummerierte Liste', hint: 'nummerierte Liste' } },
  { name: 'bulletList', options: { text: 'Liste ', hint: 'Liste' } },
  { name: 'separator' },
  { name: 'clear', options: { text: 'Formatierung löschen', hint: 'Formatierung löschen' } },
  { name: 'color', options: { text: 'Farbe', hint: 'Farbe' } },
  { name: 'background', options: { text: 'Hintergrund', hint: 'Hintergrund' } },
  { name: 'link', options: { text: 'Link einfügen', hint: 'Link einfügen' } },
  ...editorToolbarItemsFont(),
  ...editorToolbarItemsTable()
];

export const editorToolbarItemsBasic = (): IDxEditorToolbarItem[] => [
  { name: 'undo', options: { text: 'Rückgängig', hint: 'Rückgängig' } },
  { name: 'redo', options: { text: 'Wiederherstellen', hint: 'Wiederherstellen' } },
  { name: 'separator' },
  { name: 'bold', options: { text: 'Fett', hint: 'Fett' } },
  { name: 'italic', options: { text: 'Kursiv', hint: 'Kursiv' } },
  { name: 'strike', options: { text: 'Durchgestrichen', hint: 'Durchgestrichen' } },
  { name: 'underline', options: { text: 'Unterstreichen', hint: 'Unterstreichen' } },
  { name: 'separator' },
  { name: 'alignLeft', options: { text: 'Links ausrichten', hint: 'Links ausrichten' } },
  { name: 'alignCenter', options: { text: 'Mittig ausrichten', hint: 'Mittig ausrichten' } },
  { name: 'alignRight', options: { text: 'Rechts ausrichten', hint: 'Rechts ausrichten' } },
  { name: 'alignJustify', options: { text: 'Blocksatz', hint: 'Blocksatz' } },
  { name: 'separator' },
  { name: 'orderedList', options: { text: 'nummerierte Liste', hint: 'nummerierte Liste' } },
  { name: 'bulletList', options: { text: 'Liste ', hint: 'Liste' } },
  { name: 'separator' },
  { name: 'clear', options: { text: 'Formatierung löschen', hint: 'Formatierung löschen' } },
  { name: 'color', options: { text: 'Farbe', hint: 'Farbe' } },
  { name: 'background', options: { text: 'Hintergrund', hint: 'Hintergrund' } },
  { name: 'link', options: { text: 'Link einfügen', hint: 'Link einfügen' } },
  { name: 'image', options: { text: 'Bild einfügen', hint: 'Bild einfügen' } }
];

export const editorToolbarItemsFont = (): IDxEditorToolbarItem[] => [
  { name: 'separator' },
  {
    name: 'header',
    acceptedValues: [
      { id: false, name: 'Normal' },
      { id: 1, name: 'Überschrift 1' },
      { id: 2, name: 'Überschrift 2' },
      { id: 3, name: 'Überschrift 3' },
      { id: 4, name: 'Überschrift 4' }
    ],
    options: {
      placeholder: 'Absatzformat',
      hint: 'Absatzformat',
      displayExpr: 'name',
      valueExpr: 'id'
    }
  },
  {
    name: 'size',
    acceptedValues: [
      { size: '14px', name: 'Standard' },
      { size: '8pt', name: '8pt' },
      { size: '10pt', name: '10pt' },
      { size: '12pt', name: '12pt' },
      { size: '14pt', name: '14pt' },
      { size: '18pt', name: '18pt' },
      { size: '24pt', name: '24pt' },
      { size: '36pt', name: '36pt' }
    ],
    options: { placeholder: 'Größe', hint: 'Größe', valueExpr: 'size', displayExpr: 'name' }
  },
  {
    name: 'font',
    acceptedValues: [
      { fonts: 'Helvetica Neue, Segoe UI, helvetica, verdana, sans-serif', name: 'Standard' },
      { fonts: 'Arial', name: 'Arial' },
      { fonts: 'Courier New', name: 'Courier New' },
      { fonts: 'Georgia', name: 'Georgia' },
      { fonts: 'Impact', name: 'Impact' },
      { fonts: 'Lucida Console', name: 'Lucida Console' },
      { fonts: 'Tahoma', name: 'Tahoma' },
      { fonts: 'Times New Roman', name: 'Times New Roman' },
      { fonts: 'Verdana', name: 'Verdana' }
    ],
    options: { placeholder: 'Schriftart', hint: 'Schriftart', valueExpr: 'fonts', displayExpr: 'name' }
  }
];

export const editorToolbarItemsTable = (): IDxEditorToolbarItem[] => [
  { name: 'separator' },
  { name: 'insertTable', options: { text: 'Tabelle einfügen', hint: 'Tabelle einfügen' } },
  { name: 'deleteTable', options: { text: 'Tabelle löschen', hint: 'Tabelle löschen' } },
  { name: 'insertRowAbove', options: { text: 'Zeile oben einfügen', hint: 'Zeile oben einfügen' } },
  { name: 'insertRowBelow', options: { text: 'Zeile unten einfügen', hint: 'Zeile unten einfügen' } },
  { name: 'deleteRow', options: { text: 'Zeile löschen', hint: 'Zeile löschen' } },
  { name: 'insertColumnLeft', options: { text: 'Spalte davor einfügen', hint: 'Spalte davor einfügen' } },
  {
    name: 'insertColumnRight',
    options: { text: 'Spalte dahinter einfügen', hint: 'Spalte dahinter einfügen' }
  },
  { name: 'deleteColumn', options: { text: 'Spalte löschen', hint: 'Spalte löschen' } }
];

export const replacer = (source: unknown) => {
  let i = 0;

  // eslint-disable-next-line
  return function (this: unknown, key: string, value: unknown) {
    if (i !== 0 && typeof source === 'object' && typeof value == 'object' && source === value) return '[Circular]';

    if (i >= 50)
      // seems to be a harded maximum of 30 serialized objects?
      return '[Unknown]';

    ++i; // so we know we aren't using the original object anymore

    return value;
  };
};

/**
 * Functions to convert DevExtreme filter to Lucene query
 *
 * START
 */

export const isHasProperty = (source: unknown, needle: string): boolean => {
  if (source) {
    return !!Object.prototype.hasOwnProperty.call(source, needle);
  }
  return false;
};

export const getDate = (source: string): string => {
  if (!source) {
    return '*';
  }
  return dateToIsoString(new Date(source)).split('T')[0];
};

export const stringEscaping = <T = string>(source: T): T => {
  if (typeof source === 'string') {
    return source?.replace(/[-+ /&|!(){}[\]^"~*?:\\]/g, '\\$&').trim() as T;
  }
  return source;
};

// eslint-disable-next-line
const each = (
  values: unknown[] | Record<string, unknown>,
  // eslint-disable-next-line
  callback: Function
): unknown[] | Record<string, unknown> | void => {
  if (!values) {
    return;
  }
  if ('length' in values) {
    const arr = values as unknown[];
    const length = arr.length;
    for (let i = 0; i < length; i++) {
      if (false === callback.call(arr[i], i, arr[i])) {
        break;
      }
    }
  } else {
    for (const key in values) {
      if (false === callback.call(values[key], key, values[key])) {
        break;
      }
    }
  }
  return values;
};

interface ICreator {
  prop: string | string[];
  val: unknown;
}

const fieldCreator = (property: string, val: unknown): ICreator | null => {
  let prop: string | string[];
  if (Factory.checkIfValidUUID(val as string) || Factory.isCompositeId(val as string)) {
    switch (property) {
      case 'configuration-creator':
        prop = 'creation.userId';
        break;

      case 'configuration-last-write':
        prop = 'last_write.userId';
        break;

      case 'configuration-assignee':
        prop = 'documentWorkflowDocument.currentAssignee';
        break;

      case 'configuration-voter-given':
        {
          prop = 'ballots.active.votes.given.member';
        }
        break;

      case 'configuration-voter-missing':
        {
          prop = 'ballots.active.votes.missing.member';
        }
        break;

      default:
        return null;
    }
  } else {
    if (!getCurrentUser()) {
      return null;
    }

    const userId = getCurrentUser().id;

    switch (property) {
      case 'creatorNameWithAvatar':
        prop = ['creation.user.surname', 'creation.user.forename', 'creation.user.name'];
        break;

      case 'configuration-wf-status':
        prop = ['documentWorkflowDocument.currentStatusLabel', 'documentWorkflowDocument.currentStatus'];
        break;

      case 'currentAssignee':
        {
          prop = [
            'documentWorkflowDocument.currentAssignee.forename',
            'documentWorkflowDocument.currentAssignee.surname',
            'documentWorkflowDocument.currentAssignee.name'
          ];
          if (val === '[Leer]') {
            prop = 'documentWorkflowDocument.currentAssignee';
          }
        }
        break;

      case 'configuration-creator':
        {
          if (val === 'current-user') {
            prop = 'creation.userId';
            val = userId;
          }
        }
        break;

      case 'configuration-last-write':
        {
          if (val === 'current-user') {
            prop = 'last_write.userId';
            val = userId;
          }
        }
        break;

      case 'configuration-voter-given':
        {
          if (val === 'current-user') {
            prop = 'ballots.active.votes.given.member';
            val = userId;
          }
        }
        break;

      case 'configuration-voter-missing':
        {
          if (val === 'current-user') {
            prop = 'ballots.active.votes.missing.member';
            val = userId;
          }
        }
        break;

      case 'configuration-assignee':
        {
          if (val === 'current-user') {
            prop = 'documentWorkflowDocument.currentAssignee';
            if (getCurrentUser().rolesIds?.length) {
              val = [getCurrentUser().compositeId].concat(getCurrentUser().rolesIds);
            } else {
              val = getCurrentUser().compositeId;
            }
          }
        }
        break;

      case 'configuration-current-user-field':
        {
          if ((val as string).startsWith('fields.')) {
            prop = val as string;

            if (getCurrentUser().rolesIds?.length) {
              val = [getCurrentUser().compositeId].concat(getCurrentUser().rolesIds);
            } else {
              val = getCurrentUser().compositeId;
            }
          }
        }
        break;

      default:
        return null;
    }
  }
  return {
    prop,
    val
  };
};
const handleExceptionsStringFormatter = (property: string, val: unknown): ICreator | null => {
  let prop: string | string[];

  switch (property) {
    case 'configuration-wf-status':
      prop = ['documentWorkflowDocument.currentStatusLabel', 'documentWorkflowDocument.currentStatus'];
      break;
    case 'creation.userId':
    case 'creatorNameWithAvatar':
      prop = ['creation.user.surname', 'creation.user.forename', 'creation.user.name'];
      break;
    case 'documentWorkflowDocument.currentAssignee':
      prop = [
        'documentWorkflowDocument.currentAssignee.surname',
        'documentWorkflowDocument.currentAssignee.forename',
        'documentWorkflowDocument.currentAssignee.name'
      ];
      break;
    default:
      return null;
  }

  return {
    prop,
    val
  };
};

export const queryAdapter = function (fieldMapper: (selector: string) => string): (criteria: TFilterGrid) => string {
  const isConjunctiveOperator = (condition: string): boolean => /^(and|&&|&)$/i.test(condition);

  const isUnaryOperation = (criteria: TFilterExpr): boolean => '!' === criteria[0] && Array.isArray(criteria[1]);

  const normalizeBinaryCriterion = (criteria: TFilterExpr): TFilterExpr =>
    <[string, Operator, string]>[
      criteria[0],
      criteria.length < 3 ? '=' : String(criteria[1]).toLowerCase(),
      criteria.length < 2 ? true : criteria[criteria.length - 1]
    ];

  const normalizeValue = (val: string): string => {
    if (typeof val === 'string') {
      if (val === '[Leer]') {
        return '[* TO *]';
      }
      return JSON.stringify(val.trim());
    }
    return val;
  };

  const createBinaryOperationFormatter = (op: string): ((property: string, val: string) => string) => {
    return function (property: string, val: string) {
      const creator = fieldCreator(property, val);
      let prop: string | string[];
      if (creator) {
        prop = creator.prop;
        val = creator.val as string;
      } else {
        const field = fieldMapper(property);
        if (field) {
          prop = field;
        }
      }

      if (val === null) {
        return `(creation.timestamp:[* TO *] NOT ${prop as string}:[* TO *])`;
      }

      switch (op) {
        case '=': {
          val = normalizeValue(val);

          if (Array.isArray(prop)) {
            return `(${prop.map(p => `${p}:${val}`).join(' OR ')})`;
          }

          if (Array.isArray(val)) {
            return `(${val.map(v => `${prop as string}:${JSON.stringify(v)}`).join(' OR ')})`;
          }
          return `${prop}:${val}`;
        }

        case '<>': {
          val = normalizeValue(val);

          if (Array.isArray(prop)) {
            return `(creation.timestamp:[* TO *] NOT (${prop.map(p => `${p}:${val}`).join(' OR ')}))`;
          }

          if (Array.isArray(val)) {
            return `(creation.timestamp:[* TO *] NOT (${val
              .map(v => `${prop as string}:${JSON.stringify(v)}`)
              .join(' OR ')}))`;
          }

          return `(creation.timestamp:[* TO *] NOT ${prop}:${val})`;
        }

        case '>=':
          return `${prop as string}:[${getDate(val)} TO *]`;

        case '>':
          return `${prop as string}:{${getDate(val)} TO *]`;

        case '<=':
          return `${prop as string}:[* TO ${getDate(val)}]`;

        case '<':
          return `${prop as string}:[* TO ${getDate(val)}}`;
      }
      return '';
    };
  };

  const createStringFuncFormatter = (op: string): ((prop: string, val: string | string[]) => string) =>
    function (property: string, val: string | string[]): string {
      let prop: string | string[];
      const exception = handleExceptionsStringFormatter(property, val);
      if (exception) {
        prop = exception.prop;
        val = exception.val as string;
      } else {
        const field = fieldMapper(property);
        if (field) {
          prop = field;
        }
      }
      if (Array.isArray(val)) {
        val = val.map(v => stringEscaping(v));
      } else {
        val = stringEscaping(val);
      }

      if (!prop) {
        return '';
      }

      switch (op) {
        case 'startswith':
          if (Array.isArray(prop)) {
            return `(${prop.map(p => `${p}:${val as string}*`).join(' OR ')})`;
          }
          return `${prop}:${val as string}*`;

        case 'endswith':
          if (Array.isArray(prop)) {
            return `(${prop.map(p => `${p}:*${val as string}`).join(' OR ')})`;
          }
          return `${prop}:*${val as string}`;

        case 'contains':
          if (Array.isArray(prop)) {
            return `(${prop.map(p => `${p}:*${val as string}*`).join(' OR ')})`;
          }
          return `${prop}:*${val as string}*`;

        case 'notcontains':
          if (Array.isArray(prop)) {
            return `(${prop.map(p => `(creation.timestamp:[* TO *] NOT ${p}:*${val as string}*)`).join(' OR ')})`;
          }
          return `(creation.timestamp:[* TO *] NOT ${prop}:*${val as string}*)`;

        case 'between':
          if (Array.isArray(prop)) {
            return `(${prop.map(p => `${p}:[${getDate(val[0])} TO ${getDate(val[1])}]`).join(' OR ')})`;
          }
          return `${prop}:[${getDate(val[0])} TO ${getDate(val[1])}]`;

        default:
          return '';
      }
    };

  const formatters: Record<string, (prop: string, val: string) => string> = {
    '=': createBinaryOperationFormatter('='),
    '<>': createBinaryOperationFormatter('<>'),
    '>': createBinaryOperationFormatter('>'),
    '>=': createBinaryOperationFormatter('>='),
    '<': createBinaryOperationFormatter('<'),
    '<=': createBinaryOperationFormatter('<='),
    startswith: createStringFuncFormatter('startswith'),
    endswith: createStringFuncFormatter('endswith'),
    contains: createStringFuncFormatter('contains'),
    notcontains: createStringFuncFormatter('notcontains'),
    between: createStringFuncFormatter('between')
  };

  const compileBinary = (criteria: TFilterExpr): string => {
    criteria = normalizeBinaryCriterion(criteria);
    const op = criteria[1];
    const fieldName = criteria[0];
    const formatter = formatters[op.toLowerCase()];
    const value = criteria[2];

    if (!formatter) {
      throw new Error(`Falscher Operator verwendet: ${op}`);
    }

    return formatter(fieldName, value);
  };

  const compileUnary = (criteria: TFilterExpr): string => {
    const op = criteria[0];
    const crit = compileCore(criteria[1]);

    if ('!' === op) {
      return 'creation.timestamp:[* TO *] NOT ('.concat(crit, ')');
    }
    throw new Error(`Falscher Operator verwendet: ${op}`);
  };

  const compileGroup = (criteria: TFilterExpr): string => {
    const bag: string[] = [];
    let groupOperator: string;
    let nextGroupOperator: string;
    each(criteria, function (index: number, criterion: TFilterExpr) {
      if (Array.isArray(criterion)) {
        if (bag.length > 1 && groupOperator !== nextGroupOperator) {
          throw new Error('Criterion ist falsch');
        }
        bag.push('('.concat(compileCore(criterion), ')'));
        groupOperator = nextGroupOperator;
        nextGroupOperator = 'AND';
      } else {
        nextGroupOperator = isConjunctiveOperator(this as string) ? 'AND' : 'OR';
      }
    });
    return bag.join(' '.concat(groupOperator, ' '));
  };

  const compileCore = function (criteria: TFilterGrid | Operator): string {
    if (Array.isArray(criteria[0])) {
      return compileGroup(criteria as TFilterExpr);
    }
    if (isUnaryOperation(criteria as TFilterExpr)) {
      return compileUnary(criteria as TFilterExpr);
    }
    return compileBinary(criteria as TFilterExpr);
  };

  return function (criteria: TFilterGrid): string {
    if (!criteria) {
      return '';
    }
    return compileCore(criteria);
  };
};

export const combineQueries = (query1: string, query2: string, condition: 'and' | 'or'): string => {
  const q1 = query1?.trim();
  const q2 = query2?.trim();
  if (!q1 && !q2) {
    return '';
  } else if (!q1 && q2) {
    return q2;
  } else if (q1 && q2) {
    return `((${q1}) ${condition.toUpperCase()} (${q2}))`;
  }
  return q1;
};

const isFilterItem = (item: TFilterGrid): boolean => {
  switch (item[1]) {
    case Operator.equals:
    case Operator.notequals:
    case Operator.contains:
    case Operator.endsWith:
    case Operator.greaterOrEquals:
    case Operator.greaterThan:
    case Operator.startsWith:
    case Operator.smallerOrEquals:
    case Operator.smallerThan:
      return true;
    default:
      return false;
  }
};

export const extractFieldFromFilter = (filter: TFilterGrid, result: string[]): void => {
  if (!Array.isArray(filter)) {
    return;
  }
  const iterator = filter[Symbol.iterator]();
  let next = iterator.next();
  while (!next.done) {
    const item = next.value as TFilterGrid;
    if (Array.isArray(item)) {
      if (isFilterItem(item)) {
        result.push(item[0] as string);
      } else {
        if (Array.isArray(item[0])) {
          extractFieldFromFilter(item, result);
        } else {
          extractFieldFromFilter(item, result);
        }
      }
    } else if (item === 'and' || item === 'or') {
      // nothing to do
    } else if (item === '!') {
      next = iterator.next();
      if (next.value) {
        extractFieldFromFilter(next.value as TFilterGrid, result);
      }
    } else {
      result.push(item);
      return;
    }

    if (!next.done) {
      next = iterator.next();
    }
  }
};

export const extractFirstValue = (filter: TFilterGrid): unknown | null => {
  if (!Array.isArray(filter)) {
    return null;
  }
  const iterator = filter[Symbol.iterator]();
  let next = iterator.next();
  while (!next.done) {
    const item = next.value as TFilterGrid;
    if (Array.isArray(item)) {
      if (isFilterItem(item)) {
        return item[2];
      } else {
        if (Array.isArray(item[0])) {
          return extractFirstValue(item);
        } else {
          return extractFirstValue(item);
        }
      }
    }

    if (!next.done) {
      next = iterator.next();
    }
  }
  return null;
};

export const createQueryObject = (): IQueryObject => ({
  and: {
    include: [] as string[],
    exclude: [] as string[]
  },
  or: {
    include: [] as string[],
    exclude: [] as string[]
  }
});

export const queryObjectToLucene = (queryObject: IQueryObject): string => {
  if (!queryObject) {
    return '';
  }

  const result: string[] = [];
  if (queryObject.and.include.length) {
    result.push(`(${queryObject.and.include.join(' AND ')})`);
  }

  if (queryObject.or.include.length) {
    result.push(`(${queryObject.or.include.join(' OR ')})`);
  }

  if (queryObject.and.exclude.length) {
    const arr = queryObject.and.exclude.map(exclude => `(creation.timestamp:[* TO *] NOT (${exclude}))`).join(' AND ');
    result.push(`(${arr})`);
  }

  if (queryObject.or.exclude.length) {
    const arr = queryObject.and.exclude.map(exclude => `(creation.timestamp:[* TO *] NOT (${exclude}))`).join(' OR ');
    result.push(`(${arr})`);
  }

  return result.join(' AND ');
};

export const dateToLocal = (datetime: string, format: string): string =>
  DateTime.fromISO(datetime).setLocale('de').toFormat(format);
export const dateToIsoString = (date: Date): string => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number): string => (num < 10 ? '0' : '').concat(num.toString());

  return (
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};
export const isJsonString = (str: string): boolean => {
  try {
    return !(typeof str === 'number' || typeof JSON.parse(str) === 'number');
  } catch (e) {
    return false;
  }
};

export const isMobileDevice = (): boolean => isMobile(window.navigator).any;

export const richTextPtoDiv = (source: string): string => {
  const editor = document.createElement('div');
  editor.innerHTML = source;
  const target = document.createElement('div');
  Array.from(editor.children).forEach(node => {
    if (node.nodeType === 1) {
      if (node.tagName.toLowerCase() === 'p') {
        const div = document.createElement('div');
        div.style.cssText = (node as HTMLElement).style.cssText;
        div.innerHTML = node.innerHTML;
        target.appendChild(div);
      } else {
        target.appendChild(node.cloneNode(true));
      }
    } else {
      target.appendChild(node.cloneNode(true));
    }
  });
  return target.innerHTML;
};

export const resolveSubject = <T>(value: T, subject$?: Subject<T>): void => {
  if (subject$) {
    subject$.next(value);
    subject$.complete();
  }
};

export const closeSaveDialog = (
  title: string,
  messageHtml: string,
  closeSubject$: Subject<boolean>,
  saveMethod: (subject$: Subject<boolean>) => void
): ICustomDialog<boolean> =>
  custom({
    title,
    messageHtml,
    buttons: [
      {
        text: `Speichern und Schließen`,
        icon: 'floppy',
        onClick: () => {
          saveMethod(closeSubject$);
        }
      },
      {
        text: 'Schließen',
        icon: 'close',
        onClick: () => {
          resolveSubject<boolean>(true, closeSubject$);
        }
      },
      {
        text: 'Zurück',
        icon: 'material-icons md-24 output',
        onClick: () => {
          resolveSubject<boolean>(false, closeSubject$);
        }
      }
    ]
  }) as ICustomDialog<boolean>;
export const closeSaveDialogAgenda = (
  title: string,
  messageHtml: string,
  closeSubject$: Subject<boolean>,
  saveMethod: (subject$: Subject<boolean>) => void
): ICustomDialog<boolean> =>
  custom({
    title,
    messageHtml,
    buttons: [
      {
        text: `Speichern`,
        icon: 'floppy',
        onClick: () => {
          saveMethod(closeSubject$);
        }
      },
      {
        text: 'Abbrechen',
        icon: 'close',
        onClick: () => {
          resolveSubject<boolean>(true, closeSubject$);
        }
      }
    ]
  }) as ICustomDialog<boolean>;

export const actionDialog = (
  title: string,
  messageHtml: string,
  closeSubject$: Subject<boolean>,
  saveExecuteMethod: (subject$: Subject<boolean>) => void,
  executeMethod: () => void
): ICustomDialog<boolean> =>
  custom({
    title,
    messageHtml,
    buttons: [
      {
        text: `Speichern und Fortfahren`,
        icon: 'floppy',
        onClick: () => {
          saveExecuteMethod(closeSubject$);
        }
      },
      {
        text: 'Fortfahren ohne Speichern',
        icon: 'check',
        onClick: () => {
          executeMethod();
        }
      },
      {
        text: 'Zurück',
        icon: 'material-icons md-24 output',
        onClick: () => {
          resolveSubject<boolean>(false, closeSubject$);
        }
      }
    ]
  }) as ICustomDialog<boolean>;

export const refreshDialog = (
  title: string,
  messageHtml: string,
  closeSubject$: Subject<boolean>,
  saveExecuteMethod: (subject$: Subject<boolean>) => void,
  executeMethod: () => void
): ICustomDialog<boolean> =>
  custom({
    title,
    messageHtml,
    buttons: [
      {
        text: 'Speichern und neu laden',
        icon: 'floppy',
        onClick: () => {
          saveExecuteMethod(closeSubject$);
        }
      },
      {
        text: 'Neu laden',
        icon: 'pulldown',
        onClick: () => {
          executeMethod();
        }
      },
      {
        text: 'Zurück',
        icon: 'material-icons md-24 output',
        onClick: () => {
          resolveSubject<boolean>(false, closeSubject$);
        }
      }
    ]
  }) as ICustomDialog<boolean>;
export const deleteTOPDialog = (
  title: string,
  messageHtml: string,
  closeSubject$: Subject<boolean>,
  deleteMethod: (subject$: Subject<boolean>) => void,
  parkMethod: (subject$: Subject<boolean>) => void
): ICustomDialog<boolean> =>
  custom({
    title,
    messageHtml,
    buttons: [
      {
        text: 'Löschen',
        onClick: () => {
          deleteMethod(closeSubject$);
        }
      },
      {
        text: 'Parken',
        onClick: () => {
          parkMethod(closeSubject$);
        }
      },
      {
        text: 'Abbrechen',
        onClick: () => {
          resolveSubject<boolean>(false, closeSubject$);
        }
      }
    ]
  }) as ICustomDialog<boolean>;

export const convertDevexpressDataType = (
  source: 'string' | 'number' | 'date' | 'boolean' | 'datetime' | 'userRole' | 'object',
  isGroup = false
): TFieldConfigurationType => {
  switch (source) {
    case 'string':
      return 'text';

    case 'number': {
      if (isGroup) {
        return 'double';
      }
      return 'int';
    }

    case 'userRole': {
      if (isGroup) {
        return 'references';
      }
      return 'userRole';
    }

    case 'date':
    case 'datetime': {
      if (isGroup) {
        return 'timestamp';
      }
      return source;
    }

    default:
      return (source as TFieldConfigurationType) || 'text';
  }
};

/**
 * Create random value, which can be used in the DOM
 * Since ID can't start from the digit, it must have some prefix with letters
 */
export const getDomId = (): string => `id-${uuid.v4()}`;

export const flatArray = ({ children = [] as IAgenda[], ...o }): IAgenda[] => [
  o as IAgenda,
  ...children.flatMap(flatArray)
];

export const replaceUpperCaseWithDash = (str: string): string =>
  str.replace(/[A-Z]/g, (match: string) => '-' + match.toLowerCase());

export const isCssSupperted = (obj: Record<string, string>): boolean => {
  for (const [key, value] of Object.entries(obj)) {
    if (!CSS.supports(key, value)) {
      return false;
    }
  }
  return true;
};
export const isObject = (obj: Record<string, string>): boolean =>
  obj !== undefined && obj !== null && obj.constructor === Object;

export const isUndefinedValue = (value: unknown): boolean => value === undefined || value === null;

export const getTagName = (key: string): string => {
  let result: string = null;
  if (key) {
    result = key.substring(key.lastIndexOf(':') + 1) || null;
  }
  return result;
};

export const getCurrentWeek = (): { start: Date; end: Date } => {
  const now = new Date();
  const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
  const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (6 - now.getDay()));

  return {
    start: startOfWeek,
    end: endOfWeek
  };
};
export const getCurrentMonth = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const firstDay = new Date(currentYear, currentMonth, 1);
  const lastDay = new Date(currentYear, currentMonth + 1, 0);

  return {
    start: firstDay,
    end: lastDay
  };
};

export const getDateRange = (rangeType: TCardPeriod): { startDate: Date | 'infinite'; endDate: Date | 'infinite' } => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
  const currentHour = currentDate.getHours(); // Sunday: 0, Monday: 1, ..., Saturday: 6
  const daysToAdd = currentDay === 0 ? 6 : currentDay - 1; // Adjust for Sunday

  let startDate: Date | 'infinite';
  let endDate: Date | 'infinite';
  switch (rangeType) {
    case 'previousWeek':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - daysToAdd - 7);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() - currentDay);
      break;
    case 'currentWeek':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - daysToAdd);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() - currentDay + 7);
      break;
    case 'nextWeek':
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - daysToAdd + 7);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + (8 - currentDay) + 6);
      break;
    case 'previousTwoWeek':
      endDate = new Date(currentDate);
      startDate = new Date(currentDate);
      startDate.setDate(endDate.getDate() - 14);
      break;

    case 'nextTwoWeek':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 14);
      break;
    case 'previousThirtyDays':
      endDate = new Date(currentDate);
      startDate = new Date(currentDate);
      startDate.setDate(endDate.getDate() - 30);
      break;
    case 'nextThirtyDays':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 30);
      break;
    case 'currentThirtyDays':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 15);
      startDate.setDate(currentDate.getDate() - 15);
      break;
    case 'currentSixtyDays':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 30);
      startDate.setDate(currentDate.getDate() - 30);
      break;
    case 'currentOneHundredEightyDays':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 90);
      startDate.setDate(currentDate.getDate() - 90);
      break;
    case 'currentThreeHundredSixtyDays':
      startDate = new Date(currentDate);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 180);
      startDate.setDate(currentDate.getDate() - 180);
      break;
    case 'currentMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      break;
    case 'nextThreeMonth':
      startDate = new Date();
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);
      break;
    case 'nextSixMonth':
      startDate = new Date();
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 0);
      break;
    case 'previousThreeMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
      endDate = new Date();
      break;
    case 'previousSixMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1);
      endDate = new Date();
      break;
    case 'currentYear':
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = new Date(currentDate.getFullYear(), 11, 31);
      break;
    case 'future':
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = 'infinite';
      break;
    case 'past':
      startDate = 'infinite';
      endDate = new Date(currentDate.getFullYear(), 0, 1);
      break;
    // Add more cases for other range types
    default:
      throw new Error('Invalid range type');
  }
  if (startDate instanceof Date) {
    startDate.setHours(currentHour);
  }
  if (endDate instanceof Date) {
    endDate.setHours(currentHour);
  }

  return {
    startDate: startDate,
    endDate: endDate
  };
};
export const getDataPalette = (): { arg: string; val: number }[] => {
  return new Array(20).fill(1).map((val, index) => ({
    arg: `Item${index}`,
    val: val as number
  }));
};

export const isValidNotification = (data: UserNotification): boolean => {
  if (data.validSince && data.validUntil) {
    const dateSince = new Date(data.validSince);
    const dateUntil = new Date(data.validUntil);
    const currentDate = new Date();
    return dateSince < currentDate && currentDate < dateUntil;
  }
  return false;
};

export const convertToMillimeters = (value: number, unit: string): number => {
  const conversionFactors: Record<string, number> = {
    px: 0.264583, // 1 pixel = 0.264583 mm
    cm: 10, // 1 centimeter = 10 mm
    mm: 1, // 1 millimeter = 1 mm
    in: 25.4, // 1 inch = 25.4 mm
    pt: 0.352778, // 1 point = 0.352778 mm
    pc: 4.23333, // 1 pica = 4.23333 mm
    em: 16, // 1 em = 16 pixels (assuming a typical 16px font-size)
    rem: 16, // 1 rem = 16 pixels (relative to the font-size of the root element)
    vw: 10, // 1vw = 10 mm (assuming a viewport width of 100mm)
    vh: 10 // 1vh = 10 mm (assuming a viewport height of 100mm)
  };
  // Check if the unit is in the conversion factors, if not, return the original value
  if (isHasProperty(conversionFactors, unit)) {
    return value * conversionFactors[unit];
  } else {
    return value;
  }
};

export const isNotEmptyObject = (objectName: Record<string, string>) => {
  return isObject(objectName) && Object.keys(objectName)?.length;
};

export const flattAgendaHierarchy = (hierarchicalArray: TreeViewItem[]): TreeViewItem[] => {
  if (!hierarchicalArray?.length) {
    return [];
  }
  const flatArray: TreeViewItem[] = [];
  hierarchicalArray.forEach(item => {
    const flattenedItem = {
      ...item,
      children: [] as TreeViewItem[]
    };

    flatArray.push(flattenedItem);

    if (item.children && item.children.length > 0) {
      const childrenFlatArray = flattAgendaHierarchy(item.children);
      flatArray.push(...childrenFlatArray);
    }
    if (item.tasks && item.tasks.length > 0) {
      const childrenFlatArray = flattAgendaHierarchy(item.tasks);
      flatArray.push(...childrenFlatArray);
    }
    if (item.submissions && item.submissions.length > 0) {
      const childrenFlatArray = flattAgendaHierarchy(item.submissions);
      flatArray.push(...childrenFlatArray);
    }
    if (item.protocol) {
      flatArray.push(item.protocol);
    }
  });
  const removeDuplicates = (itemsArray: TreeViewItem[]): TreeViewItem[] => {
    const seen = new Set();
    return itemsArray.filter(item => {
      const id = item.id || (item.documentId as string);
      if (id) {
        if (seen.has(id)) {
          return false;
        }
        seen.add(id);
        return true;
      }
      return true;
    });
  };
  // Aufgaben und TOP-Protokolle ohne Parent element enfernen
  const result = [...new Set(flatArray)].filter(item => item.type !== 'top-submissions');
  return removeDuplicates(result);
};

export const processObservablesRecursively = (observables: Observable<unknown>[]): Observable<unknown[]> => {
  if (observables.length === 0) {
    // If there are no more observables, return an observable of an empty array
    return of([] as unknown[]);
  } else {
    // Take the first observable from the array
    const firstObservable = observables[0];
    // Recurse with the rest of the observables in the array
    const remainingObservables = observables.slice(1);
    // Use concatMap to process the first observable and concatenate it with the results of the recursive call
    return firstObservable.pipe(
      concatMap(response => {
        // Process the response if necessary
        // For example, you can map the response to extract specific data
        // response = response.someProperty;
        // Recurse with the rest of the observables and concatenate the current response to the array
        return processObservablesRecursively(remainingObservables).pipe(
          // Use toArray to collect the responses into an array and complete the observable
          toArray(),
          // Concatenate the current response to the array of responses
          concatMap(responses => of(([...responses, response] as unknown[]).flat()))
        );
      })
    );
  }
};

export const comparePermissions = (array1: TEffectivePermission[], array2: TEffectivePermission[]): boolean => {
  const requiredPermissions: TEffectivePermission[] = ['read', 'update'];

  const containsRequiredActions = (array: TEffectivePermission[]): boolean => {
    return requiredPermissions.every(action => array.includes(action));
  };

  // Compare both arrays
  const array1HasPermissions = containsRequiredActions(array1);
  const array2HasPermissions = containsRequiredActions(array2);

  // Return whether both arrays match based on the required actions
  return array1HasPermissions === array2HasPermissions;
};
/**
 * END functions
 */
