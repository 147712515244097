<aside class="left-side" [class.left-side__menu-icons]="isNavHidden">
  <div class="dashboard top" [class.dashboard--hidden]="isDashboardHidden">
    <div (click)="toggleDashboard()" class="dashboard_toggler">
      <i class="material-icons">space_dashboard</i>
    </div>
    <dx-scroll-view height="100%">
      <div class="dashboard_content">
        @if (isLoaded$ | async) {
        <div class="dashboard_grid">
          <ng-container *ngFor="let card of cards">
            <div
              class="dashboard_card dashboard_card__small"
              [style.--card-color]="card.settings.cardColor"
              (click)="openInfoPopup(card)">
              <div class="dashboard_card_icon">
                <i class="material-icons md-36">{{ card.settings.icon }}</i>
              </div>
              <div class="dashboard_card_titel" title="{{ card.settings.text }} | {{ card.settings.subText }}">
                {{ card.settings.text }}
                <span class="dashboard_card_subtitel">{{ card.settings.subText }}</span>
              </div>
              <div class="dashboard_card_count">
                {{ card.documentCount }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="dashboard_grid">
          <ng-container *ngFor="let chart of bigCards">
            <div class="dashboard_card dashboard_card__big" [ngSwitch]="chart.type">
              <app-scheduler
                *ngSwitchCase="'calendar'"
                [card]="chart"
                [events]="eventsScheduler.asObservable()"
                class="dashboard-calendar"></app-scheduler>
              <app-chart
                *ngSwitchCase="'chart'"
                [chart]="chart"
                [lists]="lists"
                [users]="users"
                [roles]="roles"></app-chart>
            </div>
          </ng-container>
        </div>
        } @else {
        <div class="dashboard-loading">
          <svg
            version="1.1"
            id="cockpitLoader"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 1322.6 788.5"
            xml:space="preserve">
            <style>
              .st0 {
                fill: currentColor;
              }
            </style>
            <g id="card" opacity="1">
              <path
                class="st0"
                d="M1273 364.1c-41.6-4.2-28.4 15.4-22 15.7 41.6 4.2 28.5-15.4 22-15.7zM1216 365.4c-13.8-2.1-39.6-2-22.6 14.5 39.9 7.4 30.6-13.1 22.6-14.5z" />
              <path
                class="st0"
                d="m1137.1 418.4 158.7-2.8c3.4 5.3 12.7 3.8 12.7-4.5V146.6c0-2.2-.7-3.9-1.7-5.2 4.3-18-31.1-7.2-68.2-9.1-126.6 2.2-174.1 3.1-291.1 5.1-22.2-14.2-5.8 130.7-10.2 135.9 0 8.6.4 95.3.5 125.3.3 17.4-6.8 24.6 13.2 23 76.1-1.3 140.4-2.4 186.1-3.2zm157.5-18.5-342.8 6c-.2-42.6-.3-85.2-.5-127.8 114.4 6.2 229 4.5 343.3-5.2v127zm0-142.7c-114.3 9.7-228.9 11.4-343.4 5.2l-.3-64.1c114-1.4 228.1-2.7 342.1-4.1.5 0 1-.1 1.5-.2.1 21.1.1 42.2.1 63.2zm0-78.6c-114 .9-229.4 2.8-343.7 4 .2-44.7-7-25.4 39-30.2 138.8-2.4 205.5-3.6 304.7-5.4v31.6z" />
              <animate attributeName="opacity" begin="0s" dur="3s" values="1;1;1;0.2" repeatCount="indefinite" />
              <animateTransform
                attributeName="transform"
                begin="0s"
                dur="3s"
                type="translate"
                values="0,0; -700,0;"
                repeatCount="indefinite" />
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="scale"
                dur="3s"
                values="1;1.1;0.9"
                repeatCount="indefinite"
                additive="sum" />
            </g>
            <g id="pc">
              <path
                class="st0"
                d="M726.1 6.7c-188.7-7.9-378-8.6-567.2-3.4C-32.6.1 11.6 30.5.7 185.6c-3.7 418.2 7.9 382 28 402.5 35.9 20.8 79 8.5 119 13.7 115.8 3.5 226.7 3.9 341.4 2.4 255.8-5.6 259.6 0 281.5-32.1 11.8-18.1 8.3-39.1 10.1-60.3 3.5-82.7 4.9-163.2 4.3-245.9-3.8-213-1.9-225.1-24.8-245.3-10.5-12-52-23.6-41.4 1.6 61.1 12.3 38 72.4 44.7 117.4 4.4 136 3.6 268.2-3.3 404.6-3.7 36.5-32.5 35.3-59.1 35.8-33.9 1.2-66.2 2.1-100.2 3-182 4.5-363.6 4.8-545.4-3.8 1.7.2-1.6-.3-1.5-.3-1.3-.4.2.4-6.8-2-29.1-13.1-21.8-43.8-24-69.7-1.9-47.8-3.1-90.3-3.8-137.5C18 16.7 25.8 48.3 38.5 33.4 51 23.2 65 26 81.8 25c145.6-5.4 287.9-7.2 433-5.1 144.8.2 257.7 17.3 211.3-13.2z" />
              <path
                class="st0"
                d="M69.7 486.3c-2.2-139.6-3.8-279.7-.4-419.7l-9.5 9.5c221.4-2.8 443-2.6 664.7-.1l-9.5-9.5c-.8 139.5-.8 279.5 0 419.4l9.5-9.5c-158.7 2.8-318.3 4.6-477.4 3.3-185.9.1-197.2-10.6-196.1 8.7 4.6 14.6 37.5 5.3 46.9 8.2 188.5 3.9 374.6 2.7 562.9-.2 78.1-2.3 74.2 8 73.1-34.4-.3-68.3-.5-133.1-.5-201.2-3.9-257.5 28.3-195.7-83.7-204.6-197-1.7-392.8-1.7-589.8.9-20.1-4-7.3 131.5-11.4 137.9 1.4 250.4-5.2 333.6 21.2 291.3zM404.2 545.7c-.4 3 .9-1.1-.9 4.1 2.4-3.5-3.9 4.3-1.5 2-.6 1.2-6.6 2.5-3.9 2.2 1.1.7-7.7-1.1-4.7-.2-3.5-2.4-1.1.3-4.3-3.3 2.7 3.8-2.4-4.5-.4-.6-.8-.4-.8-7.1-1-4.5-.5-1 3.2-6.9 1.4-4.3.5.4 7.6-10.9 15.2 2.8 1.4 19.9 25.9 11.1 17.5-7-11.3-30-51.5-20.3-52.9 7.8-2.6 25.3 33.1 38.7 48.4 18.4 18.5-24.8-9.5-36.2-12.9-17.4zM316.4 596.7c-.1 2.4-1.2 12.9-1.4 14.8-2 17.3-9.8 45.2-15.8 58.7-5.6 15.4-18 32.1-26.9 47.4 5.1 28.3 26.9-4.4 32.2-15.5 31.5-48.6 41.3-147.5 11.9-105.4zM456.4 596.7c.7 55.3 48 161.7 63.1 123.7-9-24.4-27.6-41.5-33.2-67.5-8.1-13.9-8.9-94.4-29.9-56.2z" />
              <path
                class="st0"
                d="M236 748.3c.6-3.4-.8-2.1 2.8-8.1-3.3 4 4.8-4.8 1.8-1.8 20.1-14.8 62.8-8.6 92.5-9.7 63.2.5 136-1.9 195.3.8 16.2 2.9 24.3 5.8 23.5 19.4.2 2.4-2.9 7.7-2.5 7.2-9.2 20.8-150.4 11-173.3 12.3-62.5-4.5-98.8 8.6-135.9-8.6 1.5 1.1.3.3-2.1-2.5 2.6 4.5-2.7-6.1-1.7-4.9.8-20.3-24.4-16.5-18.5 3.8 6.7 51.1 138.4 24.4 164.9 31.3 112.4.4 180.4 8.7 187.7-33.8 9.3-63.5-97.2-39.6-128.7-44.3C357 711 277 706.5 252.1 713c-59.5 15.2-27.4 66.5-16.1 35.3z" />
            </g>
          </svg>
          <p>Ihr Cockpit wird vorbereitet...</p>
        </div>
        }
      </div>
    </dx-scroll-view>
  </div>

  <div class="left-side_controls">
    <div class="create-control" (click)="actionSheetVisible = true" *ngIf="applicationMode !== 'administration'">
      <a class="create-control-link" id="createOf" *ngIf="canCreateTemplate && creatableDocTypes.length">
        <i class="material-icons md-20" id="create">add_box</i>
        <span *ngIf="!isNavHidden">Erstellen...</span>
      </a>
      <dx-tooltip
        *ngIf="!actionSheetVisible"
        target="#create"
        position="bottom"
        showEvent="dxhoverstart"
        hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Dokument erstellen</div>
      </dx-tooltip>
    </div>
  </div>

  <dx-scroll-view height="100%" class="left-side__menu__container">
    <div class="left-side__menu">
      <div
        class="menu-section"
        *ngFor="let group of naviList; let i = index"
        [class.list-group-closed]="group.isClosed">
        <h3 *ngIf="group.text" (click)="closeGroup(group)">
          <i *ngIf="group.icon" class="material-icons md-20" [class.blank-icon]="group.icon === 'remove'">
            {{ group.icon }}
          </i>
          <span>{{ group.text }}</span>
        </h3>

        <div class="group-separator-wrapper" id="header-{{ i }}" (click)="closeGroup(group)">
          <i *ngIf="group.icon" class="material-icons md-20" [class.blank-icon]="group.icon === 'remove'">
            {{ group.icon }}
          </i>
          <div class="group-separator"></div>
        </div>
        <!-- tooltip -->
        <dx-tooltip target="#header-{{ i }}" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data = data; of: 'content'">
            {{ group.text }}
          </div>
        </dx-tooltip>

        <ul class="list-group left-side-navi">
          <li *ngFor="let link of group.links" class="left-side-navi-item">
            <a (click)="navigateTo(link)">
              <i
                *ngIf="link.icon"
                class="material-icons md-18"
                [class.blank-icon]="link.icon === 'remove'"
                id="{{ link.name }}">
                {{ link.icon }}
              </i>
              <span>{{ link.text }}</span>
            </a>
            <!-- tooltip -->
            <dx-tooltip
              *ngIf="isNavHidden"
              target="#{{ link.name }}"
              position="right"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data = data; of: 'content'">
                {{ link.text }}
              </div>
            </dx-tooltip>
          </li>
        </ul>
      </div>
    </div>
  </dx-scroll-view>

  <div class="copyright">
    <div class="company-name">ventuno GmbH</div>

    <div class="toggle__pin" [class.toggle__pin-active]="isNavPinned" (click)="pinNavigation()">
      <i class="material-icons md-20" id="navPin">push_pin</i>
      <dx-tooltip target="#navPin" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">{{ pinTooltip }}</div>
      </dx-tooltip>
    </div>

    <div *ngIf="!isNavPinned" (click)="toggleNavigation()" class="toggle__switcher">
      <i id="navToggle" class="material-icons md-20">{{ chevronIcon }}</i>
      <dx-tooltip target="#navToggle" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">{{ chevronTooltip }}</div>
      </dx-tooltip>
    </div>
  </div>
</aside>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [maxHeight]="'90vh'"
  [shading]="false"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  [(visible)]="actionSheetVisible"
  (onContentReady)="onContentReady($event)"
  [animation]="{
    show: {
      type: 'fade',
      from: { opacity: 0.5 },
      to: { opacity: 1 }
    }
  }">
  <dxo-position at="right top" my="left top" of="#createOf"></dxo-position>
  <div *dxTemplate="let data of 'content'" class="create-items">
    <dx-scroll-view width="100%" height="100%">
      <ul>
        <li *ngFor="let item of creatableDocTypes" (click)="onCreateProcessPopup(item)">
          <i *ngIf="item.icon" class="material-icons md-18 mr-2">{{ item.icon }}</i>
          <span>{{ item.text }}</span>
        </li>
      </ul>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  width="70vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="showInfoPopup"
  [height]="'auto'"
  maxHeight="80vh"
  [fullScreen]="false"
  [showTitle]="false"
  [showCloseButton]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [shading]="true"
  [toolbarItems]="infoPopupToolbarItems"
  (onHidden)="onHidden()">
  <div *dxTemplate="let data of 'content'">
    <app-dynamic-list
      *ngIf="selectedCard && selectedCard.type === 'list'"
      [embeddedMode]="true"
      [noState]="true"
      [personalMode]="false"
      [additionalSettings]="selectedCard.additionalSettings"
      [documentType]="selectedCard.settings.listName"></app-dynamic-list>
    <app-scheduler *ngIf="selectedCard && selectedCard.type === 'calendar'" [card]="selectedCard"></app-scheduler>
  </div>
</dx-popup>
