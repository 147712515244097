<div class="bg-content" *ngIf="!isPopupDocument" id="summaryNew"></div>
<div class="bg-content" *ngIf="isPopupDocument" id="summaryNewPopupDocument"></div>

<app-component-notification></app-component-notification>
<div class="bg-content" *ngIf="formData && formItems?.length" [ngClass]="{ 'readonly-content': editMode !== 'Edit' }">
  <div *ngIf="draggableAllow" class="fields-edit-buttons">
    <div>
      <dx-sortable group="cardsGroup" [data]="'spacer'" group=".dx-box">
        <div>
          <button i18n type="submit" class="btn btn-secondary btn-sm" style="width: 200px; cursor: move">
            <i class="material-icons">add</i>Platzhalter erstellen
          </button>
        </div>
      </dx-sortable>
    </div>
    <div>
      <dx-sortable group="cardsGroup" [data]="'field'" group=".dx-box">
        <div>
          <button i18n type="submit" class="btn btn-secondary btn-sm" style="width: 200px; cursor: move">
            <i class="material-icons">add</i>Feld erstellen
          </button>
        </div>
      </dx-sortable>
    </div>
    <div>
      <dx-sortable group="cardsGroup" [data]="'group'" group=".dx-box">
        <div>
          <button i18n type="submit" class="btn btn-secondary btn-sm" style="width: 200px; cursor: move">
            <i class="material-icons">add</i>Gruppe erstellen
          </button>
        </div>
      </dx-sortable>
    </div>
    <div>
      <dx-sortable group="cardsGroup" [data]="'container'" group=".dx-box">
        <div>
          <button i18n type="submit" class="btn btn-secondary btn-sm" style="width: 200px; cursor: move">
            <i class="material-icons">add</i>Container erstellen
          </button>
        </div>
      </dx-sortable>
    </div>
    <div class="delete-area">
      <dx-sortable
        group="cardsGroup"
        [data]="'trash-fields'"
        (onAdd)="onDeleteDraggableItem($event)"
        [dropFeedbackMode]="'indicate'"
        [allowDropInsideItem]="true">
        <div class="vh-center"><span class="material-icons drag-delete-icon"> delete </span> Löschen</div>
      </dx-sortable>
    </div>
  </div>
  <dx-form
    #formInclude
    id="formInclude"
    [labelLocation]="labelLocation"
    [readOnly]="!(editMode === 'Edit')"
    [items]="formItems"
    [(formData)]="formData"
    [customizeItem]="customizeItem"
    [showValidationSummary]="true"
    [alignItemLabelsInAllGroups]="false"
    validationGroup="{{ validationGroupId }}"
    (onFieldDataChanged)="fieldDataChanged($event)"
    (onContentReady)="onContentFormReady($event)">
    <!-- HTML editor  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'htmlEditor'"
      class="html-editor"
      [ngStyle]="{ width: field.editorOptions.width }">
      <!-- Migration Patch -->
      <div
        class="migration"
        *ngIf="
          field.name === 'Migration_Agenda' ||
          field.name === 'Migration_Protokolle' ||
          field.name === 'Migration_Protokollzusammenfassung' ||
          field.name === 'Migration_TOPZusammenfassung'
        "
        [innerHtml]="field.editorOptions.value"></div>
      <dx-html-editor
        *ngIf="
          field.name !== 'Migration_Agenda' &&
          field.name !== 'Migration_Protokolle' &&
          field.name !== 'Migration_Protokollzusammenfassung' &&
          field.name !== 'Migration_TOPZusammenfassung'
        "
        valueType="html"
        [value]="field.editorOptions.value"
        [hint]="editMode === 'Edit' ? field.editorOptions.hint : ''"
        allowSoftLineBreak="true"
        (onValueChanged)="onHtmlEditorChanged($event, field)"
        (onContentReady)="onHtmlEditorContentReady($event, field)"
        (onInitialized)="onHtmlEditorInitialized($event, field)"
        [readOnly]="editMode !== 'Edit' || field.editorOptions.readOnly">
        <dxo-toolbar
          *ngIf="editMode === 'Edit'"
          [items]="field.editorOptions.htmlEditorToolbarItems"
          [multiline]="false">
        </dxo-toolbar>
        <dxo-media-resizing [enabled]="true"></dxo-media-resizing>
        <dxo-table-context-menu [enabled]="true"></dxo-table-context-menu>
        <dxo-table-resizing [enabled]="true"></dxo-table-resizing>
        <dxo-image-upload fileUploadMode="base64" [tabs]="['file']"></dxo-image-upload>
      </dx-html-editor>
    </div>

    <!-- checkbox group  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'checkboxGroup'"
      class="checkbox-group"
      [ngStyle]="{ width: field.editorOptions.width }">
      <div
        [ngClass]="{ 'checkbox-group-column': field.editorOptions.checkboxGroupColumns > 0 }"
        [ngStyle]="{
          'grid-template-columns':
            field.editorOptions.checkboxGroupColumns > 0
              ? 'repeat(auto-fill, max(' +
                field.editorOptions.checkboxGroupColumnWidth +
                'px, 100% /' +
                field.editorOptions.checkboxGroupColumns +
                '))'
              : null
        }">
        <div *ngFor="let checkbox of field.editorOptions.dataSource; let i = index" class="checkbox-group__items">
          <ng-container
            *ngIf="
              field.dataField && formData[field.dataField] && formData[field.dataField] | find : checkbox.value;
              else checkboxGroupFalseContainer
            ">
            <dx-check-box
              [text]="checkbox.label"
              [value]="true"
              [hint]="editMode === 'Edit' ? field?.editorOptions?.hint : ''"
              [readOnly]="editMode !== 'Edit' || field.editorOptions.disabled || field.editorOptions.readOnly"
              [visible]="checkbox.visible !== false"
              [disabled]="checkbox.disabled === true"
              (onValueChanged)="onCheckboxGroupChanged($event, field, checkbox)">
              <dx-validator
                [validationRules]="field.editorOptions.validationRules"
                validationGroup="{{ validationGroupId }}"
                name="{{ field.name + '-checkBox-' + i }}"
                *ngIf="
                  field.editorOptions.requiredOption === 'required-if-visible' ||
                  field.editorOptions.requiredOption === true
                ">
              </dx-validator>
            </dx-check-box>
          </ng-container>
          <ng-template #checkboxGroupFalseContainer>
            <ng-container *ngTemplateOutlet="checkboxGroupFalse; context: { index: i }"></ng-container>
          </ng-template>
          <ng-template #checkboxGroupFalse let-index="index">
            <dx-check-box
              [text]="checkbox.label"
              [value]="false"
              [hint]="editMode === 'Edit' ? field?.editorOptions?.hint : ''"
              [readOnly]="editMode !== 'Edit' || field.editorOptions.disabled || field.editorOptions.readOnly"
              [visible]="checkbox.visible !== false"
              [disabled]="checkbox.disabled === true"
              (onValueChanged)="onCheckboxGroupChanged($event, field, checkbox)">
              <dx-validator
                [validationRules]="field.editorOptions.validationRules"
                validationGroup="{{ validationGroupId }}"
                name="{{ field.name + '-checkBox-' + i }}"
                *ngIf="
                  field.editorOptions.requiredOption === 'required-if-visible' ||
                  field.editorOptions.requiredOption === true
                ">
              </dx-validator>
            </dx-check-box>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- file uploader  -->
    <div id="{{ field.editorOptions.elementAttr.id }}" *dxTemplate="let field of 'fileUpload'">
      <div class="upload-container" [ngStyle]="{ width: field.editorOptions.width }">
        <!-- dxFileuploader-Widget für max 20MB Dateien [maxFileSize]="1024 * 1024 * 20"-->
        <div class="upload-dx" *ngIf="editMode === 'Edit'">
          <dx-file-uploader
            uploadMode="useForm"
            [selectButtonText]="field.editorOptions.fileuploaderButtonTitel"
            labelText=""
            [hint]="editMode === 'Edit' ? field?.editorOptions?.hint : ''"
            invalidMaxFileSizeMessage="Die maximale Dateigröße beträgt {{
              field.editorOptions.fileuploaderMaxFileSizeMBit
            }}MB."
            invalidFileExtensionMessage="Unzulässiger Anhang wurde entfernt."
            readyToUploadMessage="Die Datei wird beim Speichern des Dokumentes hochgeladen."
            [allowedFileExtensions]="field.editorOptions.allowedFileExtensions | fileExtensions"
            [multiple]="true"
            [accept]="field.editorOptions.allowedFileMimeTypesDX"
            [maxFileSize]="field.editorOptions.fileuploaderMaxFileSizeBit"
            [readOnly]="editMode !== 'Edit' || field.editorOptions.disabled || field.editorOptions.readOnly"
            (onContentReady)="onContentReadyFileUpload($event)"
            (onValueChanged)="onFileValueChanged($event, field)">
            <dx-validator
              validationGroup="{{ validationGroupId }}"
              [validationRules]="field.editorOptions.validationRules">
            </dx-validator>
          </dx-file-uploader>
        </div>
      </div>

      <!-- Liste der vorhandenen Dateien -->

      <div *ngIf="field.dataField && formData[field.dataField] && formData[field.dataField]?.length">
        <dx-data-grid
          id="gridAttachment-{{ field.name }}"
          [ngClass]="{ 'uploaded-files-list': true }"
          [dataSource]="attachmentChanged$ | getAttachments : field.dataField : formData | async"
          [remoteOperations]="false"
          [showColumnHeaders]="false"
          [showRowLines]="false"
          [showColumnLines]="false"
          [rowAlternationEnabled]="false"
          (onRowRemoving)="onRemoveFile($event, field)"
          [columnAutoWidth]="true"
          [showBorders]="false">
          <dxo-editing
            mode="row"
            [allowAdding]="false"
            [allowDeleting]="editMode === 'Edit' && !field.editorOptions.readOnly"
            [useIcons]="true">
            <dxo-texts [confirmDeleteMessage]=""></dxo-texts>
          </dxo-editing>
          <dxo-paging [enabled]="false"></dxo-paging>

          <dxo-row-dragging
            [allowReordering]="editMode === 'Edit' && !field.editorOptions.readOnly"
            [onReorder]="onReorderAttachments"
            [showDragIcons]="true">
          </dxo-row-dragging>

          <dxi-column
            caption=""
            dataField="fileName"
            dataType="string"
            cellTemplate="FileTemplate"
            [minWidth]="150"
            width="auto">
          </dxi-column>

          <dxi-column caption="" cellTemplate="FileDownloadTemplate" width="36"> </dxi-column>

          <dxi-column type="buttons" [width]="36">
            <dxi-button name="delete"></dxi-button>
          </dxi-column>

          <!-- templates -->
          <div *dxTemplate="let cell of 'FileTemplate'">
            <span>{{ cell.rowIndex + 1 }}.&nbsp;</span>
            <a
              [attr.data-tabtitle]="cell.data.fileName"
              title="Anlage öffnen/herunterladen"
              (click)="downloadFile(cell.data, field, cell.rowIndex, 'open')">
              {{ cell.data.fileName }}
            </a>
          </div>

          <div *dxTemplate="let cell of 'FileDownloadTemplate'">
            <span class="uploaded-files-list-buttons">
              <a
                id="fileName-{{ field.dataField }}-{{ cell.rowIndex }}"
                download="{{ cell.data.fileName }}"
                title="Anlage herunterladen">
                <i
                  class="material-icons"
                  (click)="downloadFile(cell.data, field, cell.rowIndex, 'download')"
                  style="cursor: pointer">
                  save_alt
                </i>
              </a>
            </span>
          </div>
        </dx-data-grid>
      </div>
    </div>

    <!-- link  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'linkText'"
      [ngStyle]="{ width: field.editorOptions.width }">
      <a
        [href]="field.editorOptions.linkContent | safeHtml : 'url'"
        target="_blank"
        [attr.title]="field.editorOptions.hint">
        {{ field.editorOptions.linkName }}
      </a>
    </div>

    <!-- slider  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'sliderTemplate'"
      [ngStyle]="{ width: field.editorOptions.width }">
      <dx-slider
        [hint]="editMode === 'Edit' ? field.editorOptions.hint : ''"
        [min]="field.editorOptions?.customValues?.min"
        [max]="field.editorOptions?.customValues?.max"
        [step]="field.editorOptions?.customValues?.step"
        [(value)]="field.editorOptions.value"
        [readOnly]="editMode !== 'Edit' || field.editorOptions.disabled || field.editorOptions.readOnly"
        (onValueChanged)="onSliderChanged($event, field)">
        <dxo-label
          [visible]="field.editorOptions?.sliderLabel?.visible"
          [format]="field.editorOptions?.sliderLabel?.format"
          [position]="field.editorOptions?.sliderLabel?.position">
        </dxo-label>
        <dxo-tooltip
          [enabled]="field.editorOptions?.tooltip?.enabled"
          [format]="field.editorOptions?.tooltip?.format"
          [showMode]="field.editorOptions?.tooltip?.showMode"
          [position]="field.editorOptions?.tooltip?.position">
        </dxo-tooltip>
      </dx-slider>
    </div>

    <!-- SignatureField  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'signatureFieldTemplate'"
      style="display: flex">
      <dx-button
        [elementAttr]="{ class: 'signature-edit-button' }"
        [visible]="!field.editorOptions.readOnly && !mapShowSignatureDialog.get(field.editorOptions.elementAttr.id)"
        type="normal"
        text="Unterschrift zeichnen"
        (onClick)="openSignatureDialog(field)"></dx-button>
      <app-sign-signature
        *ngIf="mapShowSignatureDialog.get(field.editorOptions.elementAttr.id)"
        [currentField]="field"
        (dialogHidden)="signatureDialogClose(field)"
        (dialogResult)="signatureDialogResult($event)">
      </app-sign-signature>
      <div class="signature-content">
        <img
          style="max-width: 100%"
          onContextMenu="return false;"
          *ngIf="formData[field.dataField]?.length && !mapShowSignatureDialog.get(field.editorOptions.elementAttr.id)"
          [src]="formData[field.dataField][0] | fileDownloadLink : 'blobUrl' : currentDocument | async" />
      </div>
    </div>

    <!-- Container  -->
    <div
      id="{{ field.editorOptions.elementAttr.id }}"
      *dxTemplate="let field of 'container'"
      [ngStyle]="{ width: field.editorOptions.width }">
      <!-- toc:agenda  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'toc:agenda' && isAgenda"
        class="dx-form-group dx-group-colcount-1"
        style="overflow-x: auto"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-agenda-include
            [agendaDocument]="currentDocument"
            [formData]="formData"
            [agendaData]="agendaData"
            [startDateChanged]="agendaStartDateChanged$ | async"
            (agendaResult)="eventDialogResult($event)"
            [fieldContainer]="field"
            [editMode]="editMode">
          </app-agenda-include>
        </div>
      </div>
      <!-- Neue Agenda  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'agenda' && isAgenda"
        class="dx-form-group dx-group-colcount-1"
        style="overflow-x: auto"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-top-include
            [agendaDocument]="currentDocument"
            [formData]="formData"
            [formDataChanged]="formDataChanged$ | async"
            (agendaResult)="eventTopDialogResult($event)"
            [topField]="field"
            [isShowMeetingTimer]="isShowMeetingTimer"
            [isMeetingMinutes]="false"
            [editMode]="editMode">
          </app-top-include>
        </div>
      </div>

      <!-- Neuemeeting-minutes  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'meeting-minutes' && isMeetingMinutes"
        class="dx-form-group dx-group-colcount-1"
        style="overflow-x: auto"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-top-include
            [agendaDocument]="currentDocument"
            [formData]="formData"
            [startDateChanged]="agendaStartDateChanged$ | async"
            (agendaResult)="eventTopDialogResult($event)"
            [topField]="field"
            [isMeetingMinutes]="true"
            [editMode]="'ReadOnly'">
          </app-top-include>
        </div>
      </div>
      <!-- toc:meeting-minutes  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'toc:meeting-minutes' && isMeetingMinutes"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-protocol-include [meetingsMinutesDoc]="currentDocument" [editMode]="editMode"> </app-protocol-include>
        </div>
      </div>
      <!-- tasks  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'tasks'"
        class="dx-form-group dx-group-colcount-1"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-project-include
            [currentDocument]="currentDocument"
            [editMode]="editMode"
            [fieldContainer]="field"
            [isDocumentHasChanges]="isDocumentHasChanges"
            (eventResult)="eventDialogResult($event)">
          </app-project-include>
        </div>
      </div>
      <!-- reference-documents  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'reference-documents'"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-children-list
            [currentDocument]="currentDocument"
            [fieldContainer]="field"
            [isDocumentHasChanges]="isDocumentHasChanges"
            (eventResult)="eventDialogResult($event)">
          </app-children-list>
        </div>
      </div>
      <!-- signature  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'signature' && isHasSignature"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-signature-include
            [currentDocument]="currentDocument"
            [workflowDocument]="workflowDocument"
            [fieldContainer]="field">
          </app-signature-include>
        </div>
      </div>
      <!-- diagram  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'diagram:dx-diagram'"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-diagram-include
            [currentDocument]="currentDocument"
            [workflowDocument]="workflowDocument"
            (eventDiagram)="eventDiagramResult($event)"
            [fieldContainer]="field">
          </app-diagram-include>
        </div>
      </div>
      <!-- decision  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'decision' && isDecision"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-decision-include [currentDocument]="currentDocument" [editMode]="'ReadOnly'"></app-decision-include>
        </div>
      </div>
      <!-- votes  -->
      <div
        *ngIf="field.editorOptions.placeholderType === 'votes' && ballotDocuments?.length"
        [ngClass]="{ 'dx-form-group-with-caption': field.editorOptions?.label?.visible }"
        class="dx-form-group dx-group-colcount-1">
        <span class="dx-form-group-caption" *ngIf="field.editorOptions?.label?.visible">{{
          field.editorOptions?.label?.text
        }}</span>
        <div class="dx-form-group-content">
          <app-ballot-data-include [currentDocument]="currentDocument" [ballotDocuments]="ballotDocuments">
          </app-ballot-data-include>
        </div>
      </div>
    </div>
  </dx-form>
</div>

<dx-popover #popOverUserTagBox id="popOverUserTagBox" [(target)]="popoverElement">
  <div *dxTemplate="let data of 'content'">
    <div [innerHtml]="popoverContent | safeHtml"></div>
  </div>
</dx-popover>
