import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import {
  ICreateDocumentInputTemplate,
  IDecisionStampUpdateAction,
  IDocument,
  IDocumentFields,
  IEventEmitter,
  IEventEmitterExecuteWorkflow,
  INameSelectorDialogInclude,
  INameSelectorDialogSettings,
  IPermissionTarget,
  IRole,
  IUser,
  IWorkflowAction,
  IWorkflowActionSheetItem,
  TNameSelectorDialogType
} from '@interfaces/siam';
import { DocumentService } from '@services/document.service';
import { Observable, Subject, lastValueFrom, switchMap, take, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { siamConst } from '@interfaces/siamConst';
import { NameSelectorComponent } from '../name-selector/name-selector.component';
import * as UserFactory from '@factories/user.factory';
import { FieldPermissionTargetServ, FieldServer } from '@interfaces/fieldServer';
import { copy } from '@factories/document.factory';
import { WorkFlowDialogSettings, WorkflowDialogComponent } from '../workflow-dialog/workflow-dialog.component';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { LoadPanelService } from '@services/load-panel.service';
import { NotifyService } from '@services/notify.service';
import { _defaultDecisionColor, IDecisionStamp } from '@interfaces/default-decision';
import { TemplateService } from '@services/template.service';
import { isHasProperty } from '@factories/helpers';
import { LoggerService } from '@services/logger.service';

@Component({
  selector: 'app-workflow-execute',
  templateUrl: './workflow-execute.component.html',
  styleUrls: ['./workflow-execute.component.scss']
})
export class WorkflowExecuteComponent implements OnChanges {
  @ViewChild(ConfirmPopupComponent, { static: true }) confirmPopupComponent: ConfirmPopupComponent;

  @ViewChild(NameSelectorComponent, { static: true }) nameSelectorComponent: NameSelectorComponent;

  @ViewChild(WorkflowDialogComponent, { static: true }) workflowDialog: WorkflowDialogComponent;

  @Input() action: IWorkflowActionSheetItem = null;
  @Input() document: IDocument = null;
  @Input() agendaDocument: IDocument = null;

  @Output() dialogResult = new EventEmitter<IEventEmitterExecuteWorkflow<IDocument>>();
  // eslint-disable-next-line @typescript-eslint/ban-types
  templateOrder: Function[] = [];
  isProcessing = false; // Flag to track processing state
  currentIndex = 0; // Track the current template index

  isOkButtonDisabled = true;

  workflowActions: IWorkflowActionSheetItem[];

  workflowStatusLabel: string;

  private workflowCurrentAssignee: IPermissionTarget[];

  private currentUser: IUser;

  private workflowAction: IWorkflowActionSheetItem = null;

  private workflowActionVariables: Record<string, unknown> = null;

  private workflowActionCreateDecisionVariables: Record<string, IDecisionStamp> = null;

  #destroyable$ = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private logger: LoggerService,

    private templateService: TemplateService,
    private zone: NgZone,
    private nameDataPipe: GetNameDataPipe,
    private loadPanelService: LoadPanelService
  ) {
    this.currentUser = UserFactory.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.action?.currentValue) {
      this.onExecuteAction(this.action);
    }
  }

  onExecuteAction = (actionItem?: IWorkflowActionSheetItem): void => {
    if (!actionItem) {
      return;
    }
    if (!actionItem.action.isUsable) {
      this.confirmPopupComponent.show({
        title: 'Workflowinformation',
        message: `Der Workflowschritt "${actionItem.action.label}" kann nicht ausgeführt werden: ${actionItem.action.reason}`,
        items: [
          {
            text: 'Workflowinformation',
            name: 'cancel',
            location: 'before',
            toolbar: 'top'
          },
          {
            widget: 'dxButton',
            name: 'cancel',
            location: 'before',
            toolbar: 'bottom',
            options: {
              text: 'Ok',
              type: 'primary',
              icon: 'check'
            }
          }
        ],
        noInit: true
      });
      return;
    }

    const executeAction = () => {
      void this.zone.run(async () => {
        try {
          // Reset the processing state
          this.workflowActionVariables = {};
          this.workflowActionCreateDecisionVariables = {};
          this.workflowAction = actionItem;
          this.isProcessing = true;
          this.currentIndex = 0;
          this.templateOrder = []; // Clear the previous template order

          // Push the templates into the order array
          this.pushTemplatesToOrder(actionItem);

          // Start processing the templates sequentially
          await this.executeNextTemplate();
        } catch (error) {
          this.logger.error('--- document-base.component:onSelectWorkflowAction() called: {@error}: ', error);
        }
      });
    };
    void executeAction();
    this.dialogResult.emit({ command: 'loading' });
  };

  dialogCancel = (): void => {
    this.dialogResult.emit({ command: 'close' });
  };
  resultEmitterSelectorDialogClose(result: boolean): void {
    if (result === false) {
      this.dialogCancel();
    }
  }
  resultEmitterWorkflowDialogClose(result: boolean): void {
    if (result === false) {
      this.dialogCancel();
    }
  }
  async resultEmitterSelectorDialog(result: (IUser | IRole)[]): Promise<void> {
    try {
      const users = result
        .filter(item => item.compositeId.startsWith('user:'))
        .map(item => ({ targetId: item.id, type: 'user' }));

      const roles = result
        .filter(item => item.compositeId.startsWith('role:'))
        .map(item => ({ targetId: item.id, type: 'role' }));

      const variables: Record<string, unknown[]> = { assignee: [].concat(users, roles) };
      Object.assign(this.workflowActionVariables, variables);
    } catch (error) {
      this.logger.error('--- document-base.component:onEmitNameSelectorDialog() called: {@error}: ', error);
    } finally {
      await this.executeNextTemplate();
    }
  }

  async resultEmitterWorkflowDialog(result: IEventEmitter<IDocument>): Promise<void> {
    try {
      if (result.command !== 'save') return;
      if (result.variableCreateDocumentName?.length) {
        const variableObject = result.object.fields;
        if (this.agendaDocument) {
          variableObject[siamConst.decisionAgendaFieldId] = { value: this.agendaDocument?.id };
        }
        this.workflowActionVariables[result.variableCreateDocumentName] = variableObject;
      } else if (result.variableCreateDecisionName?.length) {
        const decisionStamp = this.workflowActionCreateDecisionVariables[result.variableCreateDecisionName];
        const additionalFields: IDocumentFields = {
          decisionColor: { value: decisionStamp.customData.color || _defaultDecisionColor }
        };
        if (this.agendaDocument) {
          additionalFields[siamConst.decisionAgendaFieldId] = { value: this.agendaDocument?.id };
        }
        this.workflowActionVariables[result.variableCreateDecisionName] = Object.assign(
          result.object.fields,
          additionalFields
        );
      } else if (result.variableUpdateDecisionName?.length) {
        this.workflowActionVariables[result.variableUpdateDecisionName] = Object.assign(result.object.fields);
      } else {
        const doc = result.object;
        const variables: Record<string, unknown> = {};
        Object.keys(doc.fields).forEach(key => {
          variables[key] = doc.fields[key].value;
        });
        Object.assign(
          this.workflowActionVariables,
          Object.fromEntries(Object.entries(variables).filter(([key]) => !(key in this.workflowActionVariables)))
        );
      }
    } catch (error) {
      this.logger.error('--- document-base.component:resultEmitterWorkflowDialog() called: {@error}: ', error);
    } finally {
      await this.executeNextTemplate();
    }
  }

  /* add this function to helper */
  private getNameSelectorSettings(_templateFields: FieldServer): INameSelectorDialogSettings {
    if (!_templateFields) {
      return null;
    }
    const templateFields = copy(_templateFields);
    let include = templateFields.include as INameSelectorDialogInclude[];
    let exclude = templateFields.exclude as INameSelectorDialogInclude[];
    let title = 'Bitte wählen Sie den Empfänger aus:';
    const selectionMode = templateFields.allowMultiple ? 'multiple' : 'single';
    const minSelection =
      selectionMode === 'single' ? 1 : (templateFields as FieldPermissionTargetServ).customValues?.minSelection || 1;
    const forbidRoles = templateFields.forbidRoles;
    const forbidUsers = templateFields.forbidUsers;
    const targetField = (templateFields as FieldPermissionTargetServ).customValues?.targetField || null;
    const targetFieldExclude = (templateFields as FieldPermissionTargetServ).customValues?.targetFieldExclude || null;
    const currentUserExclude = (templateFields as FieldPermissionTargetServ).customValues?.currentUserExclude || null;
    const currentAssigneeExclude =
      (templateFields as FieldPermissionTargetServ).customValues?.currentAssigneeExclude || null;
    if (targetField && targetField?.startsWith('fields')) {
      const fieldName = targetField.split('.')[1];
      if (fieldName) {
        const fieldValue = this.document.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          include = fieldValue.concat(include);
        }
      }
    }
    if (targetFieldExclude && targetFieldExclude?.startsWith('fields')) {
      const fieldName = targetFieldExclude.split('.')[1];
      if (fieldName) {
        const fieldValue = this.document.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          exclude = fieldValue.concat(exclude);
        }
      }
    }
    if (currentUserExclude) {
      const fieldValue = { type: 'user', targetId: this.currentUser.id } as IPermissionTarget;
      exclude.push(fieldValue);
    }
    if (currentAssigneeExclude) {
      const fieldValue =
        (this.workflowCurrentAssignee as INameSelectorDialogInclude[]) || ([] as INameSelectorDialogInclude[]);
      exclude = fieldValue.concat(exclude);
    }
    let selectType: TNameSelectorDialogType;
    if (forbidRoles && forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidRoles && !forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidUsers && !forbidRoles) {
      selectType = 'Rollen';
      title = 'Bitte wählen Sie die Rolle/n aus:';
    }
    if (!forbidUsers && !forbidRoles) {
      selectType = 'Beide';
    }

    return {
      title,
      selectType,
      include,
      exclude,
      selectionMode,
      minSelection
    };
  }
  private proceedWorkflowAction(variables: Record<string, unknown>): void {
    this.executeWorkflowAction(this.workflowAction.action, variables);
  }
  private executeWorkflowAction(action: IWorkflowAction, variables: Record<string, unknown> = {}): void {
    this.loadPanelService.show('Workflowaktivitäten werden ausgeführt...', 130, 270);
    const workflowParameters = this.documentService.getRereferencesObject(this.document);
    if (this.agendaDocument) {
      workflowParameters[siamConst.conditionAgendaExistsTag] = true;
    } else {
      workflowParameters[siamConst.conditionAgendaExistsTag] = false;
    }
    const workflowVariables = Object.assign(variables, workflowParameters);
    this.documentService
      .executeWorkflowAction(action, workflowVariables)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (doc): void => {
          this.dialogResult.emit({ command: 'save', object: doc });
          this.resetLoading();
          this.workflowActionVariables = null;
          this.workflowAction = null;
          NotifyService.global.success(`Der Workflowschritt "${action.label}" wurde ausgeführt.`);
        },
        error: (): void => {
          this.dialogResult.emit({ command: 'error' });
          this.resetLoading();
          NotifyService.global.error(`Der Workflowschritt '${action.label}' konnte nicht ausgeführt werden.`);
        }
      });
  }
  private resetLoading(): void {
    this.loadPanelService.hide();
  }
  private createDocumentFromTemplateId(templateId: string): Observable<IDocument> {
    return this.templateService.getTemplate(templateId).pipe(
      switchMap(template => this.documentService.createDocumentByTemplateId(template)),
      takeUntil(this.#destroyable$)
    );
  }

  // Push the template processing functions in the correct order into templateOrder array
  pushTemplatesToOrder(actionItem: IWorkflowActionSheetItem): void {
    // Process the templates in the right order

    // Step 1: userInputTemplate (kann nur 1 sein)
    if (actionItem.action.userInputTemplate) {
      const userInputTemplate = actionItem.action.userInputTemplate;
      if (userInputTemplate) {
        const nameSelector =
          (Array.isArray(userInputTemplate.tags) && userInputTemplate.tags.includes('siamAssigneeTag')) ||
          userInputTemplate.name === 'user-fields';
        if (nameSelector) {
          this.templateOrder.push(() => this.processUserInputAssigneTemplate(actionItem));
        } else {
          this.templateOrder.push(() => this.processUserInputTemplate(actionItem));
        }
      }
    }
    // Step 2: userInputTemplateLink (kann nur 1 sein)
    if (actionItem.action.userInputTemplateLink) {
      this.templateOrder.push(() => this.processUserInputTemplateLink(actionItem));
    }

    // Step 3: createDecisionDocumentTemplates (können mehr sein)
    if (actionItem.action.decisionStampsCreate) {
      for (const key in actionItem.action.decisionStampsCreate) {
        if (isHasProperty(actionItem.action.decisionStampsCreate, key)) {
          this.workflowActionCreateDecisionVariables[key] = actionItem.action.decisionStampsCreate[key];
          this.templateOrder.push(() =>
            this.processCreateDecisionDocumentFromTemplate(actionItem.action.decisionStampsCreate[key], key)
          );
        }
      }
    }

    // Step 4: decisionStampsUpdates (können mehr sein)
    if (actionItem.action.decisionStampsUpdates) {
      for (const update of actionItem.action.decisionStampsUpdates) {
        if (update.$type === 'values') {
          // 'values' ist für update-decision-values Aktivität
          this.templateOrder.push(() => this.processUpdateDecisionDocumentTemplates(update));
        }
      }
    }
    // Step 5: createDocumentInputTemplates (können mehr sein)
    if (actionItem.action.createDocumentInputTemplates) {
      for (const templateKey in actionItem.action.createDocumentInputTemplates) {
        if (isHasProperty(actionItem.action.createDocumentInputTemplates, templateKey)) {
          this.templateOrder.push(() =>
            this.processCreateDocumentFromTemplate(
              actionItem.action.createDocumentInputTemplates[templateKey],
              templateKey
            )
          );
        }
      }
    }
  }

  // Execute the next template function in the order
  private async executeNextTemplate(): Promise<void> {
    if (this.currentIndex >= this.templateOrder.length) {
      this.isProcessing = false;
      this.proceedWorkflowAction(this.workflowActionVariables);
      return; // All templates processed
    }

    this.isProcessing = true;

    const nextFunction = this.templateOrder[this.currentIndex];
    this.currentIndex++;

    try {
      await nextFunction(); // Execute the next template function
    } catch (error) {
      this.logger.error('--- document-base.component:executeNextTemplate() called: {@error}: ', error);
    }
  }
  processUserInputTemplateLink(actionItem: IWorkflowActionSheetItem): void {
    try {
      const userInputTemplateLink = actionItem.action.userInputTemplateLink;
      if (userInputTemplateLink) {
        const layout = this.documentService.createSubForm(userInputTemplateLink, actionItem.action.userInput);
        const settings: WorkFlowDialogSettings = {
          title: layout?.template?.caption || 'Formular',
          fullScreen: false,
          readonly: false,
          parentDocumentId: this.document?.id
        };
        this.workflowDialog.show(layout, this.document, settings);
      }
    } catch (error) {
      this.logger.error('--- document-base.component:processUserInputTemplateLink() called: {@error}: ', error);
    }
  }

  processUserInputAssigneTemplate(actionItem: IWorkflowActionSheetItem): void {
    try {
      const userInputTemplate = actionItem.action.userInputTemplate;
      this.nameSelectorComponent.show(this.getNameSelectorSettings(userInputTemplate.fields[0]));
    } catch (error) {
      this.logger.error('--- document-base.component:processUserInputAssigneTemplate() called: {@error}: ', error);
    }
  }

  async processUserInputTemplate(actionItem: IWorkflowActionSheetItem): Promise<void> {
    try {
      const userInputTemplate = actionItem.action.userInputTemplate;
      const layout = this.documentService.createSubForm(userInputTemplate, actionItem.action.userInput);
      let title = layout?.template?.caption || actionItem.action.label;

      // Customize title if it contains user information
      if (actionItem.action.name?.includes('vote:user:')) {
        const user = {
          id: actionItem.action.name?.split('vote:user:').pop()
        };
        const userPipe = await lastValueFrom(this.nameDataPipe.transform(user));
        title = `${title} für ${userPipe.fullName}`;
      }

      const settings: WorkFlowDialogSettings = {
        title,
        fullScreen: false,
        readonly: false,
        parentDocumentId: this.document?.id
      };
      this.workflowDialog.show(layout, this.document, settings);
    } catch (error) {
      this.logger.error('--- document-base.component:processUserInputTemplate() called: {@error}: ', error);
    }
  }

  // Create a document from the template
  async processCreateDocumentFromTemplate(template: ICreateDocumentInputTemplate, templateName: string): Promise<void> {
    try {
      const documentFromTemplate = await lastValueFrom(
        this.createDocumentFromTemplateId(template.documentTemplateId).pipe(take(1))
      );
      const showEditor =
        template?.variables && isHasProperty(template.variables, 'showEditor')
          ? (template.variables.showEditor as boolean)
          : true;
      const cloneFields =
        template?.variables && isHasProperty(template.variables, 'cloneFields')
          ? (template.variables.cloneFields as boolean)
          : true;
      const settings: WorkFlowDialogSettings = {
        title: documentFromTemplate.template.caption || this.workflowAction.action.label,
        fullScreen: false,
        readonly: false,
        createDocumentInputName: templateName,
        parentDocumentId: this.document?.id,
        cloneFields,
        showEditor
      };
      this.workflowDialog.show(documentFromTemplate, this.document, settings);
    } catch (error) {
      this.logger.error('--- document-base.component:processCreateDocumentFromTemplate() called: {@error}: ', error);
    }
  }

  // Create decision from template-decision
  async processCreateDecisionDocumentFromTemplate(
    decisionTemplate: IDecisionStamp,
    templateKey: string
  ): Promise<void> {
    try {
      const documentFromTemplate = await lastValueFrom(
        this.documentService.createDecisionDocumentFromDecisionStamp(decisionTemplate).pipe(take(1))
      );
      const settings: WorkFlowDialogSettings = {
        title: `${documentFromTemplate.template.caption || this.workflowAction.action.label} ${
          decisionTemplate.displayName
        }`,
        fullScreen: false,
        readonly: false,
        createDecisionInputName: templateKey,
        parentDocumentId: this.document?.id
      };
      this.workflowDialog.show(documentFromTemplate, this.document, settings);
    } catch (error) {
      this.logger.error(
        '--- document-base.component:processCreateDecisionDocumentFromTemplate() called: {@error}: ',
        error
      );
    }
  }

  // Update decision values from the template-decision
  async processUpdateDecisionDocumentTemplates(updateAction: IDecisionStampUpdateAction): Promise<void> {
    try {
      const documentDecision = await lastValueFrom(
        this.documentService.updateDecisionDocumentFromDecisionValue(this.document, updateAction.category).pipe(take(1))
      );
      if (documentDecision) {
        const settings: WorkFlowDialogSettings = {
          title: `${documentDecision.document?.template?.caption || this.workflowAction.action.label} ${
            documentDecision.caption
          }`,
          fullScreen: false,
          readonly: false,
          updateDecisionInputName: updateAction.variableName,
          parentDocumentId: this.document?.id
        };
        this.workflowDialog.show(documentDecision.document, this.document, settings);
      } else {
        this.logger.error(
          '--- document-base.component:updateDecisionDocumentTemplate() called: {@error}: ',
          'Kein Dokument gefunden!'
        );
      }
    } catch (error) {
      this.logger.error('--- document-base.component:updateDecisionDocumentTemplate() called: {@error}: ', error);
    }
  }
}
