import { IDocument } from './siam';

export const _defaultDecisionTemplateId = '6340926d-1809-432e-afaf-29782be57409';
export const _defaultDecisionId = '99bc3d81-9ae5-425f-8445-26500fb1c12a';
export const _defaultDecisionColor = '#008000';

export type TDecisionFields = 'category' | 'date' | 'decision' | 'number' | 'status' | 'comment';

export const DecisionFieldsDataSource: { label: string; value: TDecisionFields }[] = [
  { label: 'Beschlusskategorie', value: 'category' },
  { label: 'Beschlussdatum', value: 'date' },
  { label: 'Beschlussvermerk', value: 'decision' },
  { label: 'Beschlussnummer', value: 'number' },
  { label: 'Beschlussstatus', value: 'status' },
  { label: 'Beschlusskommentar', value: 'comment' }
];

export const _defaultDecisionDocument = {
  fields: {
    date: {
      value: null,
      valueType: 'timeStamp',
      effectivePermissions: ['read', 'update', 'modifyPermission']
    },
    number: {
      value: null,
      valueType: 'text',
      effectivePermissions: ['read', 'modifyPermission']
    },
    decision: {
      value: 'approved',
      valueType: 'text',
      effectivePermissions: ['read', 'update', 'modifyPermission']
    },
    category: {
      value: 'beschluss',
      valueType: 'text',
      effectivePermissions: ['read','modifyPermission']
    },
    comment: {
      value: null,
      valueType: 'text',
      effectivePermissions: ['read', 'update', 'modifyPermission']
    }
  },
  effectivePermissions: ['read', 'update', 'delete', 'modifyPermission', 'print'],
  tags: [
    'app:document-type:submission',
    'app:document-icon:insert_drive_file',
    'app:document:type:label-location:left'
  ],
  references: [],
  version: 1,
  template: {
    name: 'beschlussmarkierung_test',
    caption: 'Beschlussmarkierung_Test',
    description: '',
    workflowId: 'ef219817-b304-4767-ab48-b9576ac834c8',
    fields: [

      {
        choices:[],
        id: '1568124a-f867-4ef6-ae1f-2c4a0a02f124',
        name: 'category',
        label: 'Beschlusskategorie',
        validations: [],
        disableInheritance: true,
        documentFieldPermissions: [],
        type: 'dropdownlist'
      },
      {
        id: '627398d5-a5c2-46a2-86cc-a33e0225cbb6',
        name: 'number',
        label: 'Beschlussnummer',
        validations: [],
        disableInheritance: true,
        documentFieldPermissions: [],
        defaultSource: {
          value: 'Beschlussnummer wird ermittelt nach dem Speichern...',
          type: 'static'
        },
        type: 'textbox'
      },
      {
        withTime: true,
        id: 'e87e28be-f90b-45bd-8ec7-7d6411a66ec9',
        name: 'date',
        label: 'Beschlussdatum',
        validations: [
          {
            id: 'ee5a558f-d889-4522-a735-9d1d191d2adf',
            type: 'required'
          }
        ],
        disableInheritance: false,
        documentFieldPermissions: [],
        defaultSource: {
          dateOnly: false,
          roundToStartOfNextMonth: false,
          addDays: 0,
          addWeeks: 0,
          addMonths: 0,
          addYears: 0,
          type: 'today'
        },
        type: 'datetime'
      },
      {
        choices: [
          {
            label: 'Beschlossen wie vorgelegt',
            value: 'approved',
            properties: {
              dataType: 'string'
            }
          },
          {
            label: 'Beschlossen mit Änderungen',
            value: 'approved-with-changes',
            properties: {
              dataType: 'string'
            }
          },
          {
            label: 'nicht Beschlossen',
            value: 'declined',
            properties: {
              dataType: 'string'
            }
          },
          {
            label: 'zur Kenntnis genommen',
            value: 'accepted',
            properties: {
              dataType: 'string'
            }
          }
        ],
        choicesDataSource: {
          name: 'Beschlussvermerke',
          type: 'list'
        },
        id: '16f7a8bb-543e-4754-a49b-4debd9d789c6',
        name: 'decision',
        label: 'Beschlussvermerk',
        validations: [
          {
            id: '2c86d524-94da-4da6-ac55-02ae6df9c65f',
            type: 'required'
          }
        ],
        disableInheritance: false,
        documentFieldPermissions: [],
        defaultSource: {
          value: 'approved',
          type: 'static'
        },
        type: 'radiogroup'
      },
      {
        id: 'b0f8ea89-0c6b-40d9-b181-57ddb56dfe1c',
        name: 'comment',
        label: 'Kommentar',
        validations: [],
        disableInheritance: false,
        documentFieldPermissions: [],
        type: 'textarea'
      }
    ],
    layouts: [
      {
        id: 'c32a9bc8-7dbe-4f32-80ca-ef6554d2bdb6',
        name: 'default',
        layoutVersion: 0,
        entries: [
          {
            label: 'Spalten',
            orientation: 'vertical',
            name: 'decision-cols',
            id: '2ef14ecd-6401-47dd-8713-169c53a9848d',
            width: '100%',
            visible: true,
            additionalData: {
              colCount: 2,
              colSpan: 1,
              labelVisible: false,
              cssClass: '',
              isGroupClosed: false,
              hint: ''
            },
            type: 'group'
          },
          {
            label: 'Beschlussdaten',
            orientation: 'vertical',
            name: 'decision-data',
            id: '23bc441a-af3e-48df-9a95-4c4d7cc658ab',
            width: '100%',
            visible: true,
            parentContainerName: 'decision-cols',
            additionalData: {
              colCount: 1,
              colSpan: 1,
              labelVisible: true,
              cssClass: '',
              isGroupClosed: false,
              hint: ''
            },
            type: 'group'
          },
          {
            fieldName: 'category',
            orientation: 'vertical',
            id: '54d141a0-619f-4d0e-9133-45299f39a193',
            width: '100%',
            height: 'undefined',
            visible: true,
            parentContainerName: 'decision-data',
            additionalData: {
              colSpan: 1,
              description: '',
              cssClass: '',
              labelVisible: true,
              placeholder: '',
              hint: '',
              disabled: false,
              readonly: false
            },
            type: 'field'
          },
          {
            fieldName: 'number',
            orientation: 'vertical',
            id: '7eee93d0-a92f-4f0d-ab9f-0fec56f3b6f5',
            width: '100%',
            height: 'undefined',
            visible: true,
            parentContainerName: 'decision-data',
            additionalData: {
              colSpan: 1,
              description: '',
              cssClass: '',
              labelVisible: true,
              placeholder: 'wird nach dem Speichern erzeugt',
              hint: '',
              disabled: false,
              readonly: false
            },
            type: 'field'
          },
          {
            fieldName: 'date',
            orientation: 'vertical',
            id: '74981f21-50e1-4def-8b5c-ec51c5756037',
            width: '100%',
            height: 'undefined',
            visible: true,
            parentContainerName: 'decision-data',
            additionalData: {
              colSpan: 1,
              description: '',
              cssClass: '',
              labelVisible: true,
              placeholder: '',
              hint: '',
              disabled: false,
              readonly: false
            },
            type: 'field'
          },
          {
            fieldName: 'decision',
            orientation: 'vertical',
            id: 'f9ac1942-1600-4b14-82fa-da170fe099e6',
            width: '100%',
            height: 'undefined',
            visible: true,
            parentContainerName: 'decision-cols',
            additionalData: {
              colSpan: 1,
              description: '',
              cssClass: '',
              labelVisible: true,
              placeholder: '',
              hint: '',
              disabled: false,
              readonly: false
            },
            type: 'field'
          },
          {
            fieldName: 'comment',
            orientation: 'vertical',
            id: '7e7294ae-ff8d-48f3-b283-25e4173cc827',
            width: '100%',
            height: 'undefined',
            visible: true,
            additionalData: {
              colSpan: 1,
              description: '',
              cssClass: ' draggable_YXBwcm92YWxfY29tbWVudA== draggable_YXBwcm92YWxfY29tbWVudA==',
              labelVisible: true,
              placeholder: '',
              hint: '',
              disabled: false,
              readonly: false,
              autoResizeEnabled: false,
              minHeight: '80',
              maxHeight: '80'
            },
            type: 'field'
          }
        ]
      }
    ],
    autoUpdate: false,
    isDisabled: false,
    permissions: [
      {
        roleId: 'd6e4fb93-b737-4949-9933-a98500d0988b',
        allows: ['read', 'list', 'execute'],
        denies: [],
        isInherited: false,
        type: 'role'
      }
    ],
    documentPermissions: [],
    fieldPermissions: [],
    confidentialReferences: [],
    tags: [
      'app:document-type:submission',
      'app:document-icon:insert_drive_file',
      'app:document:type:label-location:left'
    ],
    creation: {
      userId: 'ca936616-cffb-4d69-a8df-a98500d0982d',
      logonUserId: 'ca936616-cffb-4d69-a8df-a98500d0982d',
      user: {
        id: 'ca936616-cffb-4d69-a8df-a98500d0982d',
        userId: 'ca936616-cffb-4d69-a8df-a98500d0982d',
        logonUserId: 'ca936616-cffb-4d69-a8df-a98500d0982d',
        name: 'Admin',
        email: 'siam-development@ventuno.de',
        isIntern: true,
        isArchived: false,
        forename: 'Administrator',
        surname: 'AgiliCision',
        jobTitle: 'Mitarbeiter IT-Orga',
        department: 'IT-Organisation'
      },
      timestamp: '2024-06-17T23:40:28.2376426+02:00'
    }
  }
} as unknown as IDocument;

interface IFieldMapping {
  decisionFieldName: string;
  documentFieldName?: string;
}
export interface IDecisionStamp {
  id?: string;
  name: string;
  displayName: string;
  serialNumberDefinitionId: string;
  decisionChoicesId: string;
  dialogId?: string;
  customData?: Record<string, unknown>;
  decisionDialogFieldMapping?: Record<string, unknown>;
}
export interface IDecisionStampClient {
  id?: string;
  name: string;
  displayName: string;
  serialNumberDefinitionId: string;
  decisionChoicesId: string;
  dialogId?: string;
  decisionDialogFieldMapping?: Record<string, unknown>;
  useDefault?: boolean;
  fieldMapping?: IFieldMapping[];
  color?: string;
}
export const mapBeschlussToServer = (decisionStamp: IDecisionStampClient): IDecisionStamp => {
  const dialogId = decisionStamp.dialogId;
  let decisionDialogFieldMapping = {};
  let useDefault = true;
  if (!dialogId || dialogId == _defaultDecisionTemplateId) {
    useDefault = true;
  } else {
    useDefault = false;
    decisionDialogFieldMapping = decisionStamp.fieldMapping.reduce((acc, current) => {
      acc[current.decisionFieldName] = current.documentFieldName;
      return acc;
    }, {} as Record<string, string>);
  }
  const customData = {
    useDefault,
    color: decisionStamp.color,
  };
  return {
    name: decisionStamp.name,
    displayName: decisionStamp.displayName,
    serialNumberDefinitionId: decisionStamp.serialNumberDefinitionId,
    decisionChoicesId: decisionStamp.decisionChoicesId,
    id: decisionStamp.id,
    customData,
    decisionDialogFieldMapping,
    dialogId
  };
};
export const mapBeschlussToClient = (decisionStamp: IDecisionStamp): IDecisionStampClient => {
  let dialogId = decisionStamp.dialogId;
  const customData = decisionStamp.customData || {};
  let fieldMapping: IFieldMapping[] = [];
  if (dialogId === _defaultDecisionTemplateId || !dialogId) {
    customData.useDefault = true;
    dialogId = null;
  } else {
    customData.useDefault = false;
    if (Object.keys(decisionStamp.decisionDialogFieldMapping).length) {
      fieldMapping = Object.entries(decisionStamp.decisionDialogFieldMapping).map(([key, value]) => {
        return {
          decisionFieldName: key,
          documentFieldName: value
        } as IFieldMapping;
      });
    }
  }
  return {
    name: decisionStamp.name,
    displayName: decisionStamp.displayName,
    serialNumberDefinitionId: decisionStamp.serialNumberDefinitionId,
    decisionChoicesId: decisionStamp.decisionChoicesId,
    id: decisionStamp.id,
    decisionDialogFieldMapping: decisionStamp.decisionDialogFieldMapping,
    fieldMapping,
    useDefault: customData.useDefault as boolean,
    color: customData.color as string,
    dialogId
  };
};
