import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ISecureDataRoomSetting, ISiamInfo } from '@interfaces/siam';
import { LoggerService } from './logger.service';
import { dateToLocal } from '@factories/helpers';

@Injectable({
  providedIn: 'root'
})
export class SiamInfoService {
  private apiBaseUrl: string;
  private readonly dateFormat = 'dd.MM.y - HH:mm';

  constructor(private http: HttpClient, private logger: LoggerService, @Inject('BASE_URL') private baseUrl: string) {
    this.apiBaseUrl = `${this.baseUrl}api/info`;
    this.logger.info('SiamInfoService started.');
  }

  /**
   * get the latest server version
   */
  serverVersion(): Observable<ISiamInfo> {
    return this.http.get<ISiamInfo>(`${this.apiBaseUrl}/server-version`).pipe(
      tap({
        next: info => {
          info.timestamp = dateToLocal(info.timestamp, this.dateFormat);
        },
        error: error => {
          this.logger.error('Fehler beim Get AgiliCision Server Info: {@error}', error);
        }
      })
    );
  }
  /**
   * get environment
   */
  environment(): Observable<string> {
    return this.http.get<string>(`${this.apiBaseUrl}/environment`, { responseType: 'text' as 'json' }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Get AgiliCision Environment Info: {@error}', error);
        }
      })
    );
  }

  /**
   * get the latest production server version
   */
  productVersion(): Observable<ISiamInfo> {
    return this.http.get<ISiamInfo>(`${this.apiBaseUrl}/product-version`).pipe(
      tap({
        next: info => {
          info.timestamp = dateToLocal(info.timestamp, this.dateFormat);
        },
        error: error => {
          this.logger.error('Fehler beim Get AgiliCision Product Version Info: {@error}', error);
        }
      })
    );
  }

  /**
   * get the latest webapp version
   */
  webAppVersion(): Observable<ISiamInfo> {
    return this.http.get<ISiamInfo>(`${this.apiBaseUrl}/web-app-version`).pipe(
      tap({
        next: info => {
          info.timestamp = dateToLocal(info.timestamp, this.dateFormat);
        },
        error: error => {
          this.logger.error('Fehler beim Get AgiliCision Web App Version Info: {@error}', error);
        }
      })
    );
  }
  /**
   * get the Secure Data Romm Setting
   */
  secureDataRoom(): Observable<ISecureDataRoomSetting> {
    return this.http.get<ISecureDataRoomSetting>(`${this.apiBaseUrl}/secure-data-room`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Get den SIcherndatenraum Einstellung: {@error}', error);
        }
      })
    );
  }
}
