import { ITemplateServer } from '@interfaces/ITemplateServer';
import {
  FieldParameterType,
  IEdgeClient,
  INameListValue,
  INameListValueNode,
  IVertexClient,
  TTypes
} from './edgeClient';
import { IEdgeServer, IVertexServer, TDecisionActionType } from './edgeServer';
import DevExpress from 'devextreme';
import { SiamList } from './siamList';
import DataSource from 'devextreme/data/data_source';
import { FieldClient, FieldTypeClient, Group } from './fieldClient';
import { MutationResult } from '@apollo/client';
import { IDxFormItemTemplate, IDxGroupItem, IDxTagBoxOnCustomItemCreating, TFilterGrid } from './devextreme';
import { Legend } from 'devextreme/viz/chart';
import { Palette } from 'devextreme/common/charts';
import { DataType } from 'devextreme/common';
import { IAdditionalPopupSettings } from '@services/popup-helper.service';
import { IDecisionStamp } from './default-decision';
import { DxActionSheetTypes } from 'devextreme-angular/ui/action-sheet';
import Column = DevExpress.ui.dxDataGrid.Column;

/**
 * Types
 */

export type TActionType =
  | 'permission-script'
  | 'set-value'
  | 'send-mail'
  | 'send-mail-template'
  | 'modify-tag'
  | 'create-decision'
  | 'update-decision-status'
  | 'update-decision-values'
  | 'delete-decision'
  | 'create-decision-deprecated'
  | 'remove-decision-deprecated'
  | 'create-ballot'
  | 'abort-ballot'
  | 'meeting-invite'
  | 'meeting-abort'
  | 'meeting-protocol-approval'
  | 'serial-number-peek'
  | 'serial-number-consume'
  | 'compare'
  | 'modify-name-field'
  | 'execute-document-workflow'
  | 'copy-permissions'
  | 'create-document'
  | 'error';

export type TActionTypeDocument = 'document';
export type TActionTypeStatic = 'static';

export type TAgendaActionType =
  | 'create-meeting-minutes'
  | 'create-meeting-minutes-new'
  | 'open-meeting-minutes'
  | 'create-pattern'
  | 'sdr-upload';

export type TCompareWorkflowActionOperator =
  | 'notEqual'
  | 'equal'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual';

export type TDynamicGridColumnDataType = 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'userRole';
export type TDynamicGridSortOrder = 'asc' | 'desc' | null;
export type TDynamicGridTemplateName =
  | 'icon'
  | 'creationDate'
  | 'creatorName'
  | 'modificationDate'
  | 'modificationName'
  | 'creatorDepartment'
  | 'currentAssignee'
  | 'labels'
  | 'progressBar'
  | 'type'
  | 'parentDocuments'
  | 'childDocuments'
  | 'workflowStatus'
  | 'decisionData';

export type TEditMode = 'ReadOnly' | 'Edit' | 'EditPreview';
export type TModifyNameFieldOperation = 'set' | 'clear' | 'add' | 'remove';
export type TApplicationMode = 'application' | 'administration' | 'dashboard';
export type TAssignment = TAssignmentRole | TAssignmentUser;
export type TAssignmentRole = 'role';
export type TAssignmentUser = 'user';

export type TCondition =
  | 'is-empty'
  | 'is-equal'
  | 'ballot-is-finished'
  | 'ballot-is-successful'
  | 'user-did-vote'
  | 'is-agenda'
  | 'has-child'
  | 'has-parent'
  | 'decision-exists'
  | 'decision-status'
  | 'decision-approval-value';

export type TDocumentUrlTag = 'submission' | 'agenda' | 'project' | string;

export type TEffectivePermission =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'list'
  | 'execute'
  | 'modifypermission'
  | 'print';

export type TFieldConfigurationType =
  | 'text'
  | 'number'
  | 'double'
  | 'int'
  | 'integer'
  | 'boolean'
  | 'bool'
  | 'user'
  | 'role'
  | 'date'
  | 'time'
  | 'datetime'
  | 'timestamp'
  | 'references'
  | 'userRole';

export type TLogLevel = 'verbose' | 'debug' | 'information' | 'warning' | 'error' | 'fatal' | '';

export type TNameSelectorDialogType = 'Personen' | 'Rollen' | 'Beide';
export type TSelectableNames = 'users' | 'roles' | 'usersAndRoles';
export type TSourceCopyPermissionsActivityMode =
  | 'extendOrReplace'
  | 'replaceAll'
  | 'combinePreferAllows'
  | 'combinePreferDenies';

export type TNameSelectorDialogMode = 'single' | 'multiple';

export type TNavigationSource = 'application' | 'dashboard';

export type TPermission = IUserPermission | IRolePermission;

export type TQueryObjectCondition = 'and' | 'or';
export type TQueryObjectType = 'include' | 'exclude';

export type TSelectionMode = 'single' | 'multiple';
export type TSelectionAllMode = 'allPages' | 'page';

export type TSecureRoomActive = 'brainloop' | 'fileSystem';

export type TTag =
  | 'app:document-type:submission'
  | 'app:document-type:agenda'
  | 'app:document-type:top'
  | 'app:document-type:pause'
  | 'app:document-type:protocol'
  | 'app:document-type:decision'
  | 'app:document-type:task'
  | 'app:document-type:project'
  | 'app:document-type:meetingMinutes'
  | 'system:document:dependency:child'
  | 'system:document:dependency:parent'
  | 'system:document:dependency:task'
  | string;

export type TDisplayMode = 'Liste' | 'DX-Kanban' | 'Diagramm';

export type TVariableType = 'namesList' | 'wert' | 'boolean' | 'number';

export type TDocumentFieldGraphTypes = IDocumentFieldNamesGraphType | IDocumentFieldAnyGraphType;

export type TErrorType = 'info' | 'warning' | 'error' | 'success';

export type TGraphqlAcoountMode = 'default' | 'activeOnly' | 'archivedOnly' | 'all';

export type TEnvironmentMode = 'Testing' | 'Produktion' | 'Development';

/**
 * Interfaces
 */

export interface IAccount {
  user?: IUser;
  users?: IUser[];
  roles?: IRole[];
}

export interface ISecureRoomConfigBrainLoop {
  url: string;
  proxy: ISecureRoomConfigBrainLoopProxy;
  upload: ISecureRoomConfigBrainLoopUpload;
}

interface ISecureRoomConfigBrainLoopProxy {
  password: string;
  url: string;
  username: string;
}

interface ISecureRoomConfigBrainLoopUpload {
  dataRoomName: string;
  documentCollectionFreezeMode: string;
  documentFreezeMode: string;
  rootFolder: string;
}

export interface ISecureRoomConfigFileSystem {
  basePath: string;
}

export interface IBrainloopLogin {
  username: string;
  password: string;
  pin?: string;
  totp?: string;
  expires?: string;
  receiver?: string;
}

export interface IEncryption {
  algorithm: string;
  iv: string;
  key: string;
  created: string;
}

export interface IUsersRolesEntry {
  id: string;
  name: string;
}

export interface IUsersRoles {
  type: string;
  entries: IUsersRolesEntry[];
}

export interface IActionSheetAction extends DxActionSheetTypes.Item {
  action: TAgendaActionType;
  hint?: string;
  document?: IDocument;
  isNewAgenda?: boolean;
}

export interface IActionStandard {
  [key: string]: unknown;

  name?: string;
  text: string;
  type?: string;
  icon?: string;
  disabled?: boolean;
  visible?: boolean;
  action: string;
}

export interface IActionType {
  source?: unknown;
  value?: unknown;
  name?: unknown;
}

export interface IActionTypeStatic extends IActionType {
  type: TActionTypeStatic;
}

export interface IActionTypeDocument extends IActionType {
  type: TActionTypeDocument;
}

export interface IAgenda {
  children: IAgenda[];
  documentId: string;
  endTime: Date;
  parentDocId: string;
  parentIndex: number;
  startTime: Date;
  totalEndTime: Date;
  index?: number;
  position?: number;
  invalid?: boolean;
  document?: IDocument;
  type?: string;
  speakers?: INameSelectorDialogRecord[];
  attachment?: boolean;
  timeInterval?: number;
  taskCount?: number;
  protocolId?: string;
  approved?: boolean;
}

export interface IAgendaServer {
  children: IAgendaServer[];
  documentId: string;
  endTime: string;
  parentDocId: string;
  parentIndex: number;
  startTime: string;
  totalEndTime: string;
  index?: number;
  position?: number;
  invalid?: boolean;
  document?: IDocument;
  type?: string;
  speakers?: INameSelectorDialogRecord[];
  attachment?: boolean;
  timeInterval?: number;
  taskCount?: number;
  protocolId?: string;
  approved?: boolean;
}

export interface IApprovalForm {
  category: string;
  number: string; // eslint-disable-line
  date: string;
  type: string;
  comment: string;
}

export interface IAssignee {
  type: 'variable' | 'static' | 'document' | 'none';
  name: string;
  value?: string;
  source?: string;
}

export interface IAssignment {
  type: TAssignment;
  targetId: string;
}

export interface IUploadAttachment {
  file: File;
  properties?: Record<string, unknown>;
  tags?: string[];
  description?: string;
}

export interface IAttachment {
  id?: string;
  fileName?: string;
  contentType: string;
  description?: string;
  size?: number;
  data?: unknown;
  dataId?: string;
  tags?: unknown[];
}

export interface IAuditInformation {
  user?: IUser;
  userId?: string;
  logonUserId?: string;
  impersonationId?: string;
  timestamp?: string;
}

export interface IBackground {
  title: string;
  background: string;
  backgroundSrc: string;
  previewSrc?: string;
  colorMode?: 'dark' | 'light';
}

export interface ICanDeactivateComponent {
  canDeactivate(): Promise<boolean>;
}

export interface ICapability {
  id?: string;
  path?: string;
  name?: string;
  category?: string;
  shortDescription?: string;
  longDescription?: string;
  assignments: IAssignment[];
  userAssignments: string[];
  roleAssignments: string[];
}

export interface IConfidential {
  confidentialId?: string;
  parentIds?: string[];
  name: string;
  description?: string;
  selectable?: boolean;
  userIds?: string[];
  roleIds?: string[];
  properties?: Record<string, unknown>;
  tags?: string[];
}

export interface IConfidentialReference {
  confidentialId: string;
  name?: string;
  children: IConfidentialPermission[];
  allowHardening: boolean;
  allowWeakening: boolean;
  parentId?: string | number;
  isDefault?: boolean;
}

export interface IConfidentialPermission {
  confidentialId: string;
  allowHardening: boolean;
  allowWeakening: boolean;
  parentId?: number | string;
  confidential?: IConfidential;
  isDefault?: boolean;
}

export interface IConfidentialPermissionClient {
  confidentialId: string;
  name: string;
  icon: string;
}

export interface IClientConfiguration {
  color: string;
  logo: File;
  background: File;
}

export type TPageOrientation = 'portrait' | 'landscape';
export type TPageSize =
  | 'dinA0'
  | 'dinA1'
  | 'dinA2'
  | 'dinA3'
  | 'dinA4'
  | 'dinA5'
  | 'dinA6'
  | 'dinA7'
  | 'dinA8'
  | 'dinA9'
  | 'dinA10'
  | 'dinB0'
  | 'dinB1'
  | 'dinB2'
  | 'dinB3'
  | 'dinB4'
  | 'dinB5'
  | 'dinB6'
  | 'dinB7'
  | 'dinB8'
  | 'dinB9'
  | 'dinB10'
  | 'ledger'
  | 'letter'
  | 'legal';

export const pageSizeDataSource: { label: string; value: TPageSize }[] = [
  { label: 'DIN A0', value: 'dinA0' },
  { label: 'DIN A1', value: 'dinA1' },
  { label: 'DIN A2', value: 'dinA2' },
  { label: 'DIN A3', value: 'dinA3' },
  { label: 'DIN A4', value: 'dinA4' },
  { label: 'DIN A5', value: 'dinA5' },
  { label: 'DIN A6', value: 'dinA6' },
  { label: 'DIN A7', value: 'dinA7' },
  { label: 'DIN A8', value: 'dinA8' },
  { label: 'DIN A9', value: 'dinA9' },
  { label: 'DIN A10', value: 'dinA10' },
  { label: 'DIN B0', value: 'dinB0' },
  { label: 'DIN B1', value: 'dinB1' },
  { label: 'DIN B2', value: 'dinB2' },
  { label: 'DIN B3', value: 'dinB3' },
  { label: 'DIN B4', value: 'dinB4' },
  { label: 'DIN B5', value: 'dinB5' },
  { label: 'DIN B6', value: 'dinB6' },
  { label: 'DIN B7', value: 'dinB7' },
  { label: 'DIN B8', value: 'dinB8' },
  { label: 'DIN B9', value: 'dinB9' },
  { label: 'DIN B10', value: 'dinB10' },
  { label: 'LEDGER', value: 'ledger' },
  { label: 'LETTER', value: 'letter' },
  { label: 'LEGAL', value: 'legal' }
];

export const pageOrientationDataSource: { label: string; value: TPageOrientation }[] = [
  { label: 'Querformat', value: 'landscape' },
  { label: 'Hochformat', value: 'portrait' }
];

export interface IPageMargin {
  top: number;
  bottom: number;
  left: number;
  right: number;
  unit: string;
}

export interface IPageLayout {
  pageMargin: IPageMargin;
  pageOrientation: TPageOrientation;
  pageSize: TPageSize;
}

export interface ILabelLayout {
  defaultLabelCss: Record<string, string>;
  topLabelCss: Record<string, string>;
}

export interface ILabelLayoutClient {
  defaultLabelCss: string;
  topLabelCss: string;
  defaultLabelCssArray: { property: string; value: string }[];
  topLabelCssArray: { property: string; value: string }[];
}

export interface IFontLayout {
  baseSizeMultiplier: number;
  commonFontSizeMultiplier: number;
  footerFontSizeMultiplier: number;
  firstPageHeaderFontSizeMultiplier: number;
}

export interface IPrintPageLayout {
  fontLayout: IFontLayout;
  labelLayout: ILabelLayout;
  pageLayout: IPageLayout;
}

export interface IPrintPageLayoutClient {
  fontLayout: IFontLayout;
  labelLayout: ILabelLayoutClient;
  pageLayout: IPageLayout;
}

export interface IConfigurationSetFile extends File {
  zip: Blob;
  filename: string;
}

export interface IConfigurationSet {
  archive: IConfigurationSetFile;
  password?: string;
  groupsToClear?: string[];
  ignoreVersion?: boolean;
  importUsers?: boolean;
}

export interface IConfigurationSetExport {
  password?: string;
  allowNoPassword: boolean;
  keepAutoUpdate: boolean;
}

export interface IDocumentField {
  value: any;
  type?: unknown;
  valueType?: unknown;
  permissions?: TPermission[];
  effectivePermissions?: TEffectivePermission[];
  listName?: string;
}

export interface IDocumentFields {
  [KEY: string]: IDocumentField;
}

export interface IDocument {
  id: string;
  creation: IAuditInformation;
  effectivePermissions: TEffectivePermission[];
  permissions: IPermission[];
  fields: IDocumentFields;
  modification: IAuditInformation;
  references: IReference[];
  confidential?: IConfidentialPermission;
  confidentialAllowed?: IConfidentialPermission[];
  tags: TTag[];
  template: ITemplateServer;
  templateId: string;
  documentWorkflowDocument?: IDocumentWorkflowDocument;
  workflowDocuments: IWorkflowDocument[];
  workflows: {
    actions?: IWorkflowAction[];
    actionItems?: IWorkflowActionSheetItem[];
  };
  workflowStatus: string;
}

export interface IMultiSaveDocument {
  $type: 'create' | 'update' | 'document-reference';
  referenceId?: string;
  document?: IDocument;
  documentId?: string;
  documentTemplateId?: string;
  linkTargetReferenceId?: string;
  workflowTemplateId?: string;
}

export interface IResponseMultiSaveDocument {
  data: { id: string };
  httpStatusCode: number;
  httpStatusMessage: string;
}

export interface IDynamicListStateToolbar extends Record<string, unknown> {
  selectedDateRange: TCardPeriod;
  isPreviewEnabled: boolean;
  personalMode: number;
  currentMode: number;
}

export interface IDynamicListState extends IListState {
  toolbar: IDynamicListStateToolbar;
  documentType: string;
}

export interface IDynamicDocumentInfo {
  icon: string;
  tag: string;
  name: string;
  selector?: TFilterGrid;
}

export interface IUserPermission extends IPermission {
  type: TAssignmentUser;
  userId?: string;
}

/**
 * Edge object
 * Part of the workflow
 */

export interface IEdgeCustomValues {
  icon?: string;
  type?: string;
  // festdefinierte Variablen für die einfache Edge-Konfiguration
  wert1?: unknown;
  wert2?: unknown;
  wert3?: unknown;
  namesList1?: IPermissionTarget[];
  namesList2?: IPermissionTarget[];
  namesList3?: IPermissionTarget[];

  [key: string]: unknown;
}

export interface IEdgeCustomValuesClient {
  [key: string]: string | number | boolean | string[];
}

export interface IEdgeCustomValuesServer {
  [key: string]: string | number | boolean | IPermissionTarget[];
}

export interface IEventLabel {
  command: string;
  labels: ILabel[];
}

export interface IEventTask {
  command: string;
  object?: ITask;
}

export interface IResultDiagram {
  fieldName: string;
  value: string;
}

export interface IEventEmitter<T> {
  command: string;
  object?: T;
  value?: boolean;
  variableCreateDocumentName?: string;
  variableCreateDecisionName?: string;
  variableUpdateDecisionName?: string;
  additionalData?: IAdditionalPopupSettings;
}

export interface IEventEmitterValueChaned<T> {
  fieldName: string;
  value: T;
}

export interface IEventEmitterFieldsDrag<T> {
  command: 'select' | 'new';
  object?: T;
  fieldIndex?: number;
}

export interface IEventEmitterExecuteWorkflow<T> {
  command: 'loading' | 'close' | 'save' | 'error';
  object?: T;
}

export interface IEmitterFieldType {
  fieldType: string;
  fieldDataSource: SiamListItem[];
  dateType?: 'date' | 'datetime';
}

export interface ISiamNotification {
  message: string;
  class: TErrorType;
  details?: string;
}

export interface IIcon {
  key: string;
  value: string;
}

export interface ILabel {
  id: string;
  name: string;
  description: string;
  color: string;
  value: string;
  deactivated: boolean;
  visible?: boolean;
}

export interface IBackgroundJob {
  id: number;
  jobIdentifier: string;
  jobName: string;
  createdAt: string;
  startedAt: string | Date;
  endedAt: string | Date;
  currentState: string;
  progress: string;
  configuration: Record<string, unknown>;
  jobGroupIdentifier: string;
  executionVersion: string;
  priority: number;
}

export interface IBackgroundJobCronTrigger {
  trigger: IBackgroundJobCronTriggerProperties;
  configuration: IBackgroundJobCronTriggerConfig;
}

export interface IBackgroundJobCronTriggerProperties {
  schedulerName: string;
  triggerName: string;
  triggerGroup: string;
  cronExpression: string;
}

export interface IBackgroundJobCronTriggerConfig {
  jobName: string;
  jobGroup: string;
  description: string;
  nextFireTime: string;
  previousFireTime: string;
  priority: number;
  triggerState: string;
  triggerType: string;
  startTime: string;
  endTime: string;
  calendarName: string;
  misfireInstructionIdentifier: string;
  jobData: IBackgroundJobCronTriggerConfigJobDataProperty;
}

export interface IBackgroundJobCronTriggerConfigJobDataProperty {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface IBackgroundJobGroupStatus {
  jobGroup: string;
  queueAction: string;
}

export interface IBackgroundJobHistory {
  id: number;
  jobIdentifier: string;
  jobName: string;
  createdAt: string;
  startedAt: string | Date;
  endedAt: string | Date;
  currentState: string;
  progress: string;
  configuration: string;
  delayExecutionUntilAtLeast: string | Date;
  jobGroupIdentifier: string;
  executionVersion: string;
  priority: number;
  details: IBackgroundJobHistoryDetails[];
  extensionDetails: string;
}

export interface IBackgroundJobHistoryDetails {
  detailId: number;
  createdAt: string | Date;
  state: string;
  content: Record<string, unknown>;
}

export interface ICategoryNavigationAccess {
  users?: string[];
  roles?: string[];
}

export interface ICategory {
  name: string;
  label: string;
  description?: string;
  tag: string;
  icon?: string;
  visibleCreation?: boolean;
  visibleNavigation?: boolean;
  visibleHomeCard?: boolean;
  access?: ICategoryNavigationAccess;
  navigationName?: string;
  settings?: IDynamicGridSettings;
}

export interface IDynamicList {
  name: string;
  label: string;
  groupName: string;
  groupNameCaption?: string;
  isVisible: boolean;
  isGroup: boolean;
  isClosed?: boolean;
  description?: string;
  selector?: TFilterGrid;
  selectorConfig?: TFilterGrid;
  icon?: string;
  access?: ICategoryNavigationAccess;
  settings?: IDynamicGridSettings;
}

export type TCardType = 'list' | 'calendar' | 'chart';

export interface IDynamicCard {
  name: string;
  label: string;
  isVisible: boolean;
  type: TCardType;
  description?: string;
  dataSource?: IDxGroupItem[];
  selector?: TFilterGrid;
  access?: ICategoryNavigationAccess;
  settings?: IcardSettings;
  schedulerSettings?: ISchedulerSettings;
  chartSettings?: IChartSetting;
  documentCount?: number;
  additionalSettings?: IDynamicGridSettings;
}

export interface ICustomDialog<T> {
  show: () => T;
  hide: (value?: unknown) => void;
}

export interface IEmailQueue {
  emails: IEmailQueueItem[];
}

export interface IEmailQueueItem {
  id: number;
  receivers?: IEmailQueueItemReceiver[];
  subject: string;
  senderAddress: string;
  senderName: string;
  state: string;
  startedAt: string;
  htmlMessage: string;
  attachments?: unknown[];
  emailContext: IEmailQueueItemContext;
  backgroundJob?: IBackgroundJob;
  sentByAddress?: string;
  replyTo?: string;
}

export interface IEmailQueueItemReceiver {
  receiverName: string;
  receiverAddress: string;
}

/* eslint-disable */
export interface IEmailQueueItemContext {
  ContextType: string;
  ContextData: IEmailQueueItemContextData;
}

export interface IEmailQueueItemContextData {
  DocumentId: string;
  EdgeName: string;
  EmailGuid: string;
}

/* eslint-enable */

export interface IEntraId {
  title: string;
  description: string;
  tenantId: string;
  clientId: string;
  clientSecret: string;
  groupMapping: ILdapGroup[];
  isDisabled: boolean;
}

export interface ILdap {
  title: string;
  description: string;
  url: string;
  queryUserName: string;
  queryPassword: string;
  userBaseDN: string | string[];
  userQueryString: string;
  multiUsersBaseDN?: string[];
  groupsBaseDN: string | string[];
  groupQueryString: string;
  multiGroupsBaseDN?: string[];
  timeout: number;
  baseDN?: string;
  groupMapping: ILdapGroup[];
  userPropertyMapping: ILdapUserProperty;
  mappingDataSource?: Record<string, unknown>[];
  isDisabled?: boolean;
}

export interface ILdapUserProperty {
  mapping: ILdapUserPropertyMapping;
  requiredAttributes: string[];
}

export interface ILdapUserPropertyMapping {
  userName: ILdapUserPropertyMappingAttributes;
  mobile: ILdapUserPropertyMappingAttributes;
  phoneNumber: ILdapUserPropertyMappingAttributes;
  forename: ILdapUserPropertyMappingAttributes;
  surname: ILdapUserPropertyMappingAttributes;
  fullName: ILdapUserPropertyMappingAttributes;
  email: ILdapUserPropertyMappingAttributes;
  department: ILdapUserPropertyMappingAttributes;
  location: ILdapUserPropertyMappingAttributes;
  title: ILdapUserPropertyMappingAttributes;
  jobTitle: ILdapUserPropertyMappingAttributes;
  company: ILdapUserPropertyMappingAttributes;
  profileImage?: ILdapUserPropertyMappingAttributes;
  substitutes?: ILdapUserPropertyMappingAttributes;
}

export interface ILdapUserPropertyMappingAttributes {
  attributeNames: string[];
}

export interface ILdapConfig {
  id: string;
  provider: 'ldap' | 'entra-id';
  configuration: ILdap | IEntraId;
  isAutomatic: boolean;
  exceptWeekend: boolean;
  hour: number;
  minute: number;
}

export interface ILdapGroup {
  providerGroupId: string;
  name: string;
  roles: string[];
}

export interface ILdapUserSource {
  company: string;
  department: string;
  email: string;
  forename: string;
  fullName: string;
  groups: string[];
  logonName: string;
  mobilePhoneNumber: string;
  placeOfEmployment: string;
  providerGroupIds: string[];
  providerId: string;
  surname: string;
  title: string;
}

export interface IListState {
  toolbar: Record<string, unknown>;
  grid: Record<string, unknown>;
}

export interface ILogClient {
  timestamp: string;
  logLevel: TLogLevel;
  userName: string;
  source: string;
  module?: string;
  action?: string;
  message: string;
  details?: string;
  properties?: ILogProperties;
  requestId?: string;
  requestPath?: string;
}

export interface ILogProperties {
  /* eslint-disable */
  LogEventSource?: string;
  SourceContext?: string;
  ActionName?: string;
  EndpointName?: string;
  RequestId?: string;
  RequestPath?: string;
  DocumentId?: string;
  Properties?: string | Record<string, unknown>;
  /* eslint-enable */
}

export interface ILogServer {
  timestamp: string;
  logLevel: TLogLevel;
  userName?: string;
  message: string;
  details?: string;
  properties?: ILogProperties;
}

export interface ILogLevel {
  defaultLogLevel: TLogLevel;
  overrides: Record<string, TLogLevel>;
}

export interface IMailTemplate {
  id?: string;
  name: string;
  description: string;
  subject: string;
  body: string;
  isHtml: boolean;
  withInvite: boolean;
  tags: string[];
  invitationClass: 'public' | 'confidential';
  isPrivate: boolean;
}

export interface INavigationBarCategory {
  text: string;
  icon: string;
  badge?: number;
}

export interface ITemplateTypes {
  text: string;
  type: string;
  icon?: string;
  badge?: string;
}

export interface ITemplateSelector {
  documentTemplate?: ITemplateServer;
  icon: string;
  label: string;
  source: string;
}

export interface INavigationGroup {
  id?: number;
  name?: string;
  text: string;
  links: INavigationLink[];
  isClosed: boolean;
  forAdminOnly: boolean;
  capabilities?: string[];
  icon?: string;
}

export interface INavigationLink {
  id?: number;
  text: string;
  name: string;
  href?: string;
  routerLink?: string[];
  forAdminOnly?: boolean;
  capabilities?: string[];
  icon?: string;
}

export interface INameSelectorDialogRecord {
  targetId: string;
  type: TAssignmentUser | TAssignmentRole;
}

export interface INameSelectorDialogInclude {
  role?: IRole;
  targetId?: string;
  type: string;
}

export interface INameSelectorDialogSettings {
  selectedRecords?: INameSelectorDialogRecord[];
  title?: string;
  filteringEnabled?: boolean;
  fullScreen?: boolean;
  selectType: TNameSelectorDialogType;
  selectionMode: TNameSelectorDialogMode;
  minSelection?: number;
  include?: INameSelectorDialogInclude[];
  exclude?: INameSelectorDialogInclude[];
}

export interface IPermission {
  allows?: TEffectivePermission[];
  denies?: TEffectivePermission[];
  isInherited?: boolean;
  isArchived?: boolean;
  name?: string;
  type?: string;
  role?: IRole;
  user?: IUser;
}

export interface IPermissionTarget {
  type: TAssignment;
  targetId: string;
  compositeId?: string;
}

export interface IReference {
  tags: TTag[];
  revision?: number;
  document?: IDocument;
  referencedDocumentId?: string;
  documentId?: string;
}

export interface IRole {
  id: string;
  name: string;
  isArchived?: boolean;
  description: string;
  capabilities: string[];
  tags: string[];
  users?: IUser[];
  compositeId: string;
}

export interface IRolePermission extends IPermission {
  type: TAssignmentRole;
  roleId?: string;
}

export interface ISiamInfo {
  version: string;
  timestamp: string;
  codename: string;
}

export interface ISecureDataRoomSetting {
  active: 'fileSystem' | 'brainloop';
  requiresAuthentication: boolean;
}

export interface SiamListItem {
  id?: string;
  label?: string;
  name?: string;
  value: any; // Record<string, string>, string;
  properties?: unknown;
  disabled?: boolean;
  visible?: boolean;
}

export interface ISiamListPropriety {
  dataType?: SiamListPropriety;
  isTreeList?: boolean;
  parentId?: string;
}

export type SiamListPropriety = 'string' | 'number' | 'roles';
export const siamListProprietyTypes = [
  { label: 'Textfeld', value: 'string' },
  { label: 'Numerisches Feld', value: 'number' },
  { label: 'Namensfeld (Rollen)', value: 'roles' }
];

export interface IErrorExceptionDetail {
  message: string; //'This is a test exception.'
  type: string; // 'System.InvalidOperationException'
  raw: string; // the whole stacktrace
}

export interface IErrorDetail {
  code?: string;
  description?: string;
  detail?: string;
  details?: string;
  errors: Record<string, string[]>;
  exceptionDetails?: IErrorExceptionDetail[];
  message?: string;
  messages?: string[];
  status: number; // 500
  title: string; // "Internal Server Error"
  traceId: string; // "00-ea0e46a12665a332c6ff1e9e54cdd665-9be74846cdbd30e9-00"
  type: string; // "https://httpstatuses.com/500"
}

export interface IError {
  error: IErrorDetail | IErrorDetail[];
  message: string;
  name: string;
  status: number;
  statusText: string;
  ok: boolean;
  url: string;
}

export interface IFeedback {
  subject: string;
  body: string;
  isHtmlBody: boolean;
  attachments?: File[];
}

export interface IGQLResult<T> extends MutationResult {
  data: T;
}

export interface ITag {
  key: string;
  value: string;
}

export interface ITask {
  id?: string;
  document?: IDocument;
  state?: string;
  statelabel?: string;
  priority?: string;
  enddate?: string;
  chair?: string[];
  chairname?: string;
  chairinitials?: string;
  chairurl?: string;
  subject?: string;
  body?: string;
  labels?: ILabel[];
}

export interface ITheme {
  name: string;
  cssClass: string;
  imageSrc?: string;
  logo?: string;
}

export interface TemplateSelectorConfiguration {
  categories: TTag[];
  title: string;
}

export interface IUser {
  id: string;
  logonUser?: IUser;
  impersonationId?: string;
  substituteUsers?: IUser[];
  substituteRoles?: IRole[];
  name: string;
  email: string;
  roles: IRole[];
  rolesIds?: string[];
  profile: IUserProfile;
  capabilities: string[];
  confidentialLevels: IConfidential[];
  effectiveCapabilities: string[];
  emailConfirmed: boolean;
  isAdministrator: boolean;
  isArchived: boolean;
  isIntern: boolean;
  isPasswordChangeRequired: boolean;
  isLockedOut: boolean;
  failedLoginCount: number;
  connectedLogins: string[];
  compositeId: string;
  displayName: string;
  selectorName: string;
  fullName: string;
  userName?: string;
  substitutes?: IPermissionTarget[];
  clientSubstitutes?: string[];
}

export interface IUserPipe {
  id: string;
  avatarName: string;
  avatarNameBig: string;
  avatarUrl: string;
  avatar: string;
  fullName: string;
  initials: string;
  name: string;
  loginName?: string;
  department: string;
  logonUser?: IUserPipe;
  lastname?: string;
}

export interface ISearchUser {
  query: string;
  properties?: string[];
  mode?: 'contains' | 'startsWith';
}

export interface ISearchUserRersponse {
  id: string;
  name: string;
  properties: Record<string, string>;
}

export interface IUserInputTemplateFieldInExclude {
  user?: {
    id: string;
  };
  role?: IRole;
}

export interface ISelectableNames {
  includes: IPermissionTarget[];
  excludes: IPermissionTarget[];
  selection: TSelectableNames;
}

export interface IUserRoleEntry {
  type?: TAssignment;
  id: string;
  compositeId?: string;
  name: string;
  visible?: boolean;
  interne?: boolean;
  archived?: boolean;
  selectorName?: string;
  fullName?: string;
  userName?: string;
  profile?: IUserProfile;
  users?: IUser[];
  roles?: IRole[];
}

export interface ILabelValue<T = string> {
  label: string;
  value: T;
  icon?: string;
}

export interface IUserPicture {
  data?: string;
  lastUpdate?: string;
  mimeType?: string;
  originalFileName?: string;
  tags?: string[];
  url: string;
  hasDefaultPicture?: boolean;
  displayNamePicture?: string;
}

export interface IUserProfile {
  jobTitle?: string;
  title?: string;
  forename?: string;
  surname?: string;
  department?: string;
  company?: string;
  location?: string;
  substitute?: string;
  picture?: IUserPicture;
}

export interface IVertex {
  id?: string; // eslint-disable-line
  name: string;
  label: string;
  flags: string[];
}

/**
 * Workflow object
 */
export interface IWorkflow {
  id?: string;
  autoUpdate?: boolean;
  description: string;
  edges: IEdgeServer[];
  effectivePermissions?: TEffectivePermission[];
  name: string;
  vertices: IVertexServer[];
  permissions?: IPermission[];
  version?: string;
}

export interface IWorkflowClient {
  id?: string;
  autoUpdate?: boolean;
  description: string;
  edges: IEdgeClient[];
  effectivePermissions?: TEffectivePermission[];
  name: string;
  vertices: IVertexClient[];
  permissions?: IPermission[];
  version?: string;
  dataSourceBallot?: INameListValueNode[];
  labels?: ILabel[];
}

export interface IDocumentFieldAnyGraphType {
  __typename: string; // eslint-disable-line
  name?: string;
}

export interface IDocumentFieldNamesGraphType {
  __typename: string; // eslint-disable-line
  name?: string;
  value?: (IReferenceRoleGraphType | IReferenceUserGraphType)[];
}

export interface IReferenceRoleGraphType {
  type?: 'role';
  targetId?: string;
  role?: IRole;
}

export interface IReferenceUserGraphType {
  type?: 'user';
  targetId?: string;
  user?: IUser;
}

export interface IWorkflowDocument {
  id?: string;
  creation: IAuditInformation;
  fields: IDocumentFields;
  tags?: string[];
  typedFields: TDocumentFieldGraphTypes[];
  workflow: {
    edges: IEdgeServer[];
    vertices: IVertexServer[];
  };
}

export interface IDocumentWorkflowDocument {
  workflowId?: string;
  currentStatus?: string;
  currentStatusLabel?: string;
  currentAssignee?: IPermissionTarget[];
}

export interface IWorkflowAction {
  documentId: string;
  workflowId: string;
  edgeId?: string;
  name: string;
  label: string;
  description: string;
  isUsable: boolean;
  reason: string;
  customValues?: IEdgeCustomValues;
  userInputTemplate?: ITemplateServer;
  userInput?: IDocumentFields;
  userInputTemplateLink?: ITemplateServer;
  createDocumentInputTemplates?: Record<string, ICreateDocumentInputTemplate>;
  createDecisionDocumentTemplates?: Record<string, string>;
  decisionStampsCreate?: Record<string, IDecisionStamp>;
  decisionStampsUpdates?: IDecisionStampUpdateAction[];
}

export interface IDecisionStampUpdateAction {
  $type: string;
  category: string;
  variableName: string;
}

export interface ICreateDocumentInputTemplate {
  documentTemplateId: string;
  variables: Record<string, unknown>;
}

export interface IWorkflowActionSheetItem {
  action: IWorkflowAction;
  text: string;
  type?: string;
  icon?: string;
  hint?: string;
}

export interface IMeetingMinutesActionSheetItem {
  document: IDocument;
  text: string;
  hint?: string;
  icon?: string;
  isNewAgenda?: boolean;
}

export interface IWorkflowHistoryUser {
  name: string;
  fullName?: string;
  picture: string;
  logonUser?: IUser;
}

export interface IWorkflowHistoryAssignee extends IWorkflowHistoryUser {
  type: string;
}

export interface IWorkflowHistoryActionLog {
  type: string;
  icon: string;
  start: Date;
  end: Date;
  isSuccess: boolean;
  message: string;
  action?: IActionData;
  detailsVisible?: boolean;
  actionId?: string;
  actionComment?: string;
}

export interface IActionData {
  formItems?: (FieldClient | Group)[];
  formData?: Record<string, unknown>;
}

export interface IFieldNewValue {
  value: unknown;
  type: FieldTypeClient;
}

export interface IWorkflowHistoryVote {
  time: Date;
  voter: IWorkflowHistoryUser;
  vote: string;
}

export interface IPossibleVote {
  value: string;
  label: string;
  isSuccess: boolean;
  properties?: {
    voteDiagramColor?: string;
  };
}

export interface IHistoryField {
  assignees: IPermissionTarget[];
  user: { userId: string; logonUserId?: string };
  timestamp: Date;
  selectedEdge: IEdgeServer;
  targetVertex: IVertex;
  actionLogs?: IHistoryFieldActionLog[];
  variables?: { [KEY: string]: unknown };
}

export interface IHistoryFieldActionLog {
  type: string;
  start: Date;
  end: Date;
  isSuccess: boolean;
  entries: {
    type: string;
    level: string;
    message: string;
  }[];
}

export interface IWorkflowHistory {
  time: Date;
  user: IWorkflowHistoryUser;
  edgeId: string;
  edge: string;
  name: string;
  sourceVertex: string;
  targetVertex: string;
  assignee: IWorkflowHistoryAssignee[];
  activitiesVisible: boolean;
  actionLogs?: IWorkflowHistoryActionLog[];
  votes?: IWorkflowHistoryVote[];
  variables?: Record<string, unknown>;
  isDocument: boolean;
  subformName?: string;
  userInputTemplateLink?: ITemplateServer;
  isAutomaticExecuted?: boolean;
  onDocument: () => void;
  onMouseEnterUser?: (Event: Event) => void;
  onMouseLeaveUser?: () => void;
}

export interface IEventVisibleCategorie {
  visible: boolean;
}

export interface IDynamicGridSettingsColumn extends Omit<Column, 'dataType'> {
  attachmentField?: string;
  choices?: SiamListItem[];
  choicesName?: string;
  columnType?: FieldTypeClient;
  isTemplate?: boolean;
  isAutoWidth?: boolean;
  isHidden?: boolean;
  isBreakWord?: boolean;
  isGrouping?: boolean;
  isGroupingDisabled?: boolean;
  templateName?: TDynamicGridTemplateName;
  decisionCategory?: string[];
  decisionField?: string;
  showGroupingOption?: boolean;
  showAttachment?: boolean;
  showSortingOption?: boolean;
  showFilterOption?: boolean;
  dataType?: DataType | TDynamicGridColumnDataType;
  defaultStatusValue?: string;
  showStatusColor?: boolean;
}

export interface IDynamicGridSettingsToolbar {
  dateField?: string;
  dateFilterDefault?: TCardPeriod;
  dateFilterDataSource?: TCardPeriod[];
  userField: string;
  isShowDateFilter: boolean;
  isShowExportExcel: boolean;
  isShowGrouping: boolean;
  isShowPersonalMode: boolean;
  isShowPreviewSwitch: boolean;
  rowAlternationEnabled: boolean;
  isShowSearch: boolean;
  isShowTitle?: boolean;
  // for the tasks page
  isShowKanban?: boolean;
  isShowDiagramm?: boolean;
}

export interface IDynamicGridSettings {
  isEditMode?: boolean;
  excludeTags?: string[];
  includeTags?: string[];
  includeOneOfTags?: string[];
  includeOneOfTemplateId?: string[];
  includeParentIds?: string[];
  excludeParentIds?: string[];
  includeChildIds?: string[];
  excludeChildIds?: string[];
  excludeDocumentIds?: string[];
  includeDocumentIds?: string[];
  excludeStatus?: string[];
  hasNoParent?: boolean;
  hasParent?: boolean;
  hasParentTag?: string;
  hasNoChild?: boolean;
  hasChild?: boolean;
  hasChildTag?: string;
  pageSize?: number;
  summary?: boolean;
  decisionCategories?: string[];
  toolbar?: IDynamicGridSettingsToolbar;
  columns?: IDynamicGridSettingsColumn[];
}

export type TSchedulerViewTypes =
  | 'day'
  | 'week'
  | 'workWeek'
  | 'month'
  | 'timelineDay'
  | 'timelineWeek'
  | 'timelineWorkWeek'
  | 'timelineMonth'
  | 'agenda';

export interface ISchedulerSelector {
  selector?: TFilterGrid;
  startDate?: string;
  endDate?: string;
  subjectField?: string;
  bodyField?: string;
  color?: string;
}

export interface ISchedulerSettings {
  selectors?: ISchedulerSelector[];
  views?: TSchedulerViewTypes[];
  currentView?: TSchedulerViewTypes;
}

export const PeriodDataSource: ILabelValue<TCardPeriod>[] = [
  { value: 'previousWeek', label: 'Letzte Woche', icon: 'icon ion-md-alarm' },
  { value: 'currentWeek', label: 'Aktuelle Woche', icon: 'icon ion-md-alarm' },
  { value: 'nextWeek', label: 'Nächste Woche', icon: 'icon ion-md-alarm' },
  { value: 'previousTwoWeek', label: 'Letzte 14 Tage', icon: 'icon ion-md-alarm' },
  { value: 'nextTwoWeek', label: 'Nächste 14 Tage', icon: 'icon ion-md-alarm' },
  { value: 'currentThirtyDays', label: 'Letzten/Nächsten 15 Tage', icon: 'icon ion-md-alarm' },
  { value: 'previousThirtyDays', label: 'Letzte 30 Tage', icon: 'icon ion-md-alarm' },
  { value: 'nextThirtyDays', label: 'Nächste 30 Tage', icon: 'icon ion-md-alarm' },
  { value: 'currentSixtyDays', label: 'Letzten/Nächsten 30 Tage', icon: 'icon ion-md-alarm' },
  { value: 'currentOneHundredEightyDays', label: 'Letzten/Nächsten 90 Tage', icon: 'icon ion-md-alarm' },
  { value: 'currentThreeHundredSixtyDays', label: 'Letzten/Nächsten 180 Tage', icon: 'icon ion-md-alarm' },
  { value: 'currentMonth', label: 'Aktueller Monat', icon: 'icon ion-md-alarm' },
  { value: 'previousThreeMonth', label: 'Letzte 3 Monate', icon: 'icon ion-md-alarm' },
  { value: 'nextThreeMonth', label: 'Nächste 3 Monate', icon: 'icon ion-md-alarm' },
  { value: 'previousSixMonth', label: 'Letzte 6 Monate', icon: 'icon ion-md-alarm' },
  { value: 'nextSixMonth', label: 'Nächste 6 Monate', icon: 'icon ion-md-alarm' },
  { value: 'future', label: 'Zukünftige', icon: 'icon ion-md-alarm' },
  { value: 'past', label: 'Vergangene', icon: 'icon ion-md-alarm' },
  { value: 'currentYear', label: 'Aktuelles Jahr', icon: 'icon ion-md-alarm' },
  { value: 'all', label: 'Gesamt', icon: 'icon ion-md-timer' }
];
export type TCardPeriod =
  | 'previousWeek'
  | 'currentWeek'
  | 'nextWeek'
  | 'previousTwoWeek'
  | 'nextTwoWeek'
  | 'currentMonth'
  | 'currentYear'
  | 'previousThreeMonth' // ab heute 3 Monate zurück
  | 'nextThreeMonth'
  | 'previousSixMonth'
  | 'nextSixMonth'
  | 'currentYear'
  | 'all'
  | 'future'
  | 'past'
  | 'previousThirtyDays' // previous 30 days ab heute
  | 'nextThirtyDays' // next 30 days ab heute
  | 'currentThirtyDays' //current 30 days; previous 15 days and next 15 days
  | 'currentSixtyDays' // current 60 days; previous 30 days and next 30 days
  | 'currentOneHundredEightyDays' // current 180 days; previous 90 days and next 90 days
  | 'currentThreeHundredSixtyDays'; // current 360 days; previous 90 days and next 90 days
export interface IcardSettings {
  listName?: string;
  cardColor?: string;
  icon?: string;
  text?: string;
  subText?: string;
  gridSettings?: IDynamicGridSettings;
}

export type TChartType = 'stackedbar' | 'piechart' | 'doughnut';
export const ChartTypeDataSource: { label: string; value: TChartType }[] = [
  { value: 'piechart', label: 'Kreisdiagramm' },
  { value: 'stackedbar', label: 'gestapelte Balken' },
  { value: 'doughnut', label: 'Ringdiagramm' }
];
export const PaletteDataSource = [
  'Bright',
  'Harmony Light',
  'Ocean',
  'Pastel',
  'Soft',
  'Soft Pastel',
  'Vintage',
  'Violet',
  'Carmine',
  'Dark Moon',
  'Dark Violet',
  'Green Mist',
  'Soft Blue',
  'Material',
  'Office'
];

export type TChartSettingGroup =
  | 'creatorName'
  | 'template'
  | 'currentAssignee'
  | 'workflowStatus'
  | 'fieldValue'
  | 'template.caption';

export const ChartSettingGroupDataSource: { label: string; value: TChartSettingGroup }[] = [
  { value: 'creatorName', label: 'Ersteller' },
  { value: 'currentAssignee', label: 'Workflow-Empfänger' },
  { value: 'workflowStatus', label: 'Workflow-Status' },
  { value: 'fieldValue', label: 'Dokumentwert' },
  { value: 'template.caption', label: 'Dokumenttyp' }
];

export interface IChartSettingTooltip {
  visible?: boolean;
  percent?: boolean;
}

export interface IChartSettingGroup {
  type: TChartSettingGroup;
  fieldName?: string;
  fieldType?: TDynamicGridColumnDataType;
  fieldListName?: string;
}

export interface IChartSetting {
  type?: TChartType;
  palette?: Palette;
  title?: string;
  subtitle?: string;
  groups?: IChartSettingGroup[];
  legend?: Legend;
  isDateFilter?: boolean;
  dateField?: string;
  period?: TCardPeriod;
  ignoreNullValue?: boolean;
  tooltip?: IChartSettingTooltip;
  label?: IChartSettingTooltip;
}

export interface IParameterData {
  namesListValues: INameListValue[] | INameListValueNode[];
  lists: SiamList[];
  workflow: IWorkflowClient;
  usersAndRoleDataSource: DataSource;
  type: 'update' | 'create';
}

export type TDefaultValuetypes = 'current-user' | 'static' | 'list-all-entries' | 'today' | 'none' | 'serial-number';
export type TModeSerialNumber = 'PEEK' | 'CONSUME_ONCE' | 'CONSUME_ALWAYS';
export const serialNumberMode: { label: string; value: TModeSerialNumber }[] = [
  { label: 'Nächste Nummer ermitteln', value: 'PEEK' },
  { label: 'Nächste Nummer ermitteln und verbrauchen(einmal)', value: 'CONSUME_ONCE' },
  { label: 'Nächste Nummer ermitteln und verbrauchen', value: 'CONSUME_ALWAYS' }
];

export interface IDefaultValueSourceStatic<T> {
  type: TDefaultValuetypes;
  value: T;
}

export interface IDefaultValueSourceAllListEntries {
  type: TDefaultValuetypes;
  listName: string;
}

export interface IEdgeFieldOptions {
  allowedTypes: TTypes[];
  allowedStaticTypes: FieldParameterType[];
  required: boolean;
}

export interface IDefaultValueSourceToDay {
  type: TDefaultValuetypes;
  dateOnly: boolean;
  roundToStartOfNextMonth: boolean;
  addDays: number;
  addWeeks: number;
  addMonths: number;
  addYears: number;
  hour: number;
  minute: number;
}

export interface IDefaultValueSourceSerialNumber {
  type: TDefaultValuetypes;
  name: string;
  mode: TModeSerialNumber;
}

export interface IDefaultValueSourceCurrentUser {
  type: TDefaultValuetypes;
}

export type TDefaultValueSource =
  | IDefaultValueSourceStatic<unknown>
  | IDefaultValueSourceAllListEntries
  | IDefaultValueSourceToDay
  | IDefaultValueSourceCurrentUser
  | IDefaultValueSourceSerialNumber;

export interface ISearch {
  query?: string;
  requiredTag?: TTag;
  skip?: number;
  count?: number;
  sortFields?: string[];
  resultConfiguration?: {
    returnFieldLists?: boolean;
    selectedFields?: string[];
  };
  groupFields?: {
    path: string;
    descending: boolean;
  }[];
  countType?: string;
  fieldConfiguration?: IDocumentSearchFieldConfiguration[];
}

export interface ISearchGroupField {
  path: string;
  descending: boolean;
  sortType: string;
}

export interface ISearchGroups {
  query?: string;
  requiredTag?: TTag;
  groupFields?: ISearchGroupField[];
  countType?: 'documents' | 'children';
}

export interface ILabelHeaderFilter {
  text: string;
  value: string[];
  key?: string;
  userName?: string;
}

export interface IColumnHeaderFilter {
  text: string;
  key?: string;
  value: unknown[];
  data?: unknown;
}

export interface IDocumentsSearch {
  dateField?: string;
  dateFrom?: Date | string;
  dateTo?: Date | string;
  tag?: TTag;
  includeTags?: string[];
  includeOneOfTags?: string[];
  includeOneOfTemplateId?: string[];
  excludeTags?: string[];
  includeParentIds?: string[];
  excludeParentIds?: string[];
  includeChildIds?: string[];
  excludeChildIds?: string[];
  excludeDocumentIds?: string[];
  includeDocumentIds?: string[];
  excludeStatus?: string[];
  hasNoParent?: boolean;
  hasParent?: boolean;
  hasParentTag?: string;
  hasNoChild?: boolean;
  hasChild?: boolean;
  hasChildTag?: string;
  skip?: number;
  count?: number;
  sortFields?: Record<string, ISortFieldsValue>;
  groupFields?: ISearchGroupField[];
  query?: string;
  filterSeparator?: boolean;
  user?: IUser;
  userField?: string;
  isIncludeLists: boolean;
  documentFields: string[];
  fieldConfiguration?: IDocumentSearchFieldConfiguration[];
}

export interface IDocumentSearchFieldConfiguration {
  fieldName: string;
  typeDescriptor: TFieldConfigurationType;
}

export interface ISortFieldsValue {
  desc: boolean;
}

export interface ISearchResponse {
  skip?: number;
  count?: number;
  totalCount?: number;
  documents: IDocument[];
  lists?: Record<string, SiamList>;
  groups?: { key: string; count: number }[];
}

export interface ISearchGroupResponse {
  totalCount: number;
  groups?: IGroup[];
}

export interface IGroup {
  key: string | IDocumentField | IPermissionTarget[] | string[];
  count: number;
  subGroups?: IGroup[];
}

export interface ISortColumn {
  desc: boolean;
  selector: string;
}

export type TSignatureColumnType = 'timeStamp' | 'user' | 'references' | 'text' | 'iconTemplate' | 'signatureTemplate';

export interface ISignatureColumn {
  type: TSignatureColumnType;
  caption: string;
  visible: boolean;
  dataField?: string;
  format?: string;
  list?: string;
  width?: string;
  cssClass?: string;
}

export interface IConfirmDialog {
  show: () => Promise<boolean>;
}

export interface ILoadPanelSettings {
  id?: string;
  message?: string;
  loading: boolean;
  height?: number | string;
  width?: number | string;
}

export interface ICustomValuesSignature {
  width?: number;
  height?: number;
  maxWidth?: number;
  penColor?: string;
  backgroundColor?: string;
  isSecurityPatternEnabled?: boolean;
}

export interface IEventEmitterSignature {
  dataField: string;
  field: IDxFormItemTemplate<IAttachment[]>;
  attachment: string;
}

export type TPageSetting = IHomePageSetting | IStartPageSetting;

export interface IHomePageSetting {
  showSearchButton: boolean;
  showFeedbackButton: boolean;
  showHelpButton: boolean;
  supportEmail: string;
  supportPhoneNumber: string;
}

export interface IStartPageSetting {
  background: IBackground;
}

export interface ISelectableCategories<T> {
  dataSource?: DataSource;
  items?: T[];
  multiline: boolean;
  displayExpr?: string;
  valueExpr?: string;
  showClearButton?: boolean;
  searchEnabled: boolean;
  acceptCustomValue: boolean;
  onCustomItemCreating: (e: IDxTagBoxOnCustomItemCreating) => void;
}

export interface IQueryObject {
  and: IQueryObjectType;
  or: IQueryObjectType;
  context?: IQueryObjectContext;
}

export interface IQueryObjectType {
  include: string[];
  exclude: string[];
}

export interface IQueryObjectContext {
  condition: TQueryObjectCondition;
  type?: TQueryObjectType;
}

export interface IRelationShips {
  origin: string;
  dependencies: string[];
}

export interface IPasswordRequirements {
  requiredLength: number;
  requiredUniqueChars: number;
  requireNonAlphanumeric: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requireDigit: boolean;
}

export interface ITemplateInformation {
  templateId: string;
  templateName: string;
  templateCaption: string;
}

export interface IDocumentHierarchy {
  documentId: string;
  documentTags: string[];
  documentFields: Record<string, string>;
  templateInformation: ITemplateInformation;
  parentDocuments: IDocumentHierarchy[];
}

export interface IDocumentBreadcrumb {
  documentId: string;
  document?: IDocument;
  isCurrent?: boolean;
  text: string;
  icon?: string;
  child: IDocumentBreadcrumb;
}

export interface IDocumentDecision {
  caption: string;
  decisionName: string;
  categoryId: string;
  document: IDocument;
  isActive: boolean;
  status: TDecisionActionType;
  color: string;
  title: string;
  statusCaption?: string;
  creationSource?: string;
}

export interface IagendaDecisionData {
  agendaId: string;
  topId: string;
  contentId: string;
  decisionId?: string;
  decisionName?: string;
}

export interface IAgendaDecision {
  approvalStatus?: TDecisionActionType;
  fields?: IDocumentFields;
}
