import { getTagName } from '@factories/helpers';
import { IDxEditorSettings, IDxEditorToolbarItemOptions } from './devextreme';
import { defaultSignatureColumns, IAgendaAllowedTemplates } from './fieldClient';
import {
  ICustomValuesSignature,
  IPermissionTarget,
  IRole,
  ISignatureColumn,
  IUserInputTemplateFieldInExclude,
  SiamListItem,
  TDefaultValueSource,
  TPermission
} from './siam';
import { IAgendaSettings } from './fieldAgenda';

export interface IFieldDataSourceStatic {
  type: 'static';
  json: SiamListItem[] | string;
}

export interface IFieldDataSourceList {
  type: 'list';
  name: string;
}

// 'group' ist kein Feld-Typ!
export type FieldTypeServer =
  | 'attachments'
  | 'signature'
  | 'checkbox'
  | 'checkboxgroup'
  | 'datetime'
  | 'dropdownlist'
  | 'group'
  | 'field'
  | 'html'
  | 'number'
  | 'permission-targets'
  | 'placeholder'
  | 'radiogroup'
  | 'slider'
  | 'textbox'
  | 'textarea'
  | 'documents';

export enum ValidationType {
  required = 'required',
  requiredIfVisible = 'required-if-visible'
}

export class ValidationBaseServer {
  id?: string;
}

export class ValidationRequiredServ extends ValidationBaseServer {
  type = ValidationType.required;
}

export class ValidationRequiredIfVisibleServ extends ValidationBaseServer {
  type = ValidationType.requiredIfVisible;
}

export type ValidationServer = ValidationRequiredServ | ValidationRequiredIfVisibleServ;

export interface IFieldBaseServer {
  allowMultiple?: boolean;
  choices?: SiamListItem[];
  choicesDataSource?: IFieldDataSourceStatic | IFieldDataSourceList;
  customValues?: any;
  decimalPlaces?: number;
  default?: any;
  defaultSource?: TDefaultValueSource;
  disableInheritance?: boolean;
  documentFieldPermissions?: TPermission[];
  exclude?: (IUserInputTemplateFieldInExclude | IPermissionTarget)[];
  forbidRoles?: boolean;
  forbidUsers?: boolean;
  id?: string;
  include?: (IUserInputTemplateFieldInExclude | IPermissionTarget)[];
  label: string;
  maxValue?: number;
  minValue?: number;
  name: string;
  parentContainerName?: string;
  selection?: string;
  supportedContentTypes?: string[];
  threeState?: boolean;
  type: FieldTypeServer;
  underlyingType?: string;
  validations: ValidationServer[];
  withTime?: boolean;
}

export interface FieldDateBoxServ extends IFieldBaseServer {
  withTime: boolean;
}

export interface FieldPermissionTargetServ extends IFieldBaseServer {
  allowMultiple: boolean;
  forbidRoles: boolean;
  forbidUsers: boolean;
  customValues: {
    targetField?: string;
    minSelection?: number;
    targetFieldExclude?: string;
    currentUserExclude?: boolean;
    currentAssigneeExclude?: boolean;
  };
  include?: ({ user: { id: string } } | { role: IRole } | IPermissionTarget)[];
  exclude?: ({ user: { id: string } } | { role: IRole } | IPermissionTarget)[];
  resolve?: ({ user: { id: string } } | { role: IRole } | IPermissionTarget)[];
  selection?: string;
}

export interface FileUploaderServ extends IFieldBaseServer {
  supportedContentTypes: string[];
}

export interface FieldCheckBoxServ extends IFieldBaseServer {
  threeState: boolean;
}

export interface FieldCheckBoxGroupServ extends IFieldBaseServer {
  choices: SiamListItem[];
  choicesDataSource: IFieldDataSourceList;
}

export interface FieldRadioGroupServ extends IFieldBaseServer {
  choices: SiamListItem[];
  choicesDataSource: IFieldDataSourceStatic | IFieldDataSourceList;
}

export interface FieldSelectBoxServ extends IFieldBaseServer {
  choices: SiamListItem[];
  choicesDataSource: IFieldDataSourceStatic | IFieldDataSourceList;
}

export interface FieldNumber extends IFieldBaseServer {
  decimalPlaces?: number;
  underlyingType?: string;
}

export interface FieldDocumentsServ extends IFieldBaseServer {
  allowNesting?: boolean;
  allowedDocumentTypes?: string[];
}

export type FieldServer =
  | FieldCheckBoxServ
  | FieldCheckBoxGroupServ
  | FieldRadioGroupServ
  | FieldNumber
  | FieldSelectBoxServ
  | FieldDateBoxServ
  | FieldPermissionTargetServ;

type TLayoutContainerType = 'field' | 'layout-reference' | 'group' | 'placeholder' | 'spacer';
export type TLayoutPlaceholderTypeServer =
  | 'decision'
  | 'votes'
  | 'toc:agenda'
  | 'signature'
  | 'toc:meeting-minutes'
  | 'tasks'
  | 'reference-documents'
  | 'diagram:gantt'
  | 'diagram:dx-diagram'
  | '--agendaitems';

export class BaseLayoutEntryServ {
  id?: string;
  type: TLayoutContainerType;
  width?: string;
  parentContainerName?: string;
  visible?: boolean;
  visibleFormula?: { id?: string; language: string; code: string };
  height?: string;
  index: string;

  constructor(initType: TLayoutContainerType, init?: Partial<BaseLayoutEntryServ>) {
    this.type = initType;
    if (init?.id) {
      this.id = init.id;
    }
    this.width = init?.width || null;
    this.height = init?.height || null;
    this.index = init?.index || null;
    this.parentContainerName = init?.parentContainerName || undefined;
    this.visible = init && init.visible;
    if (init?.visibleFormula) {
      this.visibleFormula = init.visibleFormula;
    }
  }
}

export class LayoutFieldEntryServ extends BaseLayoutEntryServ {
  fieldName?: string;
  orientation?: string;
  additionalData?: {
    description?: string;
    colSpan?: number;
    cssClass?: string;
    labelVisible?: boolean;
    placeholder?: string;
    hint?: string;
    disabled?: boolean;
    readonly?: boolean;
    staticText?: boolean;
    isLinkText?: boolean;
    customValuesSignature?: ICustomValuesSignature;

    linkName?: string;
    minHeight?: string;
    maxHeight?: string;
    linkContent?: string;
    thousandsSeparator?: boolean;
    fileuploaderButtonTitel?: string;
    fileuploaderMaxFileSize?: number;
    checkboxGroupColumns?: number;
    showEditModeButton?: boolean;
    htmlEditorToolbarItemsOptions?: IDxEditorToolbarItemOptions;
    htmlEditorSettings?: IDxEditorSettings;
    autoResizeEnabled?: boolean;
    isTreeList?: boolean;
    isTreeListParent?: boolean;
    isTreeListChild?: boolean;
    treeListParentFieldName?: string;
  };

  constructor(type: FieldTypeServer, init?: Partial<LayoutFieldEntryServ>) {
    super('field', init);
    this.height = init?.height || null;
    this.fieldName = init?.fieldName || '';
    this.orientation = init?.orientation || 'vertical';
    this.additionalData = {
      colSpan: init?.additionalData?.colSpan || 1,
      description: init?.additionalData?.description || '',
      cssClass: init?.additionalData?.cssClass || '',
      labelVisible: init?.additionalData?.labelVisible,
      placeholder: init?.additionalData?.placeholder || '',
      hint: init?.additionalData?.hint || '',
      disabled: init?.additionalData?.disabled || false,
      readonly: init?.additionalData?.readonly || false
    };
    this.getAdditionalData(type, init);
  }

  getAdditionalData(type: FieldTypeServer, init?: Partial<LayoutFieldEntryServ>): void {
    if (init?.additionalData?.isTreeList) {
      this.additionalData.isTreeList = init?.additionalData?.isTreeList;
      this.additionalData.isTreeListParent = init?.additionalData?.isTreeListParent;
      this.additionalData.isTreeListChild = init?.additionalData?.isTreeListChild;
      this.additionalData.treeListParentFieldName = init?.additionalData?.treeListParentFieldName;
    }

    switch (type) {
      case 'checkboxgroup':
        this.additionalData.checkboxGroupColumns = init?.additionalData?.checkboxGroupColumns || 0;
        break;

      case 'number':
        this.additionalData.thousandsSeparator = init?.additionalData?.thousandsSeparator || false;
        break;

      case 'attachments':
        this.additionalData.fileuploaderButtonTitel =
          init?.additionalData?.fileuploaderButtonTitel || 'Anlagen auswählen';
        this.additionalData.fileuploaderMaxFileSize = init?.additionalData?.fileuploaderMaxFileSize || 1024 * 1024 * 20;
        break;
      case 'signature':
        this.additionalData.customValuesSignature = init?.additionalData?.customValuesSignature || null;
        break;

      case 'html':
        if (init.additionalData.staticText) {
          this.additionalData.staticText = init?.additionalData?.staticText;
        }
        this.additionalData.showEditModeButton = init?.additionalData?.showEditModeButton || false;
        this.additionalData.htmlEditorToolbarItemsOptions = init.additionalData.htmlEditorToolbarItemsOptions;
        this.additionalData.htmlEditorSettings = init.additionalData.htmlEditorSettings;
        break;

      case 'textarea':
        if (init.additionalData.staticText) {
          this.additionalData.staticText = init?.additionalData?.staticText;
        }
        if (init.additionalData.isLinkText) {
          this.additionalData.isLinkText = init?.additionalData?.isLinkText;
          this.additionalData.linkName = init?.additionalData?.linkName || '';
          this.additionalData.linkContent = init?.additionalData?.linkContent || '#';
        }
        this.additionalData.autoResizeEnabled = init?.additionalData?.autoResizeEnabled || false;
        this.additionalData.minHeight = init?.additionalData?.minHeight || null;
        this.additionalData.maxHeight = init?.additionalData?.maxHeight || null;
        break;
      default:
        break;
    }
  }
}

export class LayoutGroupEntryServ extends BaseLayoutEntryServ {
  name: string;
  label: string;
  orientation?: 'vertical' | 'horizontal';
  additionalData?: {
    colCount?: number;
    colSpan?: number;
    labelVisible?: boolean;
    cssClass?: string;
    isGroupClosed?: boolean;
    hint?: string;
  };
  hint?: string;

  constructor(init?: Partial<LayoutGroupEntryServ>) {
    super('group', init);
    this.label = init?.label || '';
    this.name = init?.name || '';
    this.orientation = init?.orientation || 'vertical';
    this.additionalData = {
      colCount: init?.additionalData?.colCount || 1,
      colSpan: init?.additionalData?.colSpan || 1,
      labelVisible: init?.additionalData?.labelVisible,
      cssClass: init?.additionalData?.cssClass?.startsWith('#') ? init.additionalData.cssClass : '',
      isGroupClosed: init?.additionalData?.isGroupClosed,
      hint: init?.additionalData?.hint || ''
    };
  }
}

export class LayoutSpacerEntryServ extends BaseLayoutEntryServ {
  name: string;
  label: string;
  additionalData?: {
    colCount?: number;
    colSpan?: number;
    labelVisible?: boolean;
    cssClass?: string;
    isGroupClosed?: boolean;
  };
  hint?: string;

  constructor(init?: Partial<LayoutGroupEntryServ>) {
    super('spacer', init);
    this.label = init?.label || '';
    this.name = init?.name || '';
    this.additionalData = {
      colCount: init?.additionalData?.colCount || 1,
      colSpan: init?.additionalData?.colSpan || 1,
      labelVisible: init?.additionalData?.labelVisible,
      cssClass: init?.additionalData?.cssClass?.startsWith('#') ? init.additionalData.cssClass : '',
      isGroupClosed: init?.additionalData?.isGroupClosed
    };
  }
}

export class LayoutEntryPlaceholderServ extends BaseLayoutEntryServ {
  name: string;
  placeholderType: TLayoutPlaceholderTypeServer;
  label: string;
  additionalData?: {
    childrenTags?: string[];
    childrenDynamicListTag?: string;
    dynamicListName?: string;
    showParentDocuments?:boolean;
    childrenAllowedTemplates?: string[];
    agendaAllowedTemplates?: IAgendaAllowedTemplates;
    allowCreateChild?: boolean;
    allowAddReference?: boolean;
    taskTemplateId?: string;
    taskType?: 'list' | 'gantt' | 'both';
    colCount?: number;
    colSpan?: number;
    labelVisible?: boolean;
    cssClass?: string;
    isGroupClosed?: boolean;
    signatureColumns?: ISignatureColumn[];
    hint?: string;
    agendaSettings?: IAgendaSettings;
    isMeetingMinutes?: boolean;

  };

  constructor(init?: Partial<LayoutEntryPlaceholderServ>) {
    super('placeholder', init);
    this.label = init?.label || '';
    this.placeholderType = init?.placeholderType || 'tasks';
    this.name = init?.name || this.placeholderType;
    this.additionalData = {
      colCount: init?.additionalData?.colCount || 1,
      colSpan: init?.additionalData?.colSpan || 1,
      labelVisible: init?.additionalData?.labelVisible,
      cssClass: init?.additionalData?.cssClass?.startsWith('#') ? init.additionalData.cssClass : '',
      isGroupClosed: init?.additionalData?.isGroupClosed,
      hint: init?.additionalData?.hint || ''
    };
    this.getAdditionalData(this.placeholderType, init);
  }

  getAdditionalData(type: TLayoutPlaceholderTypeServer, init?: Partial<LayoutEntryPlaceholderServ>): void {
    switch (type) {
      case 'tasks':
        this.additionalData.taskType = init?.additionalData?.taskType || 'list';
        this.additionalData.taskTemplateId = init?.additionalData?.taskTemplateId || null;
        this.additionalData.allowCreateChild =
          init?.additionalData?.allowCreateChild !== undefined ? init?.additionalData?.allowCreateChild : true;
        this.additionalData.allowAddReference =
          init?.additionalData?.allowAddReference !== undefined ? init?.additionalData?.allowAddReference : true;
        this.additionalData.dynamicListName = init?.additionalData?.dynamicListName || null;

        break;

      case 'reference-documents':
        this.additionalData.childrenAllowedTemplates = init?.additionalData?.childrenAllowedTemplates || [];
        this.additionalData.showParentDocuments = init?.additionalData?.showParentDocuments || false;
        this.additionalData.dynamicListName =
          init?.additionalData?.dynamicListName || getTagName(this.additionalData.childrenDynamicListTag);
        this.additionalData.allowCreateChild =
          init?.additionalData?.allowCreateChild !== undefined ? init?.additionalData?.allowCreateChild : true;
        this.additionalData.allowAddReference =
          init?.additionalData?.allowAddReference !== undefined ? init?.additionalData?.allowAddReference : true;
        break;

      case 'toc:agenda':
        this.additionalData.agendaAllowedTemplates = init?.additionalData?.agendaAllowedTemplates || null;
        break;
      case 'signature':
        this.additionalData.signatureColumns = init?.additionalData?.signatureColumns || defaultSignatureColumns;
        break;
      case '--agendaitems':
        this.additionalData.agendaSettings = init?.additionalData?.agendaSettings || null;
        this.additionalData.isMeetingMinutes = init?.additionalData?.isMeetingMinutes || false;
        break;

      default:
        break;
    }
  }
}

export class LayoutServ {
  id?: string;
  name: string;
  entries: LayoutEntryServer[];
}

export type LayoutEntryServer =
  | LayoutFieldEntryServ
  | LayoutGroupEntryServ
  | LayoutEntryPlaceholderServ
  | LayoutSpacerEntryServ;
