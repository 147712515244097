import {
  IAdditionalPopupSettings,
  PopupHelperService,
  PopupSettings,
  ToolbarItem
} from '@services/popup-helper.service';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotifyService } from '@services/notify.service';
import { DxPopupComponent } from 'devextreme-angular';
import { TemplateService } from '@services/template.service';
import { lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { IDocument, IEventEmitter, IWorkflowDocument, TEditMode, TTag } from '@interfaces/siam';
import { confirm } from 'devextreme/ui/dialog';
import { LoggerService } from '@services/logger.service';
import { DocumentHelperService } from '@services/document-helper.service';
import { DocumentIncludeComponent } from '../../forms/subforms/document-include/document-include.component';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { clone } from '@factories/helpers';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss']
})
export class DocumentEditorComponent implements OnDestroy {
  @ViewChild(DocumentIncludeComponent, { static: false }) documentIncludeComponent: DocumentIncludeComponent;
  @ViewChild('dialogEditor', { static: true }) dialogEditor: DxPopupComponent;

  @Output() dialogResult = new EventEmitter<IEventEmitter<IDocument>>();
  @Input() parentDocument: IDocument; // optional Document to link task to
  @Input() isNewAgendaContainer = false;

  additionalData: IAdditionalPopupSettings = null;
  ballotDocuments: IWorkflowDocument[];
  dialogDocument: IDocument = null;
  dialogEditmode: TEditMode = 'ReadOnly';
  dialogFullscreen = false;
  dialogPreview = true;
  dialogTitle: string;
  dialogToolbarItems: ToolbarItem[];
  referencedDocuments: Observable<IDocument[]>;

  #destroyable$ = new Subject<void>();
  #hasRightToUpdate = false;
  #hasRightToDelete = false;

  constructor(
    private documentHelperService: DocumentHelperService,
    private documentService: DocumentService,
    private logger: LoggerService,
    private popupHelper: PopupHelperService,
    private templateService: TemplateService
  ) {}

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  show = async (
    document: IDocument,
    settings?: PopupSettings,
    additionalData?: IAdditionalPopupSettings
  ): Promise<void> => {
    this.logger.debug('document-editor.show() aufgerufen.');
    if (!document) {
      this.logger.error('Es wurde kein Dokument übergeben.');
      return;
    }
    this.dialogDocument = document;
    this.additionalData = additionalData;
    this.logger.debug('übergebenes Dokument {@0}', this.dialogDocument);

    if (document?.id) {
      this.ballotDocuments = await lastValueFrom(this.documentService.getBallotDocuments(document));
    }

    this.dialogEditor.toolbarItems = null;
    this.referencedDocuments = this.documentService.getReferencedDocuments(document);

    this.setDefaultToolbar();
    this.checkDocumentPermissions();

    if (settings) {
      this.dialogEditmode = settings.editMode;
      this.dialogTitle = settings.title ? settings.title : document.template.caption;
      this.dialogFullscreen = settings.fullScreen ? settings.fullScreen : false;

      const isDecisionDocument =
        additionalData?.allowCreateDecision ||
        additionalData?.allowUpdateDecision ||
        additionalData?.allowDeleteDecision;
      if (settings.openNewWindow === false) {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Open', 'visible', false);
      }

      this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Title', 'text', this.dialogTitle);

      if (!this.dialogFullscreen) {
        this.dialogEditor.maxWidth = '90vw';
        this.dialogEditor.maxHeight = '90vh';
      }

      // Position für den Previewmodus (rechts) setzen
      if (settings.preview) {
        this.dialogPreview = settings.preview;
        this.dialogEditor.position = 'right'; // { my: 'right top'};
        this.dialogEditor.maxWidth = '50vw';
        this.dialogEditor.maxHeight = '75vh';
      } else {
        this.dialogEditor.position = 'center'; // { my: 'right top'};
      }

      if (settings.editMode === 'ReadOnly') {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Edit', 'visible', false);
      } else {
        if (this.#hasRightToUpdate && settings.editMode === 'Edit' && !isDecisionDocument) {
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Open', 'visible', false);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Edit', 'visible', false);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Save', 'visible', true);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'SaveAndClose', 'visible', true);

          if (
            this.dialogDocument.id &&
            this.documentService.isProtocol(this.dialogDocument) &&
            this.#hasRightToDelete
          ) {
            this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Delete', 'visible', true);
          }
        }
      }
      if (additionalData?.allowUpdateDecision) {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Update-decision', 'visible', true);
      }
      if (additionalData?.allowDeleteDecision) {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Delete-decision', 'visible', true);
      }
      if (additionalData?.allowCreateDecision) {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Create-decision', 'visible', true);
      }
      if (isDecisionDocument) {
        this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Edit', 'visible', false);
      }
      this.dialogEditor.toolbarItems = this.dialogToolbarItems;
    }
    this.showDialog();
  };

  createDocument(doctype: TTag[], parentDoc?: IDocument, templateId?: string): void {
    this.logger.debug('document-editor.createDocument() aufgerufen.');
    this.dialogDocument = null;

    let stream$: Observable<ITemplateServer>;
    if (templateId) {
      stream$ = this.templateService.getTemplate(templateId);
    } else {
      stream$ = this.templateService.getActiveTemplates('execute', doctype).pipe(
        map(templates => {
          if (templates && !templates.length) {
            let message = '';
            switch (doctype[0]) {
              case 'app:document-type:task':
                message = 'für die Erstellung einer Aufgabe';
                break;

              case 'app:document-type:protocol':
                message = 'für die Erstellung eines TOP-Protokolls';
                break;

              case 'app:document-type:decision':
                message = 'für die Erstellung eines Beschluss';
                break;

              default:
                break;
            }
            const errorMessage = `Es wurde keine Vorlage ${message} gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.`;
            NotifyService.global.error(errorMessage);
            throwError(() => errorMessage);
            return null;
          }
          const taskTemplateAgenda = templates.find(temp =>
            temp.caption.toLocaleLowerCase().includes(parentDoc?.template?.caption?.toLocaleLowerCase())
          );
          return taskTemplateAgenda || templates[0];
        })
      );
    }

    stream$
      .pipe(
        filter(t => t !== null),
        switchMap(documentTemplate => this.documentService.createDocumentByTemplateId(documentTemplate)),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: docToShow => {
          this.logger.debug('erstellte neues Dokument vom Typ [{0]]', doctype);
          this.dialogTitle = docToShow.template.caption;
          this.dialogEditmode = 'Edit';
          this.setDefaultToolbar();
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Title', 'text', this.dialogTitle);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Save', 'visible', true);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'SaveAndClose', 'visible', true);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Edit', 'visible', false);
          this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Open', 'visible', false);
          this.dialogEditor.toolbarItems = this.dialogToolbarItems;
          if (parentDoc && this.documentService.checkMatchingFields(docToShow, parentDoc)) {
            const isTop = this.documentService.isTop(parentDoc);
            void this.confirmDialogBox(isTop).then(async dialogResult => {
              if (dialogResult) {
                try {
                  this.dialogDocument = await this.documentService.createFromParent(docToShow, parentDoc);
                  this.showDialog();
                } catch (error) {
                  this.logger.error('document-editor.createDocument():createFromParent(): {@error}:', error);

                  NotifyService.global.error(
                    `Das Übernehmen der Inhalte ist fehlgeschlagen, die Erstellung des Vorgangs wurde abgebrochen. Möglicherweise liegt ein Konfigurationsfehler im entsprechenden Dokumenttyp vor!
                    <br> ${(error as TypeError).message}`
                  );
                }
              }
              this.dialogDocument = docToShow;
              void this.dialogEditor.instance.show();
            });
          } else {
            this.dialogDocument = docToShow;
            this.showDialog();
          }
        }
      });
  }

  confirmDialogBox(isTop: boolean): Promise<boolean> {
    let message = `des Vorgangs`;
    if (isTop) {
      message = `des TOPs`;
    }
    return confirm(`Sollen Inhalte ${message} übernommen werden?`, `Erstellung der ${this.dialogTitle}`);
  }

  setDefaultToolbar(): void {
    // Aktionen für Popup registrieren ...
    this.dialogToolbarItems = [
      {
        name: 'TitleIcon',
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        options: { type: 'normal', icon: 'file', elementAttr: { class: 'button-icon' } }
      },
      {
        name: 'Title',
        text: '',
        location: 'before',
        toolbar: 'top',
        visible: true
      },
      {
        name: 'Fullscreen',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'fullscreen', hint: 'Vollbildmodus umschalten' },
        onClick: this.dialogToogleFullscreen
      },
      {
        name: 'Open',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'material-icons open_in_new', hint: 'Dokument öffnen' },
        onClick: this.dialogOpenDocument
      },
      {
        name: 'Delete',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: { type: 'normal', icon: 'clear', text: 'Protokoll löschen', hint: 'Protokoll löschen' },
        onClick: this.dialogDelete
      },
      {
        name: 'Create-decision',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: {
          type: 'normal',
          icon: 'check',
          text: 'Beschlussmarkierung speichern',
          hint: 'Beschlussmarkierung erstellen.'
        },
        onClick: this.dialogCreateDecision
      },
      {
        name: 'Update-decision',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: {
          type: 'normal',
          icon: 'check',
          text: 'Beschlussmarkierung speichern',
          hint: 'Beschlussmarkierung wird geändert.'
        },
        onClick: this.dialogUpdateDecision
      },
      {
        name: 'Delete-decision',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: {
          type: 'normal',
          icon: 'clear',
          text: 'Beschlussmarkierung entfernen',
          hint: 'Beschlussmarkierung wird entfernt, das Dokument selbst bleibt erhalten.'
        },
        onClick: this.dialogDeleteDecision
      },
      {
        name: 'Edit',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: true,
        options: { type: 'normal', icon: 'edit', text: 'Bearbeiten', hint: 'Bearbeitungsmodus wechseln' },
        onClick: this.dialogToggleEdit
      },
      {
        name: 'Save',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: { icon: 'save', text: 'Speichern', hint: 'Speichern' },
        onClick: this.dialogSave
      },
      {
        name: 'SaveAndClose',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: false,
        options: { icon: 'check', text: 'Speichern und Schließen', hint: 'Speichern und Schließen' },
        onClick: this.dialogSaveAndClose
      },
      {
        name: 'Close',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        visible: true,
        options: { icon: 'close', text: 'Schließen', hint: 'Schließen' },
        onClick: this.dialogCancel
      }
    ];
  }

  checkDocumentPermissions(): void {
    if (this.dialogDocument) {
      if (this.dialogDocument.effectivePermissions) {
        this.logger.debug('lese effPermissons aus...');

        if (this.dialogDocument.effectivePermissions.some(s => s === 'update')) {
          this.#hasRightToUpdate = true;
        }

        if (this.dialogDocument.effectivePermissions.some(s => s === 'delete')) {
          this.#hasRightToDelete = true;
        }
      } else {
        this.logger.debug('neues Dokument, Berechtigung zum Bearbeiten implizit vorhanden.');
        this.#hasRightToUpdate = true;
      }
    } else {
      this.logger.warn('Dialogdokument noch nicht verfügbar.');
    }
  }

  // ==========================================================================================================
  // Popup-Aktionen
  // ==========================================================================================================
  dialogToogleFullscreen = (): void => {
    this.dialogFullscreen = !this.dialogFullscreen;
    if (this.dialogFullscreen) {
      this.dialogEditor.maxWidth = '';
      this.dialogEditor.maxHeight = '';
    } else {
      if (this.dialogPreview) {
        this.dialogEditor.maxWidth = '90vw';
        this.dialogEditor.maxHeight = '90vh';
      } else {
        this.dialogEditor.maxWidth = '90vw';
        this.dialogEditor.maxHeight = '90vh';
      }
    }
  };

  dialogToggleEdit = (): void => {
    this.logger.debug('dialogToggleEdit() called');
    this.dialogEditmode = 'Edit';
    this.dialogEditor.toolbarItems = null;

    if (
      (this.documentService.isProtocol(this.dialogDocument) || this.documentService.isDecision(this.dialogDocument)) &&
      this.#hasRightToDelete
    ) {
      this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Delete', 'visible', true);
      const options = this.documentService.isProtocol(this.dialogDocument)
        ? { type: 'normal', icon: 'clear', text: 'Protokoll löschen', hint: 'Protokoll löschen' }
        : { type: 'normal', icon: 'clear', text: 'Beschluss löschen', hint: 'Beschluss löschen' };
      this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Delete', 'options', options);
    }
    this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Save', 'visible', this.#hasRightToUpdate);
    this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'SaveAndClose', 'visible', this.#hasRightToUpdate);
    this.popupHelper.setToolbarOption(this.dialogToolbarItems, 'Edit', 'visible', false);

    this.dialogEditor.toolbarItems = this.dialogToolbarItems;
    this.logger.debug('Toolbar: {@0}', this.dialogEditor.toolbarItems);
    const dialogDocument = clone(this.dialogDocument);
    this.dialogDocument = null;
    setTimeout(() => {
      this.dialogDocument = dialogDocument;
    }, 100);
  };

  dialogCancel = (): void => {
    this.logger.debug('dialogCancel() called, clearing popup-data');
    this.dialogEditmode = 'ReadOnly';
    this.dialogDocument = null;
    void this.dialogEditor.instance.hide();
    this.documentIncludeComponent.currentDocument = this.parentDocument;
    this.documentIncludeComponent.initDocument();
    this.dialogResult.emit({
      command: 'cancel'
    });
  };

  dialogOpenDocument = (): void => {
    if (this.dialogDocument?.id) {
      this.documentHelperService.openDocument(this.dialogDocument, this.dialogEditmode);
    }
  };

  dialogDeleteDecision = (): void => {
    this.dialogResult.emit({
      command: 'delete-decision',
      object: this.dialogDocument,
      additionalData: this.additionalData
    });
    this.dialogCancel();
  };
  dialogUpdateDecision = (): void => {
    this.documentIncludeComponent
      .prepareForSave$()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: document => {
          this.dialogResult.emit({
            command: 'update-decision',
            object: document,
            additionalData: this.additionalData
          });
          this.dialogCancel();
        }
      });
  };
  dialogCreateDecision = (): void => {
    this.documentIncludeComponent
      .prepareForSave$()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: document => {
          this.dialogResult.emit({
            command: 'create-decision',
            object: document,
            additionalData: this.additionalData
          });
          this.dialogCancel();
        }
      });
  };

  dialogDelete = (): void => {
    const documentType = this.documentService.isProtocol(this.dialogDocument)
      ? 'Protokoll'
      : this.documentService.isDecision(this.dialogDocument)
      ? 'Beschluss'
      : 'Dokument';
    void confirm(`Möchten Sie dieses ${documentType} wirklich löschen?`, `${documentType} löschen`).then(
      dialogResult => {
        if (dialogResult) {
          this.dialogResult.emit({
            command: 'loading'
          });
          this.documentService
            .delete(this.dialogDocument, true, true)
            .pipe(takeUntil(this.#destroyable$))
            .subscribe({
              next: () => {
                NotifyService.global.success(`${documentType} ist gelöscht...`);
                this.dialogResult.emit({
                  command: 'remove',
                  object: this.dialogDocument
                });
                this.dialogCancel();
              },
              error: () => {
                this.dialogResult.emit({
                  command: 'cancel'
                });
              }
            });
        }
      }
    );
  };

  dialogSaveAndClose = (): void => {
    this.dialogSave(null, true);
  };

  dialogSave = (_: Event, isClose = false): void => {
    // das hier ist das TOP-Dokument, welches gespeichert wird
    // und in die AgendaListe des TO-Dokumentes geschrieben wird, wenn DAS Dokument gespeichert wird
    this.documentIncludeComponent
      .prepareForSave$()
      // von der document-view-Komponente vorbereitetes ServerDokument holen
      .pipe(
        tap(() => {
          if (isClose) {
            void this.dialogEditor.instance.hide();
          }
          this.dialogResult.emit({
            command: 'loading'
          });
        }),
        switchMap(newDocument =>
          this.documentService.save(newDocument, this.isNewAgendaContainer ? null : this.parentDocument?.id)
        ),
        tap({
          next: savedDocument => {
            this.logger.debug('gespeichertes Dokument ist vom Typ {0}', this.documentService.getType(savedDocument));
          },
          error: error => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error?.length) {
              this.logger.error(error as Error);
            }
            this.dialogResult.emit({
              command: 'cancel'
            });
          }
        }),
        switchMap(savedDocument => {
          NotifyService.global.success('Erfolgreich gespeichert');
          const hasParent = this.documentService.isParentDocument(savedDocument, this.parentDocument);
          if (this.parentDocument && this.documentService.isTop(savedDocument) && !hasParent) {
            return this.documentService.setReference(savedDocument.id, this.parentDocument.id).pipe(
              switchMap(result => {
                this.dialogResult.emit({
                  command: this.dialogDocument?.id ? 'update' : 'store',
                  object: result,
                  additionalData: this.additionalData
                });
                return of(savedDocument);
              })
            );
          } else {
            this.dialogResult.emit({
              command: this.dialogDocument?.id ? 'update' : 'store',
              object: savedDocument,
              additionalData: this.additionalData
            });
            return of(savedDocument);
          }
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: savedDocument => {
          if (isClose) {
            this.dialogDocument = null;
          } else {
            this.dialogDocument = savedDocument;
          }
        },
        error: error => {
          NotifyService.component.error(error as string);
          this.dialogResult.emit({
            command: 'cancel'
          });
        }
      });
  };

  private showDialog(): void {
    void this.dialogEditor.instance.show();
  }
}
