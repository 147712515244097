import { Injectable } from '@angular/core';
import { DocumentApi } from '../api/document.api';
import { from, map, Observable, of, switchMap, tap, zip } from 'rxjs';
import {
  IAttachment,
  IDocument,
  IMultiSaveDocument,
  IPermissionTarget,
  IResponseMultiSaveDocument
} from '@interfaces/siam';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { TemplateService } from './template.service';
import { NotifyService } from './notify.service';
import { LayoutEntryPlaceholderServ } from '@interfaces/fieldServer';
import { INewAgendaServer, TAgendaItemType, TreeViewItem } from '@interfaces/fieldAgenda';
import { clone, flattAgendaHierarchy, isHasProperty, processObservablesRecursively } from '@factories/helpers';
import {
  checkIfValidUUID,
  copy,
  createPermissionTargetWithoutCompositeId,
  isCompositeId
} from '@factories/document.factory';
import { DocumentService } from './document.service';
import { siamConst } from '@interfaces/siamConst';

@Injectable({
  providedIn: 'root'
})
export class AgendaServiceService {
  private topTemplateDocument: IDocument;
  private topTemplate: ITemplateServer;
  private patternAgendaItems: INewAgendaServer[] = [];
  private mapAgendaIds: Record<string, string> = {};
  private agendaTOPsToSave: IDocument[];
  private referencesToSave: { parentId: string; documentId: string }[];
  private referencesToRemove: { parentId: string; documentId: string }[];

  constructor(
    private documentApi: DocumentApi,
    private templateService: TemplateService,
    private documentService: DocumentService
  ) {}

  setReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.setReferences(items);
  }
  removeReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.removeReferences(items);
  }

  prepareAgendaToSave(agendaDocument: IDocument, documentClient: IDocument): Observable<IDocument> {
    return of(agendaDocument).pipe(
      switchMap(() => {
        const agendaField = agendaDocument.template.fields.find(field => field.type === 'documents');
        if (agendaField) {
          const fieldLayout = agendaDocument.template.layouts[0].entries.find(
            entry => (entry as LayoutEntryPlaceholderServ)?.name === agendaField.name
          );
          if ((fieldLayout as LayoutEntryPlaceholderServ)?.additionalData?.isMeetingMinutes) {
            this.agendaTOPsToSave = [];
            this.referencesToSave = [];
            this.referencesToRemove = [];
            return of(documentClient);
          } else {
            const value = documentClient.fields[agendaField.name].value as TreeViewItem[];
            const plainArray = flattAgendaHierarchy(value);
            return this.fetchAgendaItemsDataToSave(plainArray, documentClient).pipe(map(() => documentClient));
          }
        }
        return of(documentClient);
      })
    );
  }

  fetchAgendaItemsDataToSave(items: TreeViewItem[], agendaDocument: IDocument): Observable<unknown> {
    return of(agendaDocument).pipe(
      switchMap(() => {
        this.getTOPDocumentsToSave(items, agendaDocument);
        return this.saveItems(this.agendaTOPsToSave);
      }),
      switchMap(() => this.setReferences(this.referencesToSave)),
      switchMap(() => this.removeReferences(this.referencesToRemove))
    );
  }

  createPatternFromDocument(agendaDocument: IDocument, agendaItemsClient: TreeViewItem[]): Observable<unknown> {
    return this.createTOPDocument(agendaDocument).pipe(
      switchMap(() => this.cloneAgendaItems(agendaDocument, agendaItemsClient)),
      switchMap(newDocument => {
        newDocument.fields[siamConst.agendaField] = { value: this.patternAgendaItems, type: 'documents' };
        newDocument.tags.push(siamConst.patternTag);
        newDocument.tags.push(`${siamConst.patternTemplateIdTag}:${agendaDocument.template.id}`);
        return this.documentService.multiSave([
          {
            $type: 'create',
            document: newDocument,
            documentTemplateId: newDocument.templateId || newDocument.template?.id,
            workflowTemplateId: siamConst.defaultWorkflowId
          }
        ]);
      }),
      switchMap(savedDocument => {
        const references = clone(this.patternAgendaItems).map(
          item =>
            ({ parentId: savedDocument[0].data.id, documentId: item.documentId } as {
              parentId: string;
              documentId: string;
            })
        );
        return this.documentService.setReferences(references);
      })
    );
  }

  createDocumentFromPattern(agendaDocument: IDocument): Observable<IDocument> {
    return this.createTOPDocument(agendaDocument).pipe(
      switchMap(() => {
        const originalItems = agendaDocument.fields[siamConst.agendaField].value as INewAgendaServer[];
        const itemIds = originalItems.map(item => item.documentId);
        const uniqueIds = Array.from(new Set(itemIds));
        return this.documentService.getDocumentsByIds(uniqueIds);
      }),
      switchMap(documents => {
        const agendaItems: TreeViewItem[] = [];
        const originalItems = agendaDocument.fields[siamConst.agendaField].value as INewAgendaServer[];
        for (const item of originalItems) {
          const document = documents.find(d => d.id === item.documentId);
          if (document) {
            agendaItems.push({ id: document.id, document, parentId: item.parentDocumentId, type: item.type });
          }
        }
        return this.cloneAgendaItems(agendaDocument, agendaItems);
      }),
      switchMap(newDocument => {
        newDocument.fields[siamConst.agendaField] = { value: this.patternAgendaItems, type: 'documents' };
        return this.documentService.save(newDocument);
      }),
      switchMap(savedDocument => {
        const references = clone(this.patternAgendaItems).map(
          item =>
            ({ parentId: savedDocument.id, documentId: item.documentId } as { parentId: string; documentId: string })
        );
        return zip(this.documentService.setReferences(references), of(savedDocument));
      }),
      map(data => data[1])
    );
  }

  cloneAgendaItems(agendaDocument: IDocument, agendaItemsClient: TreeViewItem[]): Observable<IDocument> {
    let agendaItems: TreeViewItem[] = [];
    agendaItems = clone(agendaItemsClient).filter(t => t.type === 'top' || t.type === 'pause');
    const observables: Observable<IDocument>[] = [];
    this.mapAgendaIds = {};
    this.patternAgendaItems = [];
    for (const item of agendaItems) {
      const observable = this.createTOPDocument(agendaDocument).pipe(
        switchMap(newTOP => from(this.documentService.createFromParent(newTOP, item.document))),
        map(doc => {
          doc.fields[siamConst.cloneFieldId] = { value: item.id };
          return doc;
        })
      );
      observables.push(observable);
    }
    return processObservablesRecursively(observables).pipe(
      switchMap(response => {
        const documentsToSave = response as IDocument[];
        const multiSaveDocuments: IMultiSaveDocument[] = [];
        for (const item of agendaItems) {
          const docTOSave = documentsToSave.find(
            doc => (doc.fields[siamConst.cloneFieldId].value as string) === item.id
          );
          if (docTOSave) {
            delete docTOSave.fields[siamConst.cloneFieldId];
            multiSaveDocuments.push({
              $type: 'create',
              document: docTOSave,
              documentTemplateId: docTOSave.templateId || docTOSave.template?.id
            });
          }
        }
        return this.documentService.multiSave(multiSaveDocuments);
      }),
      map(multidocuments => {
        for (let index = 0; index < multidocuments.length; index++) {
          const element = multidocuments[index];
          const item = agendaItems[index];
          this.mapAgendaIds[item.id] = element.data.id;
          const parentIdNew = this.mapAgendaIds[item.parentId];
          this.addAgendaEntry(element.data.id, 'top', parentIdNew);
        }
      }),
      switchMap(() => this.documentService.createDocumentByTemplateId(agendaDocument.template)),
      switchMap(newDocumentTemplate => from(this.documentService.createFromParent(newDocumentTemplate, agendaDocument)))
    );
  }
  mapAgendaItemsToServer(currentDocument: IDocument, document: IDocument): void {
    const agendaFields = currentDocument.template.fields.filter(field => field.type === 'documents');
    if (agendaFields?.length) {
      for (const agendaField of agendaFields) {
        const value = document.fields[agendaField.name].value as TreeViewItem[];
        const plainArray = flattAgendaHierarchy(value);

        if (plainArray?.length) {
          document.fields[agendaField.name].value = plainArray.map(item => this.mapAgendaItemClientToServer(item));
        }
      }
    }
  }
  mapAgendItemValueClientToServer(items: TreeViewItem[]): INewAgendaServer[] {
    const plainArray = flattAgendaHierarchy(items);
    return plainArray?.map(item => this.mapAgendaItemClientToServer(item)) || [];
  }
  mapAgendaItemClientToServer(item: TreeViewItem): INewAgendaServer {
    const documentId = item.id || item.document?.id || item.documentId as string
    return {
      position: item.position,
      documentId,
      parentDocumentId: item.parentId,
      type: item.type,
      duration: item.duration,
      startTime: item.startTime,
      endTime: item.endTime,
      speakers:
        item.speakers?.map(speaker => {
          if (checkIfValidUUID(speaker)) {
            return { type: 'user', targetId: speaker } as IPermissionTarget;
          } else if (isCompositeId(speaker)) {
            const permissionTarget = createPermissionTargetWithoutCompositeId(speaker);
            return {
              targetId: permissionTarget.targetId,
              type: permissionTarget.type
            } as IPermissionTarget;
          } else {
            return speaker;
          }
        }) || [],
      isActive: isHasProperty(item, 'isActive') ? item.isActive : true
    } as INewAgendaServer;
  }

  checkMeetingMinutesItems(agenda: IDocument): Observable<{ document: IDocument; noChanges: boolean }> {
    const child = this.documentService.getChildDocumentWithTag(agenda, 'app:document-type:meetingMinutes');
    if (child) {
      const meetingMinutesId = (child.document && child.document.id) || child.documentId;
      return of(meetingMinutesId).pipe(
        switchMap(() => {
          return this.documentService.getPermissions(meetingMinutesId).pipe(
            map(permissions => {
              return permissions.includes('update');
            })
          );
        }),
        switchMap(hasUpdate => {
          if (hasUpdate) {
            return this.documentService.getDocumentById(meetingMinutesId);
          }
          return of(null);
        }),
        map(meetingMinutesDocument => {
          if (!meetingMinutesDocument) {
            return { document: null, noChanges: true };
          }
          const AgendaItems = agenda.fields[siamConst.agendaField].value as INewAgendaServer[];
          const meetingMinutesItems = meetingMinutesDocument.fields[siamConst.agendaField].value as INewAgendaServer[];
          return { document: meetingMinutesDocument, noChanges: this.compareItems(AgendaItems, meetingMinutesItems) };
        })
      );
    }
    return of({ document: null, noChanges: true });
  }

  private compareItems(array1: INewAgendaServer[], array2: INewAgendaServer[]): boolean {
    // Helper function to compare two objects
    const compareObjects = (obj1: INewAgendaServer, obj2: INewAgendaServer): boolean => {
      // Compare key properties: Add or remove properties to compare based on your needs
      return (
        obj1.documentId === obj2.documentId &&
        obj1.position === obj2.position &&
        obj1.type === obj2.type &&
        obj1.parentDocumentId === obj2.parentDocumentId &&
        obj1.isActive === obj2.isActive &&
        JSON.stringify(obj1.speakers) === JSON.stringify(obj2.speakers) &&
        (obj1.duration === obj2.duration || typeof obj1.duration === 'undefined') &&
        (obj1.startTime === obj2.startTime || typeof obj1.startTime === 'undefined') &&
        (obj1.endTime === obj2.endTime || typeof obj1.endTime === 'undefined')
      );
    };

    // If arrays don't have the same length, they are not equal
    if (array1.length !== array2.length) return false;

    // Sort arrays based on documentId to ensure order doesn't affect comparison
    const sortedArray1 = array1.sort((a, b) => a.documentId.localeCompare(b.documentId));
    const sortedArray2 = array2.sort((a, b) => a.documentId.localeCompare(b.documentId));

    // Compare each object in the array
    for (let i = 0; i < sortedArray1.length; i++) {
      if (!compareObjects(sortedArray1[i], sortedArray2[i])) {
        return false; // Return false if any object does not match
      }
    }

    return true; // All objects match
  }
  private createDocumentByTemplateId(template: ITemplateServer): Observable<IDocument> {
    return this.documentApi.createDocumentByTemplateId(template.id).pipe(
      map(document => {
        document.template = template;
        return document;
      })
    );
  }

  private getTOPTemplate(agendaDocument: IDocument): Observable<ITemplateServer> {
    if (this.topTemplate) {
      return of(clone(this.topTemplate));
    }
    return this.templateService.getActiveTemplates('create', ['app:document-type:top']).pipe(
      map(templates => {
        if (templates && !templates.length) {
          NotifyService.global.error(
            'Es wurde keine Vorlage für die Erstellung eines TOP-Dokuments gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
          );
        }
        let topTemplateAgenda: ITemplateServer = null;
        const agendaField = agendaDocument?.template?.fields?.find(field => field.type === 'documents');

        const agendaFieldSettings = agendaDocument?.template?.layouts[0]?.entries?.find(
          entry => entry.type === 'placeholder' && (entry as LayoutEntryPlaceholderServ).name === agendaField.name
        ) as LayoutEntryPlaceholderServ;
        const topId = agendaFieldSettings?.additionalData?.agendaSettings?.topSubTemplates?.templateTopId;
        if (topId) {
          topTemplateAgenda = templates.find(template => template?.id === topId);
        } else {
          topTemplateAgenda = templates.find(template =>
            template?.name?.toLowerCase().includes(agendaDocument?.template?.name?.toLocaleLowerCase())
          );
        }
        this.topTemplate = topTemplateAgenda || templates[0];
        return this.topTemplate;
      })
    );
  }
  private createTOPDocument(agendaDocument: IDocument): Observable<IDocument> {
    if (this.topTemplateDocument) {
      return of(clone(this.topTemplateDocument));
    }
    return this.getTOPTemplate(agendaDocument).pipe(
      switchMap(template => this.createDocumentByTemplateId(template)),
      tap(document => {
        this.topTemplateDocument = document;
      })
    );
  }
  private addAgendaEntry = (documentId: string, type: TAgendaItemType, parentId: string = null): void => {
    this.patternAgendaItems.push({ type, documentId, parentDocumentId: parentId });
  };

  private getTOPDocumentsToSave(items: TreeViewItem[], agendaDocument: IDocument): void {
    this.agendaTOPsToSave = [];
    this.referencesToSave = [];
    this.referencesToRemove = [];
    const checkChangesItems = items?.filter(item => item.document && item.type !== 'submission');
    checkChangesItems?.forEach(item => {
      const documentHasChanged = Object.keys(item.document.fields).map(key => {
        const field = item.document.fields[key];
        // in parsed document "parsedDocument" field might be does not exists, because it was filtered out
        // accordingly to the effective permissions

        if (!isHasProperty(item.originalDocument.fields, key)) {
          return 'hasChange';
        }

        let currentField = item.document.fields[key].value as unknown;
        let sourceField = item.originalDocument.fields[key].value as unknown;
        switch (field.valueType) {
          case 'datetime':
          case 'timeStamp':
            currentField = new Date(currentField as string).toISOString();
            sourceField = new Date(sourceField as string).toISOString();
            break;
          case 'attachments':
            return this.documentService.isAttachmentChanged(currentField as IAttachment[], sourceField as IAttachment[])
              ? 'hasChange'
              : 'noChange';
          default:
            break;
        }
        currentField = this.documentService.removeCompositeId(currentField as IPermissionTarget);
        sourceField = this.documentService.removeCompositeId(sourceField as IPermissionTarget);
        if (currentField === null && sourceField === null) {
          return 'noChange';
        }
        return JSON.stringify(currentField) === JSON.stringify(sourceField) ? 'noChange' : 'hasChange';
      });
      if (documentHasChanged.includes('hasChange')) {
        this.agendaTOPsToSave.push(item.document);
      }
    });
    items?.filter(item => item.document)?.forEach(item => {
      switch (item.type) {
        case 'top':
        case 'pause':
          if (!this.documentService.isDocumentReferenced(item.document, agendaDocument)) {
            this.referencesToSave.push({ documentId: item.document.id, parentId: agendaDocument.id });
          }
          break;
        case 'submission':
        case 'top-protocol':
          {
            const parentItem = items.find(parent => parent.id === item.parentId);
            if (
              parentItem?.document &&
              item?.document &&
              !this.documentService.isDocumentReferenced(item.document, parentItem.document)
            ) {
              this.referencesToSave.push({ documentId: item.document.id, parentId: parentItem.document.id });
            }
            if (parentItem) {
              const referencedTOPs = items.filter(
                itemElement =>
                  itemElement.id !== parentItem.id &&
                  this.documentService.isDocumentReferenced(item.document, itemElement.document)
              );
              for (const refToRemove of referencedTOPs) {
                this.referencesToRemove.push({ documentId: item.document.id, parentId: refToRemove.document.id });
              }
            }
          }
          break;
        case 'task':
          {
            const parentSubmissionItem = items.find(parent => parent.id === item.parentId);
            if (
              parentSubmissionItem &&
              !this.documentService.isDocumentReferenced(item.document, parentSubmissionItem.document)
            ) {
              this.referencesToSave.push({
                documentId: item.document.id,
                parentId: parentSubmissionItem.document.id
              });
            }
          }
          break;

        default:
          break;
      }
    });
  }

  private saveItems(documents: IDocument[]): Observable<IResponseMultiSaveDocument[]> {
    const multiDocuments = documents.map((doc, index) => {
      const document = copy(doc);
      document.templateId = document.template?.id;

      delete document.template;
      delete document.creation;
      delete document.effectivePermissions;
      return {
        $type: 'update',
        document,
        documentId: document.id,
        referenceId: index.toString()
      } as IMultiSaveDocument;
    });
    return this.documentService.multiSave(multiDocuments);
  }
}
