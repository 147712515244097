import { ITemplateServer } from '@interfaces/ITemplateServer';
import { TemplateClient } from '@interfaces/templateClient';
import { siamConst } from '@interfaces/siamConst';
import {
  ICategory,
  IConfidentialPermission,
  IConfidentialReference,
  IDefaultValueSourceStatic,
  IDefaultValueSourceToDay,
  IDynamicCard,
  IPermissionTarget,
  IRolePermission,
  IUserPermission,
  SiamListItem,
  TDefaultValueSource,
  TDefaultValuetypes,
  TPermission
} from '@interfaces/siam';
import {
  IFieldBaseServer,
  FieldCheckBoxGroupServ,
  IFieldDataSourceList,
  IFieldDataSourceStatic,
  FieldPermissionTargetServ,
  FieldRadioGroupServ,
  FieldSelectBoxServ,
  FieldServer,
  FieldTypeServer,
  LayoutEntryPlaceholderServ,
  LayoutFieldEntryServ,
  LayoutGroupEntryServ,
  LayoutServ,
  LayoutSpacerEntryServ,
  ValidationRequiredIfVisibleServ,
  ValidationRequiredServ,
  ValidationServer,
  ValidationType,
  TLayoutPlaceholderTypeServer,
  FieldDocumentsServ
} from '@interfaces/fieldServer';
import {
  AgendaField,
  CheckBox,
  CheckBoxGroup,
  Container,
  DateBox,
  defaultSignatureColumns,
  DxSlider,
  Empty,
  FieldBaseClient,
  FieldClient,
  fieldConstant,
  FieldTypeClient,
  FieldTypes,
  FileUploader,
  Group,
  LinkText,
  MeetingMinutesField,
  NumberBox,
  PermissionTarget,
  RadioGroup,
  RichTextEditor,
  SelectBox,
  SignatureField,
  StaticText,
  TextArea,
  TextBox,
  TLayoutPlaceholderType
} from '@interfaces/fieldClient';
import * as Factory from '@factories/document.factory';
import { RolePermission, UserPermission } from '@interfaces/permission';
import { getTagName, isHasProperty, isJsonString, isUndefinedValue } from './helpers';
import { GetTypePipe } from '@pipes/get-type.pipe';
import {
  IBaseEditorOptions,
  ICheckBoxGroupEditorOptions,
  IFileUploaderEditorOptions,
  IReachTextBoxEditorOptions,
  ISelectBoxEditorOptions,
  ISignatureEditorOptions,
  ISliderEditorOptions,
  ITextAreaEditorOptions,
  TRequired,
  TVisibility
} from '@interfaces/fields';

export const DOCUMENT_TAG_STICKY_HEADER = 'app:document-sticky-header:';
export const DOCUMENT_TAG_LABEL_LOCATION = 'app:document:type:label-location:';
export const DOCUMENT_TAG_CATEGORY_TITLE = 'app:document-category-titel:';

const DOCUMENT_TAG_LABEL = 'app:document-label:';
const DOCUMENT_TAG_ICON = 'app:document-icon:';

/**
 * mapServerToClientDoc.
 * mapServerToClientDoc is a simple reusable component which helps you
 * to copy data from object type to Template class object and returns Template
 *
 * @param templateServer : type DocumentTemplateServ
 */
export const mapServerToClientDoc = (templateServer: ITemplateServer): TemplateClient => {
  const tags = templateServer.tags || [];

  const stickyHeaderKey = tags
    .filter(tag => tag.startsWith(DOCUMENT_TAG_STICKY_HEADER))
    .map(tag => tag.split(DOCUMENT_TAG_STICKY_HEADER).pop())[0];
  const labelLocationKey = tags
    .filter(tag => tag.startsWith(DOCUMENT_TAG_LABEL_LOCATION))
    .map(tag => tag.split(DOCUMENT_TAG_LABEL_LOCATION).pop())[0];
  const categoryTitelKey = tags
    .filter(tag => tag.startsWith(DOCUMENT_TAG_CATEGORY_TITLE))
    .map(tag => tag.split(DOCUMENT_TAG_CATEGORY_TITLE).pop())[0];

  const label = tags.filter(tag => tag.startsWith(`${DOCUMENT_TAG_LABEL}${siamConst.globalLabelsListName}:`))[0];

  const tagType = GetTypePipe.prototype.transform(tags);

  return {
    id: templateServer.id,
    name: templateServer.name,
    caption: templateServer.caption,
    description: templateServer.description,
    fields: createFields(templateServer),
    permissions: templateServer.permissions,
    documentPermissions: templateServer.documentPermissions,
    fieldPermissions: templateServer.fieldPermissions,
    confidentialReferences: mapConfidentialServerToClient(templateServer.confidentialReferences),
    effectivePermissions: [],
    tagType: tagType ? `app:document-type:${tagType}` : null,
    tags,
    defaultLayoutId:
      Array.isArray(templateServer.layouts) && templateServer.layouts.length ? templateServer.layouts[0].id : null,
    icon: templateIcon(tags),
    stickyHeader: stickyHeaderKey?.toLowerCase() === 'true',
    labelLocation: labelLocationKey || 'left',
    categoryTitel: categoryTitelKey?.toLowerCase() === 'true',
    label,
    workflowId: templateServer.workflowId,
    isDisabled: templateServer.isDisabled,
    autoUpdate: templateServer.autoUpdate,
    creation: templateServer.creation,
    modification: templateServer.modification
  };
};

export const templateIcon = (tags: string[]): string =>
  tags.filter(tag => tag.startsWith(DOCUMENT_TAG_ICON)).map(tag => tag.split(DOCUMENT_TAG_ICON).pop())[0];

export const mapConfidentialServerToClient = (confidentials: IConfidentialReference[]): IConfidentialReference[] =>
  confidentials?.map(
    c =>
      ({
        confidentialId: c.confidentialId,
        name: c.name,
        allowHardening: c.allowHardening === true || c.allowHardening === undefined,
        allowWeakening: !(c.allowWeakening === false || c.allowWeakening === undefined),
        isDefault: c.isDefault,
        children: c.children.map(
          child =>
            ({
              confidentialId: child.confidentialId,
              allowHardening: child.allowHardening === true || child.allowHardening === undefined,
              allowWeakening: !(child.allowWeakening === false || child.allowWeakening === undefined)
            } as IConfidentialPermission)
        )
      } as IConfidentialReference)
  );

export const createField = (
  template: ITemplateServer,
  field: FieldServer,
  fieldLayout: LayoutFieldEntryServ
): FieldTypes => {
  switch (field.type) {
    case 'datetime':
      return new DateBox(properties({ field, template }));

    case 'textbox':
      return new TextBox(properties({ field, template }));

    case 'textarea': {
      const isFieldStaticText = fieldLayout?.additionalData?.staticText || false;
      if (isFieldStaticText) {
        return new StaticText(properties({ field, template }));
      }
      const isLinkText = fieldLayout?.additionalData?.isLinkText || false;
      if (isLinkText) {
        return new LinkText(properties({ field, template }));
      }
      return new TextArea(properties({ field, template }));
    }

    case 'checkbox':
      return new CheckBox(properties({ field, template }));

    case 'slider':
      return new DxSlider(properties({ field, template }));

    case 'checkboxgroup':
      return new CheckBoxGroup(
        properties({
          field,
          template,
          choices: (field as FieldCheckBoxGroupServ).choices,
          dataSource: (field as FieldCheckBoxGroupServ).choices,
          choicesDataSource: (field as FieldSelectBoxServ).choicesDataSource
        })
      );

    case 'radiogroup':
      return new RadioGroup(
        properties({
          field,
          template,
          dataSource: (field as FieldRadioGroupServ).choices,
          choicesDataSource: (field as FieldRadioGroupServ).choicesDataSource
        })
      );

    case 'dropdownlist':
      return new SelectBox(
        properties({
          field,
          template,
          dataSource: field.choices,
          choicesDataSource: field.choicesDataSource
        })
      );

    case 'attachments': {
      return new FileUploader(properties({ field, template }));
    }
    case 'signature': {
      return new SignatureField(properties({ field, template }));
    }

    case 'permission-targets':
      return new PermissionTarget(properties({ field, template }));

    case 'number':
      return new NumberBox(properties({ field, template }));

    case 'html': {
      const isFieldStaticText = fieldLayout?.additionalData?.staticText || false;
      if (isFieldStaticText) {
        return new StaticText(properties({ field, template }));
      }
      return new RichTextEditor(properties({ field, template }));
    }
    case 'documents': {
      const isMeetingMinutes =
        (fieldLayout as unknown as LayoutEntryPlaceholderServ)?.additionalData?.isMeetingMinutes || false;
      if (isMeetingMinutes) {
        return new MeetingMinutesField(properties({ field, template }));
      }
      return new AgendaField(properties({ field, template }));
    }
  }

  throw new Error(`Field <${field.name}>: unsupported field type <${field.type}>`);
};

const createFields = (template: ITemplateServer): FieldTypes[] => {
  const fields: FieldTypes[] = [];
  template.layouts.forEach(layout => {
    layout.entries.forEach(entry => {
      try {
        switch (entry.type) {
          case 'group':
            {
              const grpIndex = layout.entries.findIndex(
                x => (x as LayoutGroupEntryServ).name === (entry as LayoutGroupEntryServ).name
              );
              fields[grpIndex] = createGrpField(layout.entries[grpIndex] as LayoutGroupEntryServ);
            }
            break;

          case 'placeholder':
            {
              const grpIndex = layout.entries.findIndex(
                x => (x as LayoutGroupEntryServ).name === (entry as LayoutGroupEntryServ).name
              );
              if ((layout.entries[grpIndex] as LayoutEntryPlaceholderServ).placeholderType !== '--agendaitems') {
                fields[grpIndex] = createContainerField(layout.entries[grpIndex] as LayoutEntryPlaceholderServ);
              }
            }
            break;

          case 'spacer':
            {
              const grpIndex = layout.entries.findIndex(x => (x as LayoutGroupEntryServ).id === entry.id);
              fields[grpIndex] = createEmptyField(layout.entries[grpIndex] as LayoutSpacerEntryServ);
            }
            break;

          default:
            {
              template.fields.forEach(field => {
                const index = layout.entries.findIndex(
                  x =>
                    (x.type === 'field' && (x as LayoutFieldEntryServ).fieldName === field.name) ||
                    (x.type === 'placeholder' && (x as LayoutEntryPlaceholderServ).name === field.name)
                );
                if (
                  layout.entries[index]?.type === 'placeholder' &&
                  (layout.entries[index] as LayoutEntryPlaceholderServ)?.name === siamConst.agendaField
                ) {
                  fields[index] = createContainerField(layout.entries[index] as LayoutEntryPlaceholderServ);
                } else {
                  fields[index] = createField(template, field, layout.entries[index] as LayoutFieldEntryServ);
                }
              });
            }
            break;
        }
      } catch (e) {
        console.error(e);
      }
    });
  });
  return fields;
};

const createGrpField = (entry: LayoutGroupEntryServ): Group => {
  return new Group({
    id: entry.id,
    name: entry.name,
    parentContainerName: entry.parentContainerName,
    caption: entry.label,
    colCount: entry.additionalData?.colCount,
    colSpan: entry.additionalData?.colSpan,
    label: {
      text: entry.label,
      visible: entry.additionalData?.labelVisible
    },
    cssClass: entry.additionalData?.cssClass,
    editorOptions: {
      cssClassColor: entry.additionalData?.cssClass,
      isGroupClosed: entry.additionalData?.isGroupClosed,
      fieldVisible: entry ? entry.visible : true,
      visibility: entry.visibleFormula ? 'formular' : entry ? entry.visible : true,
      visibleFormula: entry ? entry.visibleFormula : null,
      width: entry && entry.width ? entry.width : fieldConstant.width,
      hint: entry.additionalData?.hint
    }
  });
};

export const createEmptyField = (entry: LayoutSpacerEntryServ): Empty => {
  return new Empty({
    id: entry.id,
    name: entry.name || `spacer-${entry.id}`,
    parentContainerName: entry.parentContainerName,
    caption: entry.label,
    colCount: entry.additionalData?.colCount,
    colSpan: entry.additionalData?.colSpan,
    label: {
      text: entry.label,
      visible: entry?.additionalData?.labelVisible
    },
    cssClass: entry.additionalData?.cssClass,
    editorOptions: {
      cssClassColor: entry.additionalData?.cssClass,
      fieldVisible: entry ? entry.visible : true,
      visibility: entry.visibleFormula ? 'formular' : entry ? entry.visible : true,
      visibleFormula: entry ? entry.visibleFormula : null,
      width: entry.width || fieldConstant.width
    }
  });
};

export const createContainerField = (entry: LayoutEntryPlaceholderServ): Container => {
  let type = entry.placeholderType;
  if ((entry.placeholderType as unknown) === 'child-documents') {
    type = 'reference-documents'
  }
  const visible: boolean = entry?.visibleFormula
  ? false
  : !isUndefinedValue(entry.visible) 
  ? entry.visible
  : true;
  const init = {
    id: entry.id,
    name: entry.name,
    placeholderType: type,
    parentContainerName: entry.parentContainerName,
    caption: entry.label,
    colCount: entry.additionalData?.colCount,
    colSpan: entry.additionalData?.colSpan,
    label: {
      text: entry.label,
      visible: entry.additionalData?.labelVisible
    },
    cssClass: entry.additionalData?.cssClass,
    childrenTags: entry.additionalData?.childrenTags,
    childrenAllowedTemplates: entry.additionalData?.childrenAllowedTemplates,
    dynamicListName: entry.additionalData?.dynamicListName || getTagName(entry?.additionalData.childrenDynamicListTag),
    agendaAllowedTemplates: entry.additionalData?.agendaAllowedTemplates,
    allowCreateChild: entry.additionalData?.allowCreateChild,
    allowAddReference: entry.additionalData?.allowAddReference,
    showParentDocuments: entry.additionalData?.showParentDocuments,
    taskType: entry.additionalData?.taskType,
    taskTemplateId: entry.additionalData?.taskTemplateId,
    editorOptions: {
      label: { text: entry.label, visible: entry?.additionalData?.labelVisible },
      cssClassColor: entry.additionalData?.cssClass,
      elementAttr: { id: entry.id },
      isGroupClosed: entry.additionalData?.isGroupClosed,
      fieldVisible: visible,
      visibility: entry.visibleFormula ? 'formular' : visible,
      visibleFormula: entry ? entry?.visibleFormula : null,
      width: entry.width || fieldConstant.width,
      signatureColumns: entry.additionalData?.signatureColumns || defaultSignatureColumns,
      hint: entry.additionalData?.hint,
      childrenTags: entry?.additionalData.childrenTags,
      childrenAllowedTemplates: entry?.additionalData.childrenAllowedTemplates,
      dynamicListName:
        entry?.additionalData.dynamicListName || getTagName(entry?.additionalData.childrenDynamicListTag),
      agendaAllowedTemplates: entry?.additionalData.agendaAllowedTemplates,
      allowCreateChild: entry?.additionalData.allowCreateChild,
      allowAddReference: entry?.additionalData.allowAddReference,
      showParentDocuments: entry?.additionalData.showParentDocuments,
      taskType: entry?.additionalData.taskType,
      taskTemplateId: entry?.additionalData.taskTemplateId,
      agendaSettings: entry.additionalData.agendaSettings,
      isMeetingMinutes: entry.additionalData.isMeetingMinutes
    }
  };
  switch (type) {
    case '--agendaitems': {
      const isMeetingMinutes = entry?.additionalData?.isMeetingMinutes || false;
      if (isMeetingMinutes) {
        return new MeetingMinutesField(init as MeetingMinutesField);
      } else {
        return new AgendaField(init as AgendaField);
      }
    }

    default:
      return new Container(type, init as Container);
  }
};

const properties = (init: {
  field: FieldServer;
  template: ITemplateServer;
  dataSource?: any;
  choicesDataSource?: IFieldDataSourceList | IFieldDataSourceStatic;
  choices?: SiamListItem[];
}) => {
  const fieldServer: FieldServer = init.field;
  const template: ITemplateServer = init.template;
  let dataSource: unknown = init.dataSource;
  const choicesDataSource: IFieldDataSourceStatic | IFieldDataSourceList = init.choicesDataSource;
  const choices: SiamListItem[] = init.choices;

  const defaultLayout = template.layouts[0];
  const fieldEntry = defaultLayout.entries.find(
    x => (x as LayoutFieldEntryServ).fieldName === fieldServer.name
  ) as LayoutFieldEntryServ;
  const isIncluded = !!fieldEntry;

  const requiredValidationIndex = fieldServer.validations
    ? fieldServer.validations.findIndex(x => x.type === ValidationType.required)
    : -1;
  const requiredValidationIfVisibleIndex = fieldServer.validations
    ? fieldServer.validations.findIndex(x => x.type === ValidationType.requiredIfVisible)
    : -1;
  const requiredIfVisible: TRequired =
    requiredValidationIfVisibleIndex !== -1 ? 'required-if-visible' : requiredValidationIndex !== -1;

  let visibility: TVisibility;
  if (!fieldEntry) {
    visibility = true;
  } else if (fieldEntry.visibleFormula) {
    visibility = 'formular';
  } else {
    visibility = fieldEntry.visible;
  }

  const data = {
    id: fieldServer.id,
    name: fieldServer.name,
    caption: fieldServer.label,
    colSpan: fieldEntry?.additionalData?.colSpan || 1,
    label: { text: fieldServer.label, visible: fieldEntry?.additionalData?.labelVisible },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    default: fieldServer.default,
    cssClass: fieldEntry?.additionalData?.cssClass,
    description: fieldEntry?.additionalData?.description,
    included: isIncluded,
    parentContainerName: fieldEntry?.parentContainerName,
    minValue: null as number,
    maxValue: null as number,
    editorOptions: {
      disableInheritance: fieldServer.disableInheritance,
      allowPermissionRead: collectFieldPermissionsReadToClient(
        fieldServer.documentFieldPermissions,
        fieldServer.disableInheritance
      ),
      allowPermissionUpdate: collectFieldPermissionsUpdateToClient(
        fieldServer.documentFieldPermissions,
        fieldServer.disableInheritance
      ),
      defaultSource: fieldServer.defaultSource,
      defaultTime: null as Date,
      placeholder: fieldEntry?.additionalData?.placeholder || '',
      hint: fieldEntry?.additionalData?.hint || '',
      showEditModeButton: fieldEntry ? fieldEntry.additionalData?.showEditModeButton : (undefined as boolean),
      thousandsSeparator: fieldEntry ? fieldEntry.additionalData?.thousandsSeparator : (undefined as boolean),
      htmlEditorToolbarItemsOptions: fieldEntry ? fieldEntry.additionalData?.htmlEditorToolbarItemsOptions : undefined,
      htmlEditorSettings: fieldEntry ? fieldEntry.additionalData?.htmlEditorSettings : undefined,
      customValues: undefined as unknown,
      required: requiredValidationIndex !== -1,
      requiredOption: requiredIfVisible,
      visible: fieldEntry ? fieldEntry.visible : false,
      visibility,
      visibleFormula: fieldEntry ? fieldEntry?.visibleFormula : null,
      staticText: fieldEntry ? fieldEntry.additionalData?.staticText : null,
      linkText: fieldEntry ? fieldEntry.additionalData?.isLinkText : (undefined as boolean),
      linkName: fieldEntry ? fieldEntry.additionalData?.linkName : (undefined as string),
      linkContent: fieldEntry ? fieldEntry.additionalData?.linkContent : (undefined as string),
      customValuesSignature: fieldEntry ? fieldEntry.additionalData?.customValuesSignature : null,
      fileuploaderButtonTitel: fieldEntry ? fieldEntry.additionalData?.fileuploaderButtonTitel : (undefined as string),
      fileuploaderMaxFileSizeMBit: fieldEntry
        ? fieldEntry.additionalData?.fileuploaderMaxFileSize / 1024 / 1024
        : (undefined as number),
      width: fieldEntry && fieldEntry.width ? fieldEntry.width : fieldConstant.width,
      height: fieldEntry && fieldEntry.height ? fieldEntry.height : (undefined as string),
      minHeight:
        fieldEntry && fieldEntry.additionalData?.minHeight
          ? fieldEntry.additionalData?.minHeight
          : (undefined as string),
      maxHeight:
        fieldEntry && fieldEntry.additionalData?.maxHeight
          ? fieldEntry.additionalData?.maxHeight
          : (undefined as string),
      autoResizeEnabled:
        fieldEntry && fieldEntry.additionalData?.autoResizeEnabled
          ? fieldEntry.additionalData?.autoResizeEnabled
          : (undefined as boolean),
      layout: (fieldEntry && fieldEntry.orientation) || 'vertical',
      withTime: undefined as boolean,
      type: undefined as string,
      allowedFileMimeTypes: [] as string[],
      forbidRoles: undefined as boolean,
      forbidUsers: undefined as boolean,
      allowMultiple: undefined as boolean,
      include: undefined as Array<unknown>,
      exclude: undefined as Array<unknown>,
      resolve: undefined as Array<unknown>,
      selection: undefined as string,
      decimalPlaces: undefined as number,
      underlyingType: undefined as string,
      threeState: undefined as boolean,
      dataSource: undefined as unknown,
      choicesDataSource: undefined as IFieldDataSourceStatic | IFieldDataSourceList,
      choices: undefined as SiamListItem[],
      checkboxGroupColumns: fieldEntry ? fieldEntry.additionalData?.checkboxGroupColumns : 0,
      label: fieldServer.label,
      isTreeList: fieldEntry ? fieldEntry.additionalData?.isTreeList : null,
      isTreeListParent: fieldEntry ? fieldEntry.additionalData?.isTreeListParent : null,
      isTreeListChild: fieldEntry ? fieldEntry.additionalData?.isTreeListChild : null,
      treeListParentFieldName: fieldEntry ? fieldEntry.additionalData?.treeListParentFieldName : null,
      agendaSettings: (fieldEntry as unknown as LayoutEntryPlaceholderServ)?.additionalData?.agendaSettings,
      isMeetingMinutes: (fieldEntry as unknown as LayoutEntryPlaceholderServ)?.additionalData?.isMeetingMinutes
    },
    requiredValidationId: requiredValidationIndex === -1 ? null : fieldServer.validations[requiredValidationIndex].id,
    layoutEntryId: fieldEntry && fieldEntry.id
  };

  switch (fieldServer.type) {
    case 'checkboxgroup':
      {
        data.editorOptions.choices = choices;
        data.editorOptions.choicesDataSource = choicesDataSource;
      }
      break;

    case 'textarea':
      data.editorOptions.height = fieldEntry && fieldEntry.height ? fieldEntry.height : fieldConstant.height;
      break;

    case 'datetime':
      {
        data.editorOptions.withTime = fieldServer.withTime;
        data.editorOptions.type = fieldServer.withTime ? 'datetime' : 'date';
        const hour = (fieldServer.defaultSource as IDefaultValueSourceToDay)?.hour;
        const minute = (fieldServer.defaultSource as IDefaultValueSourceToDay)?.minute;
        const newDate = new Date();
        if (hour !== null && hour !== undefined) {
          newDate.setHours(hour);
        }
        if (minute !== null && minute !== undefined) {
          newDate.setMinutes(minute);
        }
        if (hour || minute) {
          data.editorOptions.defaultTime = newDate;
        }
      }
      break;

    case 'attachments':
      data.editorOptions.allowedFileMimeTypes = fieldServer.supportedContentTypes || ['*/*'];
      break;

    case 'permission-targets':
      {
        data.editorOptions.forbidRoles = fieldServer.forbidRoles;
        data.editorOptions.forbidUsers = fieldServer.forbidUsers;
        data.editorOptions.allowMultiple = fieldServer.allowMultiple;
        data.editorOptions.selection = (fieldServer as FieldPermissionTargetServ).selection;
        data.editorOptions.dataSource = (fieldServer as FieldPermissionTargetServ).resolve;
        data.editorOptions.include = (fieldServer as FieldPermissionTargetServ).include?.map(
          (val: unknown) => `${(val as IPermissionTarget).type}:${(val as IPermissionTarget).targetId}`
        );
        data.editorOptions.exclude = (fieldServer as FieldPermissionTargetServ).exclude?.map(
          (val: unknown) => `${(val as IPermissionTarget).type}:${(val as IPermissionTarget).targetId}`
        );
        data.editorOptions.resolve = (fieldServer as FieldPermissionTargetServ).resolve;
      }
      break;

    case 'number':
      {
        data.editorOptions.decimalPlaces = fieldServer.decimalPlaces;
        data.editorOptions.underlyingType = fieldServer.underlyingType;
      }
      break;

    case 'checkbox':
      data.editorOptions.threeState = fieldServer.threeState;
      break;

    case 'radiogroup':
    case 'dropdownlist':
      {
        // für Optionsfelder und Auswahlfelder muss das value von Datasource ein compositeId
        if (Array.isArray(dataSource)) {
          dataSource = dataSource.map((item: SiamListItem) => {
            if (isHasProperty(item.value, 'targetId') && isHasProperty(item.value, 'type')) {
              const target = Factory.permissionTarget(item.value as IPermissionTarget);
              item.value = target.compositeId;
              return item;
            } else if (
              Array.isArray(item.value) &&
              item.value.length === 1 &&
              isHasProperty(item.value[0], 'targetId') &&
              isHasProperty(item.value[0], 'type')
            ) {
              const target = item.value.map(p => Factory.permissionTarget(p as IPermissionTarget)?.compositeId);
              item.value = target[0];
              return item;
            }  else if (
              Array.isArray(item.value) &&
              item.value.length > 0 &&
              isHasProperty(item.value[0], 'targetId') &&
              isHasProperty(item.value[0], 'type')
            ) {
              const target = item.value.map(p => Factory.permissionTarget(p as IPermissionTarget)?.compositeId);
              item.value = JSON.stringify(target);
              return item;
            } else {
              return item;
            }
          });
        }
        data.editorOptions.choicesDataSource = choicesDataSource;
      }
      break;

    case 'slider':
      {
        data.editorOptions.underlyingType = fieldServer.underlyingType;
        data.editorOptions.customValues = fieldServer.customValues;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.minValue = (fieldServer.customValues && (fieldServer.customValues.minValue as number)) || null;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        data.maxValue = (fieldServer.customValues && (fieldServer.customValues.max as number)) || null;
      }
      break;
  }

  if (fieldServer.defaultSource?.type === 'static') {
    const defaultValue = (
      fieldServer.defaultSource as IDefaultValueSourceStatic<IPermissionTarget[] | IPermissionTarget>
    ).value;
    switch (fieldServer.type) {
      case 'radiogroup':
      case 'dropdownlist':
        {
          if (fieldServer.choicesDataSource.type === 'list') {
            if (Array.isArray(defaultValue) && defaultValue.length) {
              const clientValue = defaultValue.map(item => {
                if (isHasProperty(item, 'targetId') && isHasProperty(item, 'type')) {
                  const target = Factory.permissionTarget(item);
                  return target.compositeId;
                } else {
                  return item;
                }
              });
              data.editorOptions.defaultSource = { type: 'static', value: JSON.stringify(clientValue.sort()) };
              data.default = JSON.stringify(clientValue.sort());
            } else if (
              typeof defaultValue === 'object' &&
              isHasProperty(defaultValue, 'targetId') &&
              isHasProperty(defaultValue, 'type')
            ) {
              const target = Factory.permissionTarget(defaultValue as IPermissionTarget);
              data.editorOptions.defaultSource = { type: 'static', value: target.compositeId };
              data.default = target.compositeId;
            } else {
              data.editorOptions.defaultSource = { type: 'static', value: defaultValue };
              data.default = defaultValue;
            }
          } else {
            data.editorOptions.defaultSource = { type: 'static', value: defaultValue };
            data.default = defaultValue;
          }
        }
        break;

      case 'permission-targets':
        {
          if (Array.isArray(defaultValue) && defaultValue.length) {
            const clientValue = defaultValue.map(item => {
              if (isHasProperty(item, 'targetId') && isHasProperty(item, 'type')) {
                const target = Factory.permissionTarget(item);
                return target.compositeId;
              } else {
                return item;
              }
            });
            data.editorOptions.defaultSource = { type: 'static', value: clientValue };
            data.default = clientValue;
          } else if (
            typeof defaultValue === 'object' &&
            isHasProperty(defaultValue, 'targetId') &&
            isHasProperty(defaultValue, 'type')
          ) {
            const target = Factory.permissionTarget(defaultValue as IPermissionTarget);
            data.editorOptions.defaultSource = { type: 'static', value: target.compositeId };
            data.default = target.compositeId;
          } else {
            data.editorOptions.defaultSource = { type: 'static', value: defaultValue };
            data.default = defaultValue;
          }
        }
        break;

      default:
        data.editorOptions.defaultSource = { type: 'static', value: defaultValue };
        data.default = defaultValue;
        break;
    }
  }
  if (dataSource !== undefined) {
    data.editorOptions.dataSource = dataSource;
  }
  return data as IBaseEditorOptions;
};

/**
 * mapClientToServerDoc
 * mapClientToServerDoc is a simple reusable component which helps you
 * to copy data from object type to Template class object and returns DocumentTemplateServ
 *
 * @param templateClient
 */

export const mapClientToServerDoc = (templateClient: TemplateClient): ITemplateServer => {
  if (templateClient.tags.length) {
    templateClient.tags = templateClient.tags
      // clear type tag from tags
      .filter(tag => !tag.startsWith('app:document-type:'))
      // clear icon tag from tags
      .filter(tag => !tag.startsWith(DOCUMENT_TAG_ICON))
      // clear sticky-header tag from tags
      .filter(tag => !tag.startsWith(DOCUMENT_TAG_STICKY_HEADER))
      // clear label-location tag from tags
      .filter(tag => !tag.startsWith(DOCUMENT_TAG_LABEL_LOCATION))
      // clear category titel tag from tags
      .filter(tag => !tag.startsWith(DOCUMENT_TAG_CATEGORY_TITLE))
      // clear label tag from tags
      .filter(
        tag => !tag.toLowerCase().startsWith(`${DOCUMENT_TAG_LABEL}${siamConst.globalLabelsListName}:`.toLowerCase())
      );
  }

  // add type tag from tags
  if (templateClient.tagType) {
    templateClient.tags.push(templateClient.tagType);
  }

  // add icon with prefix to tags
  if (templateClient.icon) {
    templateClient.tags.push(DOCUMENT_TAG_ICON + templateClient.icon);
  }

  // add sticky header with prefix to tags
  if (templateClient.stickyHeader) {
    templateClient.tags.push(DOCUMENT_TAG_STICKY_HEADER + templateClient.stickyHeader.toString());
  }

  // add label position top with prefix to tags
  if (templateClient.labelLocation) {
    templateClient.tags.push(DOCUMENT_TAG_LABEL_LOCATION + templateClient.labelLocation);
  }

  // add category titel with prefix to tags
  if (templateClient.categoryTitel) {
    templateClient.tags.push(DOCUMENT_TAG_CATEGORY_TITLE + templateClient.categoryTitel.toString());
  }

  // add label with prefix to label
  if (templateClient.label) {
    templateClient.tags.push(templateClient.label);
  }

  const templateServer: ITemplateServer = {
    name: templateClient.name,
    caption: templateClient.caption,
    description: templateClient.description,
    fields: createFieldsServ(templateClient),
    layouts: createLayoutsServ(templateClient),
    permissions: collectPermissions(templateClient.permissions),
    documentPermissions: collectPermissions(templateClient.documentPermissions),
    fieldPermissions: templateClient.fieldPermissions,
    confidentialReferences: templateClient.confidentialReferences,
    tags: templateClient.tags,
    workflowId: templateClient.workflowId,
    isDisabled: templateClient.isDisabled,
    autoUpdate: templateClient.autoUpdate
  };

  if (templateClient.id) {
    templateServer.id = templateClient.id;
  }

  return templateServer;
};

const mapFieldToServer = (value: FieldTypeClient): FieldTypeServer => {
  switch (value) {
    case 'dxCheckBox':
      return 'checkbox';
    case 'dxCheckboxGroup':
      return 'checkboxgroup';
    case 'dxDateBox':
      return 'datetime';
    case 'dxRadioGroup':
      return 'radiogroup';
    case 'dxSelectBox':
      return 'dropdownlist';
    case 'dxTextArea':
      return 'textarea';
    case 'staticText':
      return 'html';
    case 'linkText':
      return 'textarea';
    case 'dxTextBox':
      return 'textbox';
    case 'dxFileUploader':
      return 'attachments';
    case 'signature':
      return 'signature';
    case 'dxNumberBox':
      return 'number';
    case 'dxTagBox':
      return 'permission-targets';
    case 'group':
      return 'group';
    case 'placeholder':
      return 'placeholder';
    case 'dxSlider':
      return 'slider';
    case 'empty':
      return 'field';
    case 'dxHtmlEditor':
      return 'html';
  }
};
const mapContainerToServer = (value: TLayoutPlaceholderType): TLayoutPlaceholderTypeServer => {
  switch (value) {
    case 'agenda':
    case 'meeting-minutes':
      return '--agendaitems';
    default:
      return value;
  }
};

const getDataSource = (field: SelectBox): IFieldDataSourceStatic | IFieldDataSourceList => {
  const options = field.editorOptions;
  const choicesDataSource = options.choicesDataSource;
  if (choicesDataSource) {
    switch (choicesDataSource.type) {
      case 'static':
        {
          // prüfen, ob json als String vorliegt statt als array (z.B. von dxTextBox)
          if (typeof choicesDataSource.json === 'string') {
            return {
              type: 'static',
              json: JSON.parse(choicesDataSource.json) as SiamListItem[]
            } as IFieldDataSourceStatic;
          }
        }
        break;

      case 'list':
        {
          // prüfen, ob json als String vorliegt statt als array (z.B. von dxTextBox)
          if (typeof choicesDataSource.name === 'string') {
            return {
              type: 'list',
              name: choicesDataSource.name
            } as IFieldDataSourceList;
          }
        }
        break;
    }
    return choicesDataSource;
  }

  if (!options.dataSource) {
    return undefined;
  }

  if (typeof options.dataSource === 'string') {
    return {
      type: 'static',
      json: JSON.parse(options.dataSource) as SiamListItem[]
    } as IFieldDataSourceStatic;
  }

  return {
    type: 'static',
    json: options.dataSource as SiamListItem[]
  } as IFieldDataSourceStatic;
};

const settingsServ = (init: { field: FieldClient }): IFieldBaseServer => {
  const fieldClient: FieldClient = init.field;
  const dataSource = getDataSource(fieldClient as SelectBox);
  const validations = createValidations(fieldClient as FieldBaseClient);
  const editorOptions = fieldClient.editorOptions as IBaseEditorOptions;

  let choices: unknown;
  if (dataSource) {
    switch (dataSource.type) {
      case 'static':
        choices = dataSource.json;
        break;
      case 'list':
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        choices = (editorOptions as ISelectBoxEditorOptions).dataSource || editorOptions.choices;
        break;
      default:
    }
  }

  const data = {
    name: fieldClient.name,
    type: mapFieldToServer(fieldClient.type),
    label: fieldClient.caption,
    defaultSource: editorOptions.defaultSource?.type === 'none' ? null : editorOptions.defaultSource,
    colCount: fieldClient.colSpan,
    validations,
    choices,
    choicesDataSource: dataSource,
    disableInheritance: !!collectFieldPermissionsToServer(
      editorOptions.allowPermissionRead,
      editorOptions.allowPermissionUpdate
    )?.length,
    documentFieldPermissions: collectFieldPermissionsToServer(
      editorOptions.allowPermissionRead,
      editorOptions.allowPermissionUpdate
    ),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dataSource: (editorOptions as ISelectBoxEditorOptions).dataSource,
    withTime: editorOptions.withTime,
    allowMultiple: editorOptions.allowMultiple,
    forbidRoles: editorOptions.forbidRoles,
    forbidUsers: editorOptions.forbidUsers,
    targetField: editorOptions.targetField,
    include: editorOptions.include?.map((val: string) => Factory.createPermissionTarget(val)),
    exclude: editorOptions.exclude?.map((val: string) => Factory.createPermissionTarget(val)),
    selection: editorOptions.selection,
    decimalPlaces: editorOptions.decimalPlaces,
    underlyingType: editorOptions.underlyingType,
    threeState: editorOptions.threeState,
    customValues: (editorOptions as ISliderEditorOptions).customValues,
    hint: editorOptions.hint
  } as IFieldBaseServer;

  if (fieldClient.type === 'dxFileUploader') {
    if (
      (editorOptions as IFileUploaderEditorOptions).allowedFileExtensions &&
      (editorOptions as IFileUploaderEditorOptions).allowedFileExtensions.length
    ) {
      const extensions = (editorOptions as IFileUploaderEditorOptions).allowedFileExtensions;
      if (extensions.includes('*')) {
        data.supportedContentTypes = ['*/*'];
      } else {
        const types = new Set<string>();
        extensions.forEach(extension => {
          switch (extension) {
            case '.pdf':
              types.add('application/pdf');
              break;

            case '.doc':
            case '.docx':
              types.add('application/msword');
              types.add('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
              break;

            case '.ppt':
            case '.pptx':
              types.add('application/vnd.ms-powerpoint');
              types.add('application/vnd.openxmlformats-officedocument.presentationml.presentation');
              break;

            case '.xls':
            case '.xlsx':
              types.add('application/vnd.ms-excel');
              types.add('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
              break;

            case '.png':
              types.add('.png');
              break;

            case '.jpg':
              types.add('.jpg');
              break;

            case '.jpeg':
              types.add('.jpeg');
              break;
          }
        });
        data.supportedContentTypes = Array.from(types);
      }
    } else {
      // it can't be empty and by default allow all file types for upload
      data.supportedContentTypes = ['*/*'];
    }
  }

  if (fieldClient.type === 'dxDateBox' && editorOptions.defaultSource?.type === 'today') {
    const hour = editorOptions.defaultTime?.getHours();
    const minute = editorOptions.defaultTime?.getMinutes();
    (data.defaultSource as IDefaultValueSourceToDay).hour = hour !== undefined && hour !== null ? hour : null;
    (data.defaultSource as IDefaultValueSourceToDay).minute = minute !== undefined && minute !== null ? minute : null;
  }
  if (fieldClient.type === 'dxSlider') {
    data.minValue = (editorOptions as ISliderEditorOptions)?.customValues?.min;
    data.maxValue = (editorOptions as ISliderEditorOptions)?.customValues?.max;
  }
  if (!dataSource) {
    delete data.choices;
    delete data.choicesDataSource;
  }
  if (fieldClient.editorOptions?.defaultSource?.type === 'static' && fieldClient.type !== 'dxCheckboxGroup') {
    const defaultValueSource = (fieldClient.editorOptions.defaultSource as IDefaultValueSourceStatic<unknown>).value;
    const defaultValue = isJsonString(defaultValueSource as string)
      ? (JSON.parse(defaultValueSource as string) as unknown)
      : defaultValueSource;
    if (Array.isArray(defaultValue as string[]) && (defaultValue as string[]).length) {
      const serverValue = (defaultValue as string[]).map(item => {
        if (Factory.isCompositeId(item)) {
          return Factory.createPermissionTarget(item);
        } else if (
          Array.isArray(item) &&
          item.length === 1 &&
          typeof item[0] === 'string' &&
          Factory.isCompositeId(item[0])
        ) {
          return Factory.createPermissionTarget(item[0]);
        } else if (
          Array.isArray(item) &&
          item.length > 1 &&
          typeof item[0] === 'string' &&
          Factory.isCompositeId(item[0])
        ) {
          return (item as string[]).map(v => Factory.createPermissionTarget(v));
        } else {
          return item;
        }
      });
      data.defaultSource = { type: 'static', value: serverValue };
    } else if (Factory.isCompositeId(defaultValue as string)) {
      data.defaultSource = { type: 'static', value: Factory.createPermissionTarget(defaultValue as string) };
    } else {
      data.defaultSource = { type: 'static', value: defaultValue };
    }
  }

  return data;
};

const createDocumentFieldServer = (init: { field: AgendaField }): IFieldBaseServer => {
  const fieldClient: AgendaField = init.field;
  const editorOptions = fieldClient.editorOptions as IBaseEditorOptions;
  const validations = createValidations(fieldClient as FieldBaseClient);

  return {
    name: siamConst.agendaField,
    type: 'documents',
    label: fieldClient.caption,
    allowNesting: true,
    defaultSource: editorOptions.defaultSource?.type === 'none' ? null : editorOptions.defaultSource,
    colCount: fieldClient.colSpan,
    validations,
    disableInheritance: !!collectFieldPermissionsToServer(
      editorOptions.allowPermissionRead,
      editorOptions.allowPermissionUpdate
    )?.length,
    documentFieldPermissions: collectFieldPermissionsToServer(
      editorOptions.allowPermissionRead,
      editorOptions.allowPermissionUpdate
    ),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dataSource: (editorOptions as ISelectBoxEditorOptions).dataSource,
    include: editorOptions.include?.map((val: string) => Factory.createPermissionTarget(val)),
    exclude: editorOptions.exclude?.map((val: string) => Factory.createPermissionTarget(val)),
    hint: editorOptions.hint
  } as FieldDocumentsServ;
};

const createFieldsServ = (template: TemplateClient): FieldServer[] => {
  const result: FieldServer[] = [];
  template.fields.forEach(field => {
    if (field) {
      switch (field.kind as FieldTypeClient) {
        case 'dxTagBox':
        case 'dxFileUploader':
        case 'signature':
        case 'dxDateBox':
        case 'dxCheckBox':
        case 'dxCheckboxGroup':
        case 'dxTextBox':
        case 'dxHtmlEditor':
        case 'dxTextArea':
        case 'staticText':
        case 'linkText':
        case 'dxRadioGroup':
        case 'dxSelectBox':
        case 'dxSlider':
        case 'dxNumberBox':
          {
            const item = settingsServ({ field: field as FieldClient });
            if (field.id) {
              item.id = field.id;
            }
            result.push(item);
          }
          break;

        case 'placeholder': {
          if (field.name === siamConst.agendaField) {
            const item = createDocumentFieldServer({ field: field as AgendaField });
            if (field.id) {
              item.id = field.id;
            }
            result.push(item);
          }
        }
      }
    }
  });
  return result;
};

const createValidations = (field: FieldBaseClient): ValidationServer[] => {
  const result: ValidationServer[] = [];

  if (field.editorOptions.requiredOption === true) {
    const validation = new ValidationRequiredServ();
    if (field.requiredValidationId) {
      validation.id = field.requiredValidationId;
    }
    result.push(validation);
  }
  if (field.editorOptions.requiredOption === 'required-if-visible') {
    const validation = new ValidationRequiredIfVisibleServ();
    if (field.requiredValidationId) {
      validation.id = field.requiredValidationId;
    }
    result.push(validation);
  }
  return result;
};

const createLayoutsServ = (template: TemplateClient): LayoutServ[] => {
  const result: LayoutServ[] = [];

  const defaultLayout = new LayoutServ();
  if (template.defaultLayoutId) {
    defaultLayout.id = template.defaultLayoutId;
  }

  defaultLayout.name = 'default';
  defaultLayout.entries = [];

  template.fields.forEach(fieldOrGroup => {
    if (fieldOrGroup !== undefined) {
      if (fieldOrGroup.kind === 'group') {
        const group = fieldOrGroup as Group;
        const entryGrp = new LayoutGroupEntryServ({
          id: group.layoutEntryId || group.id,
          name: group.name,
          label: group.caption,
          width: group.editorOptions.width,
          visible: group.editorOptions.visibility === 'formular' ? null : group.editorOptions.fieldVisible,
          visibleFormula: group.editorOptions.visibility === 'formular' ? group.editorOptions.visibleFormula : null,
          parentContainerName: group.parentContainerName,
          additionalData: {
            colCount: group.colCount,
            colSpan: group.colSpan,
            cssClass: group?.editorOptions?.cssClassColor,
            labelVisible: group.label.visible,
            isGroupClosed: group.editorOptions.isGroupClosed,
            hint: group.editorOptions.hint
          }
        });
        defaultLayout.entries.push(entryGrp);
      } else if (fieldOrGroup.kind === 'placeholder') {
        const container = fieldOrGroup as Container;
        const entryGrp = new LayoutEntryPlaceholderServ({
          id: container.layoutEntryId || container.id,
          placeholderType: mapContainerToServer(container.placeholderType),
          name: container.name,
          label: container.caption,
          width: container.editorOptions.width,
          visible: container?.editorOptions?.visibility === 'formular' ? null : container.editorOptions.fieldVisible,
          visibleFormula:
            container.editorOptions?.visibility === 'formular' ? container.editorOptions?.visibleFormula : null,
          parentContainerName: container.parentContainerName,
          additionalData: {
            colCount: container.colCount,
            colSpan: container.colSpan,
            cssClass: container?.editorOptions?.cssClassColor,
            labelVisible: container.label.visible,
            isGroupClosed: container.editorOptions.isGroupClosed,
            childrenAllowedTemplates: container.childrenAllowedTemplates,
            dynamicListName: container.dynamicListName,
            agendaAllowedTemplates: container.agendaAllowedTemplates,
            allowCreateChild: container.allowCreateChild,
            allowAddReference: container.allowAddReference,
            showParentDocuments: container.showParentDocuments,
            taskType: container.taskType,
            taskTemplateId: container.taskTemplateId,
            signatureColumns: container.editorOptions.signatureColumns,
            hint: container.editorOptions.hint,
            agendaSettings: container.editorOptions.agendaSettings,
            isMeetingMinutes: container.editorOptions.isMeetingMinutes
          }
        });
        defaultLayout.entries.push(entryGrp);
      } else if (fieldOrGroup.kind === 'empty') {
        const container = fieldOrGroup as Empty;
        const entryGrp = new LayoutSpacerEntryServ({
          id: container.layoutEntryId || container.id,
          name: container.name,
          label: container.caption,
          width: container.editorOptions.width,
          visible: container?.editorOptions?.visibility === 'formular' ? null : container.editorOptions.fieldVisible,
          visibleFormula:
            container.editorOptions?.visibility === 'formular' ? container.editorOptions?.visibleFormula : null,
          parentContainerName: container.parentContainerName,
          additionalData: {
            colSpan: container.colSpan,
            cssClass: container?.editorOptions?.cssClassColor,
            labelVisible: container.label.visible,
            isGroupClosed: container.editorOptions.isGroupClosed
          }
        });
        defaultLayout.entries.push(entryGrp);
      } else {
        const field = fieldOrGroup as FieldClient;

        let visible: boolean;
        if (field.editorOptions.visibility === 'formular') {
          visible = false;
        } else {
          visible = !!field.editorOptions.visibility;
        }

        const entry = new LayoutFieldEntryServ(mapFieldToServer(field.kind), {
          id: field.layoutEntryId,
          fieldName: field.name,
          width: String(field.editorOptions.width),
          height: String(field.editorOptions.height),
          visible,
          visibleFormula: field.editorOptions.visibility === 'formular' ? field.editorOptions.visibleFormula : null,
          parentContainerName: field.parentContainerName,
          orientation: (field.editorOptions as ICheckBoxGroupEditorOptions).layout || 'vertical',
          additionalData: {
            colSpan: field.colSpan,
            cssClass: field.cssClass,
            labelVisible: field.label.visible,
            placeholder: field.editorOptions.placeholder,
            hint: field.editorOptions.hint,
            description: field.description,
            disabled: field.editorOptions.disabled,
            readonly: field.editorOptions.readOnly,
            staticText: field.editorOptions.isStaticText,
            isLinkText: field.editorOptions.isLinkText,
            linkContent: field.editorOptions.linkContent,
            linkName: field.editorOptions.linkName,
            customValuesSignature: (field.editorOptions as ISignatureEditorOptions).customValuesSignature,
            fileuploaderButtonTitel: (field.editorOptions as IFileUploaderEditorOptions).fileuploaderButtonTitel,
            fileuploaderMaxFileSize:
              (field.editorOptions as IFileUploaderEditorOptions).fileuploaderMaxFileSizeMBit * 1024 * 1024,
            checkboxGroupColumns: (field.editorOptions as IFileUploaderEditorOptions).checkboxGroupColumns,
            showEditModeButton: (field.editorOptions as IReachTextBoxEditorOptions).showEditModeButton,
            htmlEditorToolbarItemsOptions: (field.editorOptions as IReachTextBoxEditorOptions)
              .htmlEditorToolbarItemsOptions,
            htmlEditorSettings: (field.editorOptions as IReachTextBoxEditorOptions).htmlEditorSettings,
            thousandsSeparator: field.editorOptions.thousandsSeparator,
            autoResizeEnabled: field.editorOptions.autoResizeEnabled,
            minHeight: String((field.editorOptions as ITextAreaEditorOptions).minHeight),
            maxHeight: String((field.editorOptions as ITextAreaEditorOptions).maxHeight),
            isTreeList: field.editorOptions.isTreeList,
            isTreeListParent: field.editorOptions.isTreeListParent,
            isTreeListChild: field.editorOptions.isTreeListChild,
            treeListParentFieldName: field.editorOptions.treeListParentFieldName
          }
        });

        defaultLayout.entries.push(entry);
      }
    }
  });
  result.push(defaultLayout);
  return result;
};

const collectPermissions = (source: TPermission[]): TPermission[] => {
  const permissions: TPermission[] = [];
  if (source) {
    source.map(permission => {
      if (permission.allows.length || permission.denies.length) {
        permissions.push(permission);
      }
    });
  }
  return permissions;
};

const collectFieldPermissionsToServer = (read: string[], update: string[]): TPermission[] => {
  const permissions: TPermission[] = [];
  if (read?.length) {
    read.map(r => {
      const person = r.split(':')[0];
      const id = r.split(':')[1];
      switch (person) {
        case 'user':
          {
            const index = permissions.findIndex(p => (p as IUserPermission)?.userId === id);
            if (index > -1) {
              permissions[index].allows =
                permissions[index].allows.indexOf('read') === -1
                  ? permissions[index].allows.concat('read')
                  : permissions[index].allows;
              permissions[index].denies = permissions[index].denies.filter(f => f !== 'read');
            } else {
              const userPermission = new UserPermission({
                allows: ['read'],
                denies: [],
                isInherited: false,
                userId: id
              });
              permissions.push(userPermission);
            }
          }
          break;
        case 'role':
          {
            const index = permissions.findIndex(p => (p as IRolePermission)?.roleId === id);
            if (index > -1) {
              permissions[index].allows =
                permissions[index].allows.indexOf('read') === -1
                  ? permissions[index].allows.concat('read')
                  : permissions[index].allows;
              permissions[index].denies = permissions[index].denies.filter(f => f !== 'read');
            } else {
              const rolePermission = new RolePermission({
                allows: ['read'],
                denies: [],
                isInherited: false,
                roleId: id
              });
              permissions.push(rolePermission);
            }
          }
          break;

        default:
          break;
      }
    });
  }
  if (update?.length) {
    update.map(r => {
      const person = r.split(':')[0];
      const id = r.split(':')[1];
      switch (person) {
        case 'user':
          {
            const index = permissions.findIndex(p => (p as IUserPermission)?.userId === id);
            if (index > -1) {
              permissions[index].allows =
                permissions[index].allows.indexOf('update') === -1
                  ? permissions[index].allows.concat('update')
                  : permissions[index].allows;
              permissions[index].denies = permissions[index].denies.filter(f => f !== 'update');
            } else {
              const userPermission = new UserPermission({
                allows: ['read', 'update'],
                denies: [],
                isInherited: false,
                userId: id
              });
              permissions.push(userPermission);
            }
          }
          break;
        case 'role':
          {
            const index = permissions.findIndex(p => (p as IRolePermission)?.roleId === id);
            if (index > -1) {
              permissions[index].allows =
                permissions[index].allows.indexOf('update') === -1
                  ? permissions[index].allows.concat('update')
                  : permissions[index].allows;
              permissions[index].denies = permissions[index].denies.filter(f => f !== 'update');
            } else {
              const rolePermission = new RolePermission({
                allows: ['read', 'update'],
                denies: [],
                isInherited: false,
                roleId: id
              });
              permissions.push(rolePermission);
            }
          }
          break;

        default:
          break;
      }
    });
  }
  return permissions;
};

const collectFieldPermissionsReadToClient = (permissions: TPermission[], disableInheritance: boolean): string[] => {
  const compositeIds: string[] = [];
  if (!disableInheritance) {
    return compositeIds;
  }
  permissions?.map(perm => {
    if (perm.allows.includes('read')) {
      if ((perm as IUserPermission).userId) {
        const compositeId = `${perm.type}:${(perm as IUserPermission).userId}`;
        compositeIds.push(compositeId);
      }
      if ((perm as IRolePermission).roleId) {
        const compositeId = `${perm.type}:${(perm as IRolePermission).roleId}`;
        compositeIds.push(compositeId);
      }
    }
  });
  return compositeIds;
};

const collectFieldPermissionsUpdateToClient = (permissions: TPermission[], disableInheritance: boolean): string[] => {
  const compositeIds: string[] = [];
  if (!disableInheritance) {
    return compositeIds;
  }
  permissions?.map(perm => {
    if (perm.allows.includes('update') && !perm.denies.includes('read')) {
      if ((perm as IUserPermission).userId) {
        const compositeId = `${perm.type}:${(perm as IUserPermission).userId}`;
        compositeIds.push(compositeId);
      }
      if ((perm as IRolePermission).roleId) {
        const compositeId = `${perm.type}:${(perm as IRolePermission).roleId}`;
        compositeIds.push(compositeId);
      }
    }
  });
  return compositeIds;
};

export const createDefaultSourceType = (
  type: TDefaultValuetypes,
  value?: unknown,
  toJson = true
): TDefaultValueSource => {
  switch (type) {
    case 'current-user':
      return {
        type: 'current-user'
      };
    case 'static':
      return {
        type: 'static',
        value: (toJson && JSON.stringify(value)) || value
      };
    case 'list-all-entries':
      return {
        type: 'list-all-entries',
        listName: value as string
      };
    case 'today':
      return {
        type: 'today',
        dateOnly: false,
        roundToStartOfNextMonth: false,
        addDays: 0,
        addWeeks: 0,
        addMonths: 0,
        addYears: 0,
        hour: null,
        minute: null
      };
    case 'serial-number':
      return {
        type: 'serial-number',
        name: null,
        mode: 'PEEK'
      };
    case 'none':
      return {
        type: 'none',
        value
      };
    default:
      return null;
  }
};

export const reorder = (
  source: (ICategory | IDynamicCard)[],
  from: ICategory | IDynamicCard,
  to: ICategory | IDynamicCard
): void => {
  const toIndex = source.indexOf(to as any); // eslint-disable-line
  const fromIndex = source.indexOf(from as any); // eslint-disable-line
  if (toIndex === -1 || fromIndex === -1) {
    return;
  }
  source.splice(fromIndex, 1);
  source.splice(toIndex, 0, from);
};
