import {
  ILabel,
  ILabelValue,
  IPossibleVote,
  TEffectivePermission,
  TSelectableNames,
  TSourceCopyPermissionsActivityMode
} from './../types/siam';
import {
  IActionTypeDocument,
  IActionTypeStatic,
  IAssignee,
  IEdgeCustomValuesClient,
  IEdgeCustomValuesServer,
  IEdgeFieldOptions,
  IPermission,
  IPermissionTarget,
  IRole,
  IVertex,
  IWorkflow,
  IWorkflowClient,
  TActionType,
  TCondition,
  TPermission
} from '@interfaces/siam';
import * as uuid from 'uuid';
import { clone, sortBy } from './helpers';
import { siamConst } from '@interfaces/siamConst';
import {
  IAssigneeTemplateClient,
  IBallotIsFinishedEdgeConditionClient,
  IBallotIsSuccessfulEdgeConditionClient,
  IDecisionStampApprovalConditionClient,
  IDecisionStampExistsConditionClient,
  IDecisionStampStatusConditionClient,
  IEdgeClient,
  IEmptyEdgeConditionClient,
  IEqualEdgeConditionClient,
  INameListValue,
  INameListValueNode,
  IParameterTypeClient,
  ISourceAggregateClient,
  ISourceDocumentSelectorClient,
  IUserDidVoteEdgeConditionClient,
  IVertexClient,
  IWorkflowAbortBallotActionClient,
  IWorkflowCompareActionClient,
  IWorkflowConsumeSerialNumberActivityClient,
  IWorkflowCopyPermissionsActivityClient,
  IWorkflowCreateBallotActionClient,
  IWorkflowCreateDecisionActionClient,
  IWorkflowCreateDecisionActionClientDeprecated,
  IWorkflowCreateDocumentActionClient,
  IWorkflowDeleteDecisionActionClient,
  IWorkflowErrorActionClient,
  IWorkflowExecuteDocumentWorkflowActivityClient,
  IWorkflowMeetingAbortActivityClient,
  IWorkflowMeetingInviteActivityClient,
  IWorkflowMeetingProtocolApprovalActivityClient,
  IWorkflowModifyNameFieldActivityClient,
  IWorkflowModifyTagActionClient,
  IWorkflowPeekSerialNumberActivityClient,
  IWorkflowPermissionScriptActionClient,
  IWorkflowRemoveDecisionActionClientDeprecated,
  IWorkflowSendMailActionClient,
  IWorkflowSendMailTemplateActionClient,
  IWorkflowSetValueActionClient,
  IWorkflowUpdateDecisionStatusActionClient,
  IWorkflowUpdateDecisionValuesActionClient,
  TActionClient,
  TconditionClient,
  TSourceCopyPermissionsActivityClient,
  TTypes,
  TUserInputTemplateFieldChoiceInclude
} from '@interfaces/edgeClient';
import {
  IActionServer,
  IBallotIsFinishedEdgeConditionServer,
  IBallotIsSuccessfulEdgeConditionServer,
  IDecisionStampApprovalConditionServer,
  IDecisionStampExistsConditionServer,
  IDecisionStampStatusConditionServer,
  IEdgeServer,
  IEmptyEdgeConditionServer,
  IEqualEdgeConditionServer,
  IFilterAndOrSelectorExecuteDocumentWorkflow,
  IFilterNotSelectorExecuteDocumentWorkflow,
  IFilterTagIncludesSelectorExecuteDocumentWorkflow,
  IFilterWfStatusSelectorExecuteDocumentWorkflow,
  IParameterTypeBallotWorkflow,
  IParameterTypeDocument,
  IParameterTypePermissionNames,
  IParameterTypeStatic,
  IParameterTypeVariable,
  IParameterTypeWorkflow,
  ISourceAggregate,
  ISourceDocumentField,
  ISourceDocumentSelector,
  IUserDidVoteEdgeConditionServer,
  IVertexServer,
  IWorkflowAbortBallotActionServer,
  IWorkflowCompareActionServer,
  IWorkflowConsumeSerialNumberActivityServer,
  IWorkflowCopyPermissionsActivityServer,
  IWorkflowCreateBallotActionServer,
  IWorkflowCreateDecisionActionServer,
  IWorkflowCreateDocumentActionServer,
  IWorkflowDeleteDecisionActionServer,
  IWorkflowErrorActionServer,
  IWorkflowExecuteDocumentWorklfowActivityServer,
  IWorkflowMeetingAbortActivityServer,
  IWorkflowMeetingInviteActivityServer,
  IWorkflowMeetingProtocolApprovalActivityServer,
  IWorkflowModifyNameFieldActivityServer,
  IWorkflowModifyTagActionServer,
  IWorkflowPeekSerialNumberActivityServer,
  IWorkflowPermissionScriptActionServer,
  IWorkflowSendMailActionServer,
  IWorkflowSendMailTemplateActionServer,
  IWorkflowSetValueActionServer,
  IWorkflowUpdateDecisionStatusActionServer,
  IWorkflowUpdateDecisionValuesActionServer,
  TActionServer,
  TConditionServer,
  TFailureModeExecuteDocumentWorkflow,
  TFilterSelecor,
  TParameterType,
  TResolvedFieldType,
  TSourceAggregation,
  TSourceCopyPermissionsActivityServer,
  TSourceTypes
} from '@interfaces/edgeServer';
import { TemplateClient } from '@interfaces/templateClient';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { mapClientToServerDoc, mapServerToClientDoc } from './template.factory';
import { RolePermission } from '@interfaces/permission';
import { FieldPermissionTargetServ } from '@interfaces/fieldServer';
import { FilterValue } from '@interfaces/devextreme';

export interface IParameterTypeData {
  value?: unknown;
  valueType?: TResolvedFieldType;
  ballotId?: TParameterType;
  required?: TEffectivePermission;
  selection?: TSelectableNames;
}

export const selectablePermissionSource: { label: string; value: TSourceTypes }[] = [
  { label: 'Aktuelle Dokumentberechtigungen', value: null },
  { label: 'Berechtigungen von referenzierten Dokumenten', value: 'document-selector' },
  { label: 'Berechtigungen eines Feldes im aktuellen Dokument', value: 'document-field' },
  { label: 'kombinierte Berechtigungen', value: 'aggregate' }
];

export const selectableEffectivePermissions: { value: TEffectivePermission; label: string }[] = [
  { value: 'read', label: 'Lesen' },
  { value: 'update', label: 'Ändern' },
  { value: 'delete', label: 'Löschen' }
];
export const selectableSourceAggregation: { value: TSourceAggregation; label: string }[] = [
  { value: 'first', label: 'Erste gefundene Berechtigung einer Benutzer/Rolle verwenden' },
  { value: 'last', label: 'Letzte gefundene Berechtigung einer Benutzer/Rolle verwenden' },
  { value: 'combinePreferAllows', label: 'Berechtigungen kombinieren mit "allows"-Vorrang' },
  { value: 'combinePreferDenies', label: 'Berechtigungen kombinieren mit "denies"-Vorrang' }
];
export const selectableActions = [
  { value: 'permission-script', icon: 'card', label: 'Berechtigungen setzen' },
  { value: 'set-value', icon: 'edit', label: 'Dokumentwerte setzen' },
  { value: 'modify-tag', icon: 'tags', label: 'Labels ändern' },
  { value: 'create-decision', icon: 'tags', label: 'Beschluss erstellen' },
  { value: 'remove-decision', icon: 'tags', label: 'Beschluss entfernen' },
  { value: 'send-mail', icon: 'email', label: 'Mail versenden' },
  { value: 'send-mail-template', icon: 'email', label: 'Mailschablone versenden' },
  { value: 'create-ballot', icon: 'comment', label: 'Abstimmung starten' },
  { value: 'abort-ballot', icon: 'clear', label: 'Abstimmung abbrechen' },
  { value: 'meeting-invite', icon: 'export', label: 'Sitzungseinladung durchführen' },
  { value: 'meeting-protocol-approval', icon: 'exportselected', label: 'Sitzungsprotokoll freigeben' },
  { value: 'meeting-abort', icon: 'clear', label: 'Sitzung absagen' },
  { value: 'serial-number-peek', icon: 'orderedlist', label: 'Nummernkreis abfragen' },
  {
    value: 'serial-number-consume',
    icon: 'orderedlist',
    label: 'Nummernkreis abfragen und verbrauchen'
  },
  {
    value: 'modify-name-field',
    icon: 'refresh',
    label: 'Namensfeld modifizieren'
  },
  {
    value: 'error',
    icon: 'error',
    label: 'Fehlermeldung erstellen'
  }
];

export const dataSourceSelection: { value: TSelectableNames; label: string }[] = [
  { value: 'users', label: 'Benutzer' },
  { value: 'roles', label: 'Rollen' },
  { value: 'usersAndRoles', label: 'Benutzer und Rollen' }
];

export const dataSourceCopyPermissionsMode: { value: TSourceCopyPermissionsActivityMode; label: string }[] = [
  { value: 'replaceAll', label: 'Berechtigungen ersetzen' },
  { value: 'extendOrReplace', label: 'Berechtigungen hinzufügen' },
  { value: 'combinePreferAllows', label: 'Berechtigungen kombinieren mit "allows"-Vorrang' },
  { value: 'combinePreferDenies', label: 'Berechtigungen kombinieren mit "denies"-Vorrang' }
];
export const copy = <T>(value: T): T => clone(value);

export const create = (defaultRole: IRole): IWorkflow => ({
  name: 'Neuer Workflow',
  description: '',
  edges: [
    {
      name: 'create',
      label: 'Erstellen',
      source: 'start',
      target: 'created',
      permissions: [
        new RolePermission({
          roleId: defaultRole.id,
          allows: ['execute'],
          denies: []
        })
      ],
      userInputTemplate: mapClientToServerDoc(new TemplateClient({ name: 'edge-formular' })),
      actions: []
    }
  ],
  vertices: [
    {
      name: 'start',
      label: 'in Erstellung',
      flags: ['start'],
      enterActions: [],
      leaveActions: []
    },
    {
      name: 'created',
      label: 'Erstellt',
      flags: ['end'],
      enterActions: [],
      leaveActions: []
    }
  ]
});

export const mapWorkflowServerToClient = (workflow: IWorkflow): IWorkflowClient => ({
  id: workflow.id,
  name: workflow.name,
  description: workflow.description,
  effectivePermissions: workflow.effectivePermissions,
  permissions: workflow?.permissions,
  vertices: workflow.vertices.map(vertex => mapVertexServerToClient(vertex)),
  edges: workflow.edges.map(edge => mapEdgeServerToClient(edge)),
  autoUpdate: workflow?.autoUpdate,
  version: workflow?.version,
  dataSourceBallot: setDataSourceBallot(workflow)
});

export const mapWorkflowClientToServer = (workflow: IWorkflowClient): IWorkflow => ({
  id: workflow.id,
  name: workflow.name,
  description: workflow.description,
  effectivePermissions: workflow.effectivePermissions,
  permissions: workflow?.permissions,
  vertices: workflow.vertices.map(vertex => mapVertexClientToServer(vertex, workflow.labels)),
  edges: workflow.edges.map(edge => mapEdgeClientToServer(edge, workflow.labels)),
  autoUpdate: workflow?.autoUpdate,
  version: workflow?.version
});

export const createAssignee = (): IAssignee => ({
  type: 'none',
  name: 'assignee'
});

export const createEdge = (): IEdgeClient => ({
  id: uuid.v4(),
  label: '',
  name: '',
  permissions: [],
  actions: [],
  conditions: [],
  assignee: createAssignee(),
  customValues: { type: 'normal' },
  roleAssignedValues: [],
  userAssignedValues: [],
  namesListValues: [],
  userInputTemplate: new TemplateClient({ name: 'edge-formular' }),
  userInputTemplateLinkId: null
});

export const duplicate = (value: IWorkflow): IWorkflow => {
  const workflow = copy(value);

  delete workflow.id;
  workflow.edges.map(edge => {
    delete edge.edgeId;
    return edge;
  });
  workflow.name = `${value.name}-Kopie`;
  workflow.description = `${value.description}`;

  return workflow;
};

export const prepareForSave = (value: IWorkflow): IWorkflow => {
  const workflow = copy(value);
  delete workflow.version;
  delete workflow.effectivePermissions;

  // remove data from devextreme datagrid-editor
  workflow.vertices.forEach(v => {
    if (!(v.flags instanceof Object)) {
      v.flags = v.flags ? [v.flags] : null;
    }
  });

  return workflow;
};

export const reorder = (
  source: IVertex[] | IEdgeServer[] | IActionServer[] | IEdgeClient[],
  from: IVertex | IEdgeServer | IActionServer | IEdgeClient,
  to: IVertex | IEdgeServer | IActionServer | IEdgeClient
): void => {
  const toIndex = source.indexOf(to as any); // eslint-disable-line
  const fromIndex = source.indexOf(from as any); // eslint-disable-line

  source.splice(fromIndex, 1);
  source.splice(toIndex, 0, from as IActionServer);
};

export const createUserInputTemplateClient = (): IAssigneeTemplateClient => ({
  choiceInclude: 'person',
  allowMultiple: false,
  minSelection: 1,
  targetField: null,
  targetFieldExclude: null,
  currentAssigneeExclude: null,
  currentUserExclude: null,
  include: [],
  exclude: []
});

export const createCondition = (type: TCondition): TconditionClient => {
  switch (type) {
    case 'is-empty':
      return {
        type,
        source: createParameterType('document'),
        invert: false,
        isVisibleForClient: false
      };

    case 'is-equal':
      return {
        type,
        source: createParameterType('document'),
        target: createParameterType('document'),
        invert: false,
        isVisibleForClient: false
      };
    case 'is-agenda':
      return {
        type,
        source: createParameterType('variable', { value: siamConst.conditionAgendaExistsTag }),
        target: createParameterType('static', { value: true, valueType: 'boolean' }),
        invert: false,
        isVisibleForClient: false
      };
    case 'has-child':
    case 'has-parent':
      return {
        type,
        source: createParameterType('variable', { value: '' }),
        target: createParameterType('static', { value: true, valueType: 'boolean' }),
        invert: false,
        isVisibleForClient: false
      };

    case 'ballot-is-finished':
      return {
        type,
        ballotId: createParameterType('document', { value: 'fields.--siam-ballot' }),
        invert: false,
        isVisibleForClient: false
      };

    case 'ballot-is-successful':
      return {
        type,
        ballotId: createParameterType('document', { value: 'fields.--siam-ballot' }),
        invert: false,
        isVisibleForClient: false
      };

    case 'user-did-vote':
      return {
        type,
        ballotId: createParameterType('document', { value: 'fields.--siam-ballot' }),
        user: createParameterType('static'),
        onBehalfOf: createParameterType('static'),
        invert: false,
        isVisibleForClient: false
      };
    case 'decision-exists':
      return {
        type,
        category: null,
        invert: false,
        isVisibleForClient: false
      } as IDecisionStampExistsConditionClient;
    case 'decision-status':
      return {
        type,
        category: null,
        status: 'preliminary',
        invert: false,
        isVisibleForClient: false
      } as IDecisionStampStatusConditionClient;
    case 'decision-approval-value':
      return {
        type,
        category: null,
        decision: null,
        invert: false,
        isVisibleForClient: false
      } as IDecisionStampApprovalConditionClient;

    default:
      return null;
  }
};
export const createParameterTypeAssignee = (type: TTypes, value?: unknown): IParameterTypeClient => {
  switch (type) {
    case 'variable':
      if (value === 'assignee') {
        return {
          type: 'choice-assignee',
          value
        };
      }
      return {
        type: 'variable',
        value
      };

    default:
      return null;
  }
};
export const createParameterType = (type: TTypes, data?: IParameterTypeData): IParameterTypeClient => {
  let value = data?.value !== null && data?.value !== undefined ? data.value : null;
  switch (type) {
    case 'document': {
      if (value === 'creation.user') {
        return {
          type: 'creation-user'
        };
      }
      return {
        type: 'document',
        value
      };
    }
    case 'static':
      if (Array.isArray(value) && value.length && data?.valueType !== 'valueList') {
        value = value.map(v => {
          if (typeof v === 'object' && (v as IPermissionTarget).type && (v as IPermissionTarget).targetId) {
            return `${(v as IPermissionTarget).type}:${(v as IPermissionTarget).targetId}`;
          } else {
            return v as unknown;
          }
        });
      }
      if (data?.valueType === 'json') {
        value = JSON.stringify(value);
      }
      return {
        type: 'static',
        value,
        valueType: data?.valueType
      };
    case 'variable':
      return {
        type: 'variable',
        value
      };
    case 'list':
      return {
        type: 'list',
        value
      };
    case 'current-user':
      return {
        type: 'current-user'
      };
    case 'creation-user':
      return {
        type: 'creation-user'
      };
    case 'workflow':
      return {
        type: 'workflow',
        value
      };
    case 'ballot-workflow':
      return {
        type: 'ballot-workflow',
        value,
        ballotId: (data.ballotId as IParameterTypeDocument).source
      };
    case 'permission-names':
      return {
        type: 'permission-names',
        required: data?.required,
        selection: data?.selection
      };
    case 'none':
      return {
        type: 'none',
        value
      };
    default:
      return null;
  }
};

const isJsonStringFromValue = (str: string): boolean => {
  try {
    return !(typeof str === 'number' || typeof JSON.parse(str) === 'number');
  } catch (e) {
    return false;
  }
};

export const mapEdgeServerToClient = (edge: IEdgeServer): IEdgeClient => {
  const result = {
    id: uuid.v4(),
    edgeId: edge.edgeId,
    name: edge.name,
    label: edge.label,
    description: edge.description,
    actions: mapActionsServerToClient(edge.actions),
    permissionScripts: mapPermissionScriptServerToClient(edge.actions),
    assignee: mapAssigneeServerToClient(edge),
    conditions: mapConditionServerToClient(edge.conditions),
    visibilityOrder: edge.visibilityOrder,
    importancePriority: edge.importancePriority,
    customValues: mapCustomValuesServerToClient(edge),
    permissions: edge.permissions,
    userAssignedValues: mapPermissionsServerToClient(edge.permissions, 'user'),
    roleAssignedValues: mapPermissionsServerToClient(edge.permissions, 'role'),
    source: edge.source,
    target: edge.target,
    userInputTemplate: edge.userInputTemplate
      ? mapServerToClientDoc(edge.userInputTemplate)
      : new TemplateClient({ name: 'edge-formular' }),
    assigneeTemplate: mapUserInputTemplateServerToClient(edge.userInputTemplate),
    userInputTemplateLinkId: edge.userInputTemplateLinkId || null
  };
  updateNamesListDataSource(result);
  return result;
};
export const mapVertexServerToClient = (vertex: IVertexServer): IVertexClient => ({
  id: uuid.v4(),
  name: vertex.name,
  label: vertex.label,
  flags: vertex.flags,
  enterActions: (vertex?.enterActions && mapActionsServerToClient(vertex.enterActions)) || [],
  enterPermissionScripts: mapPermissionScriptServerToClient(vertex.enterActions),
  leaveActions: (vertex?.leaveActions && mapActionsServerToClient(vertex.leaveActions)) || [],
  leavePermissionScripts: mapPermissionScriptServerToClient(vertex.leaveActions),
  clientConfiguration: vertex.clientConfiguration || {}
});
export const mapEdgeClientToServer = (edge: IEdgeClient, labels?: ILabel[]): IEdgeServer => ({
  edgeId: edge.edgeId,
  name: edge.name,
  label: edge.label,
  description: edge.description,
  actions: (edge?.actions && mapActionsClientToServer(edge.actions, labels)) || [],
  assignee: (edge?.assignee && mapAssigneeClientToServer(edge.assignee)) || null,
  conditions: (edge?.conditions && mapConditionClientToServer(edge.conditions)) || [],
  visibilityOrder: edge.visibilityOrder,
  customValues: mapCustomValuesClientToServer(edge),
  importancePriority: edge.importancePriority,
  permissions: edge.permissions,
  source: edge.source,
  target: edge.target,
  userInputTemplate: mapEdgeTemplateToServer(edge),
  userInputTemplateLinkId: edge.userInputTemplateLinkId || null
});
export const mapVertexClientToServer = (vertex: IVertexClient, labels?: ILabel[]): IVertexServer => ({
  name: vertex.name,
  label: vertex.label,
  flags: vertex.flags,
  enterActions: (vertex?.enterActions && mapActionsClientToServer(vertex.enterActions, labels)) || [],
  leaveActions: (vertex?.leaveActions && mapActionsClientToServer(vertex.leaveActions, labels)) || [],
  clientConfiguration: vertex.clientConfiguration
});

export const mapUserInputTemplateServerToClient = (template: ITemplateServer): IAssigneeTemplateClient => {
  let result: IAssigneeTemplateClient = null;
  if (isAssigneeTemplate(template)) {
    const field = template.fields[0] as FieldPermissionTargetServ;
    const forbidUsers = field.forbidUsers;
    const forbidRoles = field.forbidRoles;
    let choiceInclude: TUserInputTemplateFieldChoiceInclude = 'both';

    if (!forbidUsers && forbidRoles) {
      choiceInclude = 'person';
    } else if (forbidUsers && !forbidRoles) {
      choiceInclude = 'role';
    }

    if (field) {
      result = {
        choiceInclude,
        allowMultiple: field.allowMultiple,
        minSelection: field.customValues?.minSelection,
        targetField: field.customValues?.targetField,
        targetFieldExclude: field.customValues?.targetFieldExclude,
        currentAssigneeExclude: field.customValues?.currentAssigneeExclude,
        currentUserExclude: field.customValues?.currentUserExclude,
        include:
          field.include?.map((data: unknown) =>
            (data as IPermissionTarget).targetId && (data as IPermissionTarget).type
              ? `${(data as IPermissionTarget).type}:${(data as IPermissionTarget).targetId}`
              : (data as string)
          ) || [],
        exclude:
          field.exclude?.map((data: unknown) =>
            (data as IPermissionTarget).targetId && (data as IPermissionTarget).type
              ? `${(data as IPermissionTarget).type}:${(data as IPermissionTarget).targetId}`
              : (data as string)
          ) || []
      };
    }
  }
  return result;
};

export const mapEdgeTemplateToServer = (edge: IEdgeClient): ITemplateServer => {
  if (edge?.assignee?.type === 'choice-assignee' && edge?.assigneeTemplate) {
    let forbidUsers = false;
    let forbidRoles = false;
    const assigneeTemplate = edge.assigneeTemplate || ({} as IAssigneeTemplateClient);
    switch (assigneeTemplate.choiceInclude) {
      case 'person':
        forbidRoles = true;
        forbidUsers = false;
        break;

      case 'role':
        forbidUsers = true;
        forbidRoles = false;
        break;

      case 'both':
        forbidUsers = false;
        forbidRoles = false;
        break;
    }

    return {
      caption: null,
      documentPermissions: null,
      fieldPermissions: null,
      permissions: null,
      name: 'user-fields',
      fields: [
        {
          type: 'permission-targets',
          name: 'assignee',
          forbidUsers,
          forbidRoles,
          customValues: {
            targetField: assigneeTemplate.targetField,
            targetFieldExclude: assigneeTemplate.targetFieldExclude,
            currentAssigneeExclude: assigneeTemplate.currentAssigneeExclude,
            currentUserExclude: assigneeTemplate.currentUserExclude,
            minSelection: assigneeTemplate.allowMultiple ? assigneeTemplate.minSelection : 1
          },
          allowMultiple: assigneeTemplate.allowMultiple,
          include:
            (edge?.assignee?.type === 'choice-assignee' &&
              assigneeTemplate.include?.map(compositeId => createPermissionTarget(compositeId))) ||
            [],
          exclude:
            (edge?.assignee?.type === 'choice-assignee' &&
              assigneeTemplate.choiceInclude !== 'role' &&
              assigneeTemplate.exclude?.map(compositeId => createPermissionTarget(compositeId))) ||
            [],
          label: 'Empfänger',
          validations: null
        }
      ],
      layouts: [],
      tags: [siamConst.siamAssigneeTag]
    } as ITemplateServer;
  }

  const template = edge.userInputTemplate;
  if (!template?.fields?.length) {
    return null;
  }
  template.name = 'edge-formular';
  return mapClientToServerDoc(template);
};

export const mapPermissionsServerToClient = (permissions: TPermission[], type: 'role' | 'user'): string[] => {
  const ids: string[] = [];
  if (Array.isArray(permissions)) {
    const filtered = permissions.filter(perm => perm.type === type);
    filtered.forEach(x => {
      if (x.type === 'user') {
        ids.push(x.userId);
      }
      if (x.type === 'role') {
        ids.push(x.roleId);
      }
    });
  }
  return ids;
};

export const mapParameterTypeClientToServer = (
  parameter?: IParameterTypeClient,
  staticType: TResolvedFieldType = null
): TParameterType => {
  switch (parameter?.type) {
    case 'static': {
      let value = parameter.value;
      if (value === 'null') {
        value = null;
      }
      if (Array.isArray(value) && value?.length) {
        value = value?.map(v => {
          if (typeof v === 'string' && (v.startsWith('role') || v.startsWith('user'))) {
            return createPermissionTarget(v);
          } else {
            return v as unknown;
          }
        });
      }
      if (parameter.valueType === 'json') {
        value = isJsonStringFromValue(value as string) ? JSON.parse(value as string) : value;
      }
      return { type: 'static', value, valueType: staticType || parameter.valueType };
    }

    case 'document':
      return { type: 'document', source: parameter.value as string };
    case 'creation-user':
      return { type: 'document', source: 'creation.user' };
    case 'permission-names':
      return {
        type: 'permission-names',
        required: typeof parameter.required === 'string' ? [parameter.required] : parameter.required,
        selection: parameter.selection
      };
    case 'variable':
      return { type: 'variable', name: parameter.value as string };
    case 'workflow':
      return { type: 'workflow', source: parameter.value as string };
    case 'ballot-workflow':
      return {
        type: 'ballot-workflow',
        source: parameter.value as string,
        ballotId: { type: 'document', source: parameter.ballotId } as IParameterTypeDocument
      };
    case 'list':
      return { type: 'list', name: parameter.value as string };
    case 'current-user':
      return { type: 'current-user' };
    default:
      return null;
  }
};

export const mapParameterTagsClientToServer = (tags: string[], labels?: ILabel[]): TParameterType => ({
  type: 'static',
  value: tags.map(tag => {
    const getId = (key: string): string => key.substring(key.lastIndexOf(':') + 1);
    if (tag.startsWith('app:document-label:')) {
      const tagId = getId(tag);
      return labels?.find(l => l.value === tag || tagId === l.id)?.value || tag;
    }

    return (
      labels?.find(l => l.id === tag || tag === getId(l.value))?.value ||
      'app:document-label:' + siamConst.globalLabelsListName + ':' + tag
    );
  })
});

export const mapParameterTypeServerToClient = (parameter?: TParameterType): IParameterTypeClient => {
  switch (parameter?.type) {
    case 'static':
      if ((parameter as IParameterTypeStatic).value === 'current-user') {
        return createParameterType('current-user', (parameter as IParameterTypeStatic).value);
      }
      return createParameterType('static', {
        value: (parameter as IParameterTypeStatic).value,
        valueType: (parameter as IParameterTypeStatic).valueType
      });
    case 'current-user':
      return createParameterType('current-user');
    case 'document': {
      if ((parameter as IParameterTypeDocument).source === 'creation.user') {
        return createParameterType('creation-user');
      }
      return createParameterType('document', { value: (parameter as IParameterTypeDocument).source });
    }
    case 'permission-names':
      return createParameterType('permission-names', {
        required: Array.isArray((parameter as IParameterTypePermissionNames).required)
          ? (parameter as IParameterTypePermissionNames).required[0]
          : null,
        selection: (parameter as IParameterTypePermissionNames).selection
      });
    case 'variable':
      return createParameterType('variable', { value: (parameter as IParameterTypeVariable).name });
    case 'workflow':
      return createParameterType('workflow', { value: (parameter as IParameterTypeWorkflow).source });
    case 'ballot-workflow':
      return createParameterType('ballot-workflow', {
        value: (parameter as IParameterTypeBallotWorkflow).source,
        valueType: 'undefined',
        ballotId: (parameter as IParameterTypeBallotWorkflow).ballotId
      });
    case 'list':
      return createParameterType('list', { value: (parameter as IParameterTypeVariable).name });
    case null:
      return createParameterType('none');
    default:
      return createParameterType('none');
  }
};

export const mapAssigneeClientToServer = (assignee?: IParameterTypeClient): TParameterType => {
  switch (assignee?.type) {
    case 'variable':
      return { type: 'variable', name: assignee.value as string };
    case 'choice-assignee':
      return { type: 'variable', name: 'assignee' };
    case 'static':
      if (Array.isArray(assignee.value) && assignee.value.length) {
        assignee.value = assignee.value.map(v => {
          if (typeof v === 'string' && (v.startsWith('role') || v.startsWith('user'))) {
            return createPermissionTarget(v);
          } else {
            return v as unknown;
          }
        });
      }
      return { type: 'static', value: assignee.value };
    case 'document':
      return { type: 'document', source: assignee.value as string };
    case 'workflow':
      return { type: 'workflow', source: assignee.value as string };
    case 'ballot-workflow':
      return {
        type: 'ballot-workflow',
        source: assignee.value as string,
        ballotId: { type: 'document', source: assignee.ballotId } as IParameterTypeDocument
      };
    case 'list':
      return { type: 'list', name: assignee.value as string };
    case 'current-user':
      return { type: 'current-user' };
    case 'creation-user':
      return { type: 'document', source: 'creation.user' };
    case 'none':
    default:
      return null;
  }
};

export const mapAssigneeServerToClient = (edge: IEdgeServer): IParameterTypeClient => {
  if (edge.assignee) {
    switch (edge.assignee.type) {
      case 'variable':
        return createParameterTypeAssignee('variable', (edge.assignee as IParameterTypeVariable).name);
      case 'document':
        return createParameterType('document', { value: (edge.assignee as IParameterTypeDocument).source });
      case 'static':
        if ((edge.assignee as IParameterTypeStatic).value === 'current-user') {
          return createParameterType('current-user', { value: (edge.assignee as IParameterTypeStatic).value });
        }
        return createParameterType('static', { value: (edge.assignee as IParameterTypeStatic).value });
      case 'current-user':
        return createParameterType('current-user');
      case 'workflow':
        return createParameterType('workflow', { value: (edge.assignee as IParameterTypeWorkflow).source });
      case 'ballot-workflow':
        return createParameterType('ballot-workflow', {
          value: (edge.assignee as IParameterTypeBallotWorkflow).source,
          valueType: 'undefined',
          ballotId: (edge.assignee as IParameterTypeBallotWorkflow).ballotId
        });
      case 'list':
        return createParameterType('list', { value: (edge.assignee as IParameterTypeVariable).name });
      case null:
        return createParameterType('none');

      default:
        return createParameterType('none');
    }
  } else {
    return createParameterType('none');
  }
};

export const mapCustomValuesServerToClient = (edge: IEdgeServer): IEdgeCustomValuesClient => {
  const result: IEdgeCustomValuesClient = {};
  if (edge.customValues) {
    Object.keys(edge.customValues).forEach(key => {
      const value = edge.customValues[key];
      if (key.startsWith('namesList')) {
        result[key] = (value as IPermissionTarget[]).map(v => createCompositeId(v));
      }
      if (key.startsWith('wert') || key.startsWith('icon') || key.startsWith('type')) {
        result[key] = value as string;
      }
      if (key.startsWith('boolean') || key.startsWith('is_workflow_signature')) {
        result[key] = value as boolean;
      }
      if (key.startsWith('number')) {
        result[key] = value as number;
      }
    });
  }
  if (edge.userInputTemplateLinkId) {
    result.dialogformularId = edge.userInputTemplateLinkId;
  }
  return result;
};

export const mapCustomValuesClientToServer = (edge: IEdgeClient): IEdgeCustomValuesServer => {
  const result: IEdgeCustomValuesServer = {};
  if (edge.customValues) {
    Object.keys(edge.customValues).forEach(key => {
      const value = edge.customValues[key];
      if (key.startsWith('namesList')) {
        result[key] = (value as string[])?.map(v => createPermissionTarget(v));
      }
      if (key.startsWith('wert') || key.startsWith('icon') || key.startsWith('type')) {
        result[key] = value as string;
      }
      if (key.startsWith('boolean') || key.startsWith('is_workflow_signature')) {
        result[key] = value as boolean;
      }
      if (key.startsWith('number')) {
        result[key] = value as number;
      }
    });
  }
  if (edge.userInputTemplateLinkId) {
    result.dialogformularId = edge.userInputTemplateLinkId;
  } else {
    delete result.dialogformularId;
  }
  if (edge.conditions?.find(c => c.type === 'is-agenda')) {
    result[siamConst.conditionAgendaExistsTag] = false;
  }
  edge.conditions
    ?.filter(c => c.type === 'has-child' || c.type === 'has-parent')
    .forEach(condition => {
      const documentType = (condition as IEqualEdgeConditionClient).source.value as string;
      result[documentType] = false;
    });

  return result;
};

export const createAssigneeOptions = (type: TTypes | 'assignee', edge: IEdgeClient): void => {
  switch (type) {
    case 'assignee':
      edge.assignee = {
        type: 'choice-assignee',
        value: 'assignee'
      };
      edge.assigneeTemplate = createUserInputTemplateClient();
      break;
    case 'static':
      edge.assignee = {
        type: 'static',
        value: ''
      };
      break;
    case 'document':
      edge.assignee = {
        type: 'document',
        value: ''
      };
      break;
    case 'none':
      edge.assignee = {
        type: 'none',
        value: 'assignee'
      };
      edge.assigneeTemplate = {} as IAssigneeTemplateClient;
      if (edge.userInputTemplate?.tags?.includes(siamConst.siamAssigneeTag)) {
        edge.userInputTemplate = new TemplateClient({ name: 'edge-formular' });
      }
      break;

    default:
      break;
  }
};

export const createActionTypeStatic = (value?: unknown): IActionTypeStatic => ({
  type: 'static',
  value
});

export const createActionTypeDocument = (): IActionTypeDocument => ({
  type: 'document',
  source: `fields.--siam-ballot-${uuid.v4()}`
});

export const getAllowedTypes = (
  actionType: TActionType | TCondition | 'assignee',
  parameter: string
): IEdgeFieldOptions => {
  const result: IEdgeFieldOptions = {
    allowedTypes: [
      'document',
      'creation-user',
      'permission-names',
      'static',
      'variable',
      'list',
      'current-user',
      'workflow',
      'ballot-workflow'
    ],
    allowedStaticTypes: [
      'dxDateBox',
      'dxCheckBox',
      'dxHtmlEditor',
      'dxNumberBox',
      'dxTagBox',
      'dxTextArea',
      'dxTextBox'
    ],
    required: true
  };
  switch (actionType) {
    case 'set-value':
      switch (parameter) {
        case 'source':
          {
            result.allowedTypes = [
              'document',
              'permission-names',
              'creation-user',
              'static',
              'variable',
              'list',
              'current-user',
              'workflow',
              'ballot-workflow'
            ];
            result.allowedStaticTypes = [
              'dxDateBox',
              'dxCheckBox',
              'dxHtmlEditor',
              'dxNumberBox',
              'dxTagBox',
              'dxTextArea',
              'dxTextBox',
              'dxSelectBox',
              'dxRadioGroup',
              'dxCheckboxGroup',
              'empty'
            ];
            result.required = true;
          }
          break;
        case 'destination':
          result.allowedTypes = ['document', 'workflow', 'ballot-workflow', 'variable'];
          result.required = true;
          break;
        default:
          break;
      }
      break;
    case 'compare':
      switch (parameter) {
        case 'operand1':
        case 'operand2':
          {
            result.allowedTypes = [
              'document',
              'permission-names',
              'creation-user',
              'static',
              'variable',
              'list',
              'current-user',
              'workflow',
              'ballot-workflow'
            ];
            result.allowedStaticTypes = [
              'dxDateBox',
              'dxCheckBox',
              'dxHtmlEditor',
              'dxNumberBox',
              'dxTagBox',
              'dxTextArea',
              'dxTextBox',
              'dxSelectBox',
              'dxRadioGroup',
              'dxCheckboxGroup',
              'empty'
            ];
            result.required = true;
          }
          break;
        case 'result':
          result.allowedTypes = ['document', 'variable'];
          result.required = true;
          break;
        default:
          break;
      }
      break;
    case 'create-document':
      switch (parameter) {
        case 'parentDocumentId':
          result.allowedTypes = [
            'document',
            'permission-names',
            'creation-user',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.allowedStaticTypes = [
            'dxDateBox',
            'dxCheckBox',
            'dxHtmlEditor',
            'dxNumberBox',
            'dxTagBox',
            'dxTextArea',
            'dxTextBox',
            'dxSelectBox',
            'dxRadioGroup',
            'dxCheckboxGroup',
            'empty'
          ];
          result.required = false;
          break;

        default:
          break;
      }
      break;
    case 'modify-name-field':
      switch (parameter) {
        case 'source':
          {
            result.allowedTypes = [
              'document',
              'permission-names',
              'creation-user',
              'static',
              'variable',
              'list',
              'current-user',
              'workflow',
              'ballot-workflow'
            ];
            result.allowedStaticTypes = [
              'dxDateBox',
              'dxCheckBox',
              'dxHtmlEditor',
              'dxNumberBox',
              'dxTagBox',
              'dxTextArea',
              'dxTextBox',
              'dxSelectBox',
              'dxRadioGroup',
              'dxCheckboxGroup',
              'empty'
            ];
            result.required = false;
          }
          break;
        case 'destination':
          result.allowedTypes = ['document', 'workflow', 'ballot-workflow', 'variable'];
          result.required = true;
          break;
        default:
          break;
      }
      break;
    case 'serial-number-peek':
    case 'serial-number-consume':
      switch (parameter) {
        case 'target':
          result.allowedTypes = ['document', 'workflow', 'ballot-workflow'];
          result.required = true;
          break;
        default:
          break;
      }
      break;
    case 'create-ballot':
      switch (parameter) {
        case 'voters':
          result.allowedTypes = [
            'permission-names',
            'creation-user',
            'document',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.required = true;
          result.allowedStaticTypes = ['dxTagBox'];
          break;
      }
      break;
    case 'meeting-protocol-approval':
    case 'meeting-invite':
    case 'meeting-abort':
      switch (parameter) {
        case 'replyTo':
          result.allowedTypes = [
            'none',
            'permission-names',
            'creation-user',
            'document',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.required = false;
          result.allowedStaticTypes = ['dxTagBox'];
          break;
      }
      break;
    case 'permission-script':
      switch (parameter) {
        case 'script':
          result.allowedTypes = ['document', 'static'];
          result.allowedStaticTypes = ['dxTextArea'];
          result.required = true;
          break;
      }
      break;
    case 'send-mail':
    case 'send-mail-template':
      switch (parameter) {
        case 'to':
          result.allowedTypes = [
            'document',
            'permission-names',
            'creation-user',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.required = true;
          result.allowedStaticTypes = ['dxTagBox'];
          break;
        case 'replyTo':
          result.allowedTypes = [
            'none',
            'permission-names',
            'creation-user',
            'document',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.required = false;
          result.allowedStaticTypes = ['dxTagBox'];
          break;
        case 'subject':
          result.allowedTypes = ['document', 'static', 'workflow', 'ballot-workflow'];
          if (actionType === 'send-mail') {
            result.allowedTypes = ['document', 'static', 'workflow', 'ballot-workflow', 'variable'];
          }
          result.required = true;
          result.allowedStaticTypes = ['dxTextBox'];
          break;
        case 'body':
          result.allowedTypes = ['document', 'static', 'workflow', 'ballot-workflow'];
          if (actionType === 'send-mail') {
            result.allowedTypes = ['document', 'static', 'workflow', 'ballot-workflow', 'variable'];
          }
          result.required = true;
          result.allowedStaticTypes = ['dxTextBox', 'dxTextArea', 'dxHtmlEditor'];
          break;
      }
      break;
    case 'abort-ballot':
      switch (parameter) {
        case 'ballotId':
          result.allowedTypes = ['ballot-workflow'];
          result.required = true;
          result.allowedStaticTypes = [];
          break;

        default:
          break;
      }
      break;
    case 'execute-document-workflow':
      switch (parameter) {
        case 'edgeName':
          result.allowedTypes = ['document', 'static', 'workflow', 'ballot-workflow', 'variable'];
          result.required = true;
          result.allowedStaticTypes = ['dxTextBox'];
          break;

        default:
          break;
      }
      break;
    case 'is-empty':
    case 'is-equal':
      switch (parameter) {
        case 'source':
          result.allowedTypes = ['document', 'workflow', 'ballot-workflow', 'variable', 'static'];
          result.required = true;
          break;
        case 'target':
          result.allowedTypes = [
            'document',
            'permission-names',
            'creation-user',
            'static',
            'variable',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.allowedStaticTypes = [
            'dxDateBox',
            'dxCheckBox',
            'dxHtmlEditor',
            'dxNumberBox',
            'dxTagBox',
            'dxTextArea',
            'dxTextBox',
            'dxSelectBox',
            'dxRadioGroup',
            'dxCheckboxGroup',
            'empty'
          ];
          result.required = true;
          break;

        default:
          break;
      }
      break;
    case 'user-did-vote':
      switch (parameter) {
        case 'user':
          result.allowedTypes = [
            'document',
            'permission-names',
            'static',
            'variable',
            'list',
            'current-user',
            'workflow',
            'ballot-workflow'
          ];
          result.allowedStaticTypes = ['dxTagBox'];
          result.required = true;
          break;

        default:
          break;
      }
      break;
    case 'assignee':
      result.allowedTypes = [
        'document',
        'permission-names',
        'creation-user',
        'static',
        'variable',
        'list',
        'current-user',
        'workflow',
        'ballot-workflow',
        'choice-assignee',
        'none'
      ];
      result.allowedStaticTypes = ['dxTagBox'];
      result.required = true;
      break;
    default:
      result.allowedTypes = [
        'document',
        'creation-user',
        'permission-names',
        'static',
        'variable',
        'list',
        'current-user',
        'workflow',
        'ballot-workflow'
      ];
      result.required = true;
  }
  if (!result.required) {
    result.allowedTypes.push('none');
  }
  return result;
};
export const createAction = (type: TActionType): TActionClient => {
  switch (type) {
    case 'permission-script':
      return {
        type,
        script: createParameterType('static'),
        variables: { actionComment: '' }
      };
    case 'modify-tag':
      return {
        type,
        preferAdd: 'add',
        tags: [],
        variables: { modificationTag: 'label', actionComment: '' }
      } as IWorkflowModifyTagActionClient;
    case 'create-decision':
      return {
        type,
        serialNumberName: null,
        status: 'preliminary',
        variableName: 'decision',
        variables: { actionComment: '' }
      } as IWorkflowCreateDecisionActionClient;
    case 'update-decision-status':
      return {
        type,
        status: 'final',
        categories: null,
        ignoreMissing: true,
        variables: { actionComment: '' }
      } as IWorkflowUpdateDecisionStatusActionClient;
    case 'update-decision-values':
      return {
        type,
        category: null,
        variableName: 'decision',
        variables: { actionComment: '' }
      } as IWorkflowUpdateDecisionValuesActionClient;
    case 'delete-decision':
      return {
        type,
        categories: null,
        ignoreMissing: false,
        variables: { actionComment: '' }
      } as IWorkflowDeleteDecisionActionClient;
    case 'remove-decision-deprecated':
      return {
        type,
        preferAdd: 'remove',
        tags: ['app:document-type:decision'],
        variables: { modificationTag: 'decision', actionComment: '' }
      } as IWorkflowRemoveDecisionActionClientDeprecated;

    case 'set-value':
      return {
        type,
        source: createParameterType('static', null),
        destination: createParameterType('document', null),
        variables: { actionComment: '' }
      };

    case 'compare':
      return {
        type,
        operand1: createParameterType('document', null),
        operand2: createParameterType('document', null),
        result: createParameterType('document', null),
        operator: 'equal',
        variables: { actionComment: '' }
      };

    case 'send-mail':
      return {
        type,
        to: createParameterType('static'),
        subject: createParameterType('static'),
        body: createParameterType('static'),
        replyTo: createParameterType('none'),
        variables: { actionComment: '' }
      };

    case 'send-mail-template':
      return {
        type,
        to: createParameterType('variable'),
        emailTemplateId: null,
        replyTo: createParameterType('none'),
        variables: { actionComment: '' }
      };

    case 'abort-ballot':
      return {
        type,
        ballotId: createParameterType('document'),
        variables: { actionComment: '' }
      };

    case 'meeting-invite':
    case 'meeting-abort':
      return {
        type,
        meetingParticipantsFieldName: null,
        meetingParticipantsEmailTemplateId: null,
        speakersFieldName: null,
        speakersEmailTemplateId: null,
        recorderFieldName: null,
        recorderEmailTemplateId: null,
        replyTo: createParameterType('none'),
        variables: { actionComment: '' }
      };

    case 'meeting-protocol-approval':
      return {
        type,
        meetingParticipantsFieldName: null,
        meetingParticipantsEmailTemplateId: null,
        speakersFieldName: null,
        speakersEmailTemplateId: null,
        recorderFieldName: null,
        recorderEmailTemplateId: null,
        replyTo: createParameterType('none'),
        agendaWorkflowActionName: null,
        agendaItemWorkflowActionName: null,
        agendaItemProtocolWorkflowActionName: null,
        agendaItemTaskWorkflowActionName: null,
        agendaSubmissionPermissionNamesFieldName: null,
        agendaItemPermissionNamesFieldName: null,
        agendaItemTaskPermissionNamesFieldName: null,
        agendaItemProtocolPermissionNamesFieldName: null,
        agendaSubmissionWorkflowActionName: null,
        failureMode: ['ignoreEverything'],
        variables: { actionComment: '' }
      } as IWorkflowMeetingProtocolApprovalActivityClient;

    case 'create-ballot':
      return {
        type,
        ballotTemplateId: createActionTypeStatic(siamConst.defaultBallotId),
        voters: createParameterType('static'),
        voteItems: {
          type: 'static',
          value: [
            {
              label: 'Zustimmung',
              value: 'ja',
              isSuccess: true,
              properties: { voteDiagramColor: '#97c95c' }
            },
            {
              label: 'Ablehnung',
              value: 'nein',
              isSuccess: false,
              properties: { voteDiagramColor: '#f5564a' }
            },
            {
              label: 'Enthaltung',
              value: 'enthaltung',
              isSuccess: true,
              properties: { voteDiagramColor: '#f8ca00' }
            }
          ] as IPossibleVote[],
          valueType: 'json'
        } as IParameterTypeClient,
        variables: { actionComment: '' }
      } as IWorkflowCreateBallotActionClient;
    case 'serial-number-peek':
    case 'serial-number-consume':
      return {
        type,
        serialNumberName: null,
        target: createParameterType('document'),
        variables: { actionComment: '' }
      } as IWorkflowPeekSerialNumberActivityClient;
    case 'modify-name-field':
      return {
        type,
        operation: 'add',
        source: createParameterType('static'),
        destination: createParameterType('document'),
        variables: { actionComment: '' }
      } as IWorkflowModifyNameFieldActivityClient;
    case 'execute-document-workflow':
      return {
        type,
        edgeName: createParameterType('static'),
        selectors: [{ type: 'children', filter: ['document', 'tags-includes', 'app:document-type:top'] }],
        failureMode: [],
        deferExecution: false,
        variables: { actionComment: '' }
      } as IWorkflowExecuteDocumentWorkflowActivityClient;
    case 'copy-permissions':
      return {
        type,
        targetSelectors: [{ type: 'children', filter: ['document', 'tags-includes', 'app:document-type:top'] }],
        sourceType: null,
        mode: 'extendOrReplace',
        variables: { actionComment: '' }
      } as IWorkflowCopyPermissionsActivityClient;
    case 'create-document':
      return {
        type,
        parentDocumentId: true,
        documentTemplateId: null,
        variableName: null,
        cloneFields: true,
        showEditor: true,
        variables: { actionComment: '' }
      } as IWorkflowCreateDocumentActionClient;
    case 'error':
      return {
        type,
        errorMessage: ''
      } as IWorkflowErrorActionClient;
    default:
      return null;
  }
};

export const createVertexClient = (): IVertexClient => ({
  id: uuid.v4(),
  name: '',
  label: '',
  flags: [],
  enterActions: [],
  leaveActions: []
});

export const mapConditionClientToServer = (conditions: TconditionClient[]): TConditionServer[] => {
  const result: TConditionServer[] = [];
  conditions.forEach(condition => {
    switch (condition.type) {
      case 'is-empty':
        {
          result.push({
            type: condition.type,
            invert: condition.invert,
            isVisibleForClient: condition.isVisibleForClient,
            source: mapParameterTypeClientToServer((condition as IEmptyEdgeConditionClient).source),
            errorText: condition.errorText
          } as IEmptyEdgeConditionServer);
        }
        break;
      case 'is-equal':
      case 'is-agenda':
      case 'has-child':
      case 'has-parent':
        {
          result.push({
            type: 'is-equal',
            invert: condition.invert,
            isVisibleForClient: condition.isVisibleForClient,
            source: mapParameterTypeClientToServer((condition as IEqualEdgeConditionClient).source),
            target: mapParameterTypeClientToServer((condition as IEqualEdgeConditionClient).target),
            errorText: condition.errorText
          } as IEqualEdgeConditionServer);
        }
        break;
      case 'user-did-vote':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            user: (condition as IUserDidVoteEdgeConditionClient).user,
            ballotId: (condition as IUserDidVoteEdgeConditionClient).ballotId,
            onBehalfOf: (condition as IUserDidVoteEdgeConditionClient).onBehalfOf
          } as IUserDidVoteEdgeConditionServer);
        }
        break;
      case 'ballot-is-finished':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            ballotId: (condition as IBallotIsFinishedEdgeConditionClient).ballotId
          } as IBallotIsFinishedEdgeConditionServer);
        }
        break;
      case 'ballot-is-successful':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            ballotId: (condition as IBallotIsSuccessfulEdgeConditionClient).ballotId
          } as IBallotIsSuccessfulEdgeConditionServer);
        }
        break;
      case 'decision-exists':
        {
          result.push({
            type: condition.type,
            category: createParameterType('static', {
              value: (condition as IDecisionStampExistsConditionClient).category
            }),
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert
          } as IDecisionStampExistsConditionServer);
        }
        break;
      case 'decision-status':
        {
          result.push({
            type: condition.type,
            category: createParameterType('static', {
              value: (condition as IDecisionStampStatusConditionClient).category
            }),
            isVisibleForClient: condition.isVisibleForClient,
            status: (condition as IDecisionStampStatusConditionClient).status,
            errorText: condition.errorText,
            invert: condition.invert
          } as IDecisionStampStatusConditionServer);
        }
        break;
      case 'decision-approval-value':
        {
          result.push({
            type: condition.type,
            category: createParameterType('static', {
              value: (condition as IDecisionStampApprovalConditionClient).category
            }),
            decision: createParameterType('static', {
              value: (condition as IDecisionStampApprovalConditionClient).decision
            }),
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert
          } as IDecisionStampApprovalConditionServer);
        }
        break;
    }
  });
  return result;
};

export const mapConditionServerToClient = (conditions: TConditionServer[]): TconditionClient[] => {
  const result: TconditionClient[] = [];
  conditions?.forEach(condition => {
    switch (condition.type) {
      case 'is-empty':
        {
          result.push({
            type: condition.type,
            invert: condition.invert,
            isVisibleForClient: condition.isVisibleForClient,
            source: mapParameterTypeServerToClient((condition as IEmptyEdgeConditionServer).source),
            errorText: condition.errorText
          } as IEmptyEdgeConditionClient);
        }
        break;
      case 'is-equal':
        {
          const source = (condition as IEqualEdgeConditionServer).source as IParameterTypeVariable;
          if (source.type === 'variable' && source.name === siamConst.conditionAgendaExistsTag) {
            result.push({
              type: 'is-agenda',
              invert: condition.invert,
              isVisibleForClient: condition.isVisibleForClient,
              source: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).source),
              target: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).target),
              errorText: condition.errorText
            } as IEqualEdgeConditionClient);
          } else if (source.type === 'variable' && source.name.startsWith(siamConst.conditionChildExistsTag)) {
            result.push({
              type: 'has-child',
              invert: condition.invert,
              isVisibleForClient: condition.isVisibleForClient,
              source: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).source),
              target: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).target),
              errorText: condition.errorText
            } as IEqualEdgeConditionClient);
          } else if (source.type === 'variable' && source.name.startsWith(siamConst.conditionParentExistsTag)) {
            result.push({
              type: 'has-parent',
              invert: condition.invert,
              isVisibleForClient: condition.isVisibleForClient,
              source: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).source),
              target: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).target),
              errorText: condition.errorText
            } as IEqualEdgeConditionClient);
          } else {
            result.push({
              type: condition.type,
              invert: condition.invert,
              isVisibleForClient: condition.isVisibleForClient,
              source: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).source),
              target: mapParameterTypeServerToClient((condition as IEqualEdgeConditionServer).target),
              errorText: condition.errorText
            } as IEqualEdgeConditionClient);
          }
        }
        break;
      case 'user-did-vote':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            user: (condition as IUserDidVoteEdgeConditionServer).user,
            ballotId: (condition as IUserDidVoteEdgeConditionServer).ballotId,
            onBehalfOf: (condition as IUserDidVoteEdgeConditionServer).onBehalfOf
          } as IUserDidVoteEdgeConditionClient);
        }
        break;
      case 'ballot-is-finished':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            ballotId: (condition as IBallotIsFinishedEdgeConditionServer).ballotId
          } as IBallotIsFinishedEdgeConditionClient);
        }
        break;
      case 'ballot-is-successful':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            ballotId: (condition as IBallotIsSuccessfulEdgeConditionServer).ballotId
          } as IBallotIsSuccessfulEdgeConditionClient);
        }
        break;
      case 'decision-exists':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            category: (condition as IDecisionStampExistsConditionServer).category.value
          } as IDecisionStampExistsConditionClient);
        }
        break;
      case 'decision-status':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            category: (condition as IDecisionStampStatusConditionServer).category.value,
            status: (condition as IDecisionStampStatusConditionServer).status
          } as IDecisionStampStatusConditionClient);
        }
        break;
      case 'decision-approval-value':
        {
          result.push({
            type: condition.type,
            isVisibleForClient: condition.isVisibleForClient,
            errorText: condition.errorText,
            invert: condition.invert,
            category: (condition as IDecisionStampApprovalConditionServer).category.value,
            decision: (condition as IDecisionStampApprovalConditionServer).decision.value
          } as IDecisionStampApprovalConditionClient);
        }
        break;
    }
  });
  return result;
};

export const mapPermissionScriptServerToClient = (actions: TActionServer[]): ILabelValue[] => {
  const result: ILabelValue[] = [];
  const permissionScript = actions.filter(a => a.type === 'permission-script');
  permissionScript.forEach(permission => {
    if ((permission as IWorkflowPermissionScriptActionServer).script.type === 'static') {
      let label = ((permission as IWorkflowPermissionScriptActionServer).script as IParameterTypeStatic)
        .value as string;
      if (label?.length > 30) {
        label = `${label.substring(0, 27)}...`;
      }
      const value = ((permission as IWorkflowPermissionScriptActionServer).script as IParameterTypeStatic)
        .value as string;
      if (label && value) {
        result.push({ label, value });
      }
    }
    if ((permission as IWorkflowPermissionScriptActionServer).script.type === 'document') {
      let label = ((permission as IWorkflowPermissionScriptActionServer).script as IParameterTypeDocument).source;
      if (label?.length) {
        label = `Script aus Dokumentfeld`;
      }
      const value = ((permission as IWorkflowPermissionScriptActionServer).script as IParameterTypeDocument).source;
      if (label && value) {
        result.push({ label, value });
      }
    }
  });
  return result;
};
export const mapActionsServerToClient = (actions: TActionServer[]): TActionClient[] => {
  let result: TActionClient[] = [];
  actions?.forEach(action => {
    switch (action.type) {
      case 'set-value':
        result.push({
          type: 'set-value',
          source: mapParameterTypeServerToClient((action as IWorkflowSetValueActionServer).source),
          destination: mapParameterTypeServerToClient((action as IWorkflowSetValueActionServer).destination),
          variables: action.variables
        } as IWorkflowSetValueActionClient);
        break;
      case 'create-ballot':
        result.push({
          type: action.type,
          ballotIdStore: (action as IWorkflowCreateBallotActionServer).ballotIdStore,
          ballotTemplateId: (action as IWorkflowCreateBallotActionServer).ballotTemplateId,
          caption: (action as IWorkflowCreateBallotActionServer).caption,
          description: (action as IWorkflowCreateBallotActionServer).description,
          documentWorkflowEdgeNameOnFinish: (action as IWorkflowCreateBallotActionServer)
            .documentWorkflowEdgeNameOnFinish,
          documentWorkflowEdgeNameOnAbort: (action as IWorkflowCreateBallotActionServer)
            .documentWorkflowEdgeNameOnAbort,
          voters: mapParameterTypeServerToClient((action as IWorkflowCreateBallotActionServer).voters),
          voteItems: (action as IWorkflowCreateBallotActionServer).voteItems,
          variables: action.variables
        } as IWorkflowCreateBallotActionClient);
        break;
      case 'abort-ballot':
        result.push({
          type: action.type,
          ballotId: mapParameterTypeServerToClient((action as IWorkflowAbortBallotActionServer).ballotId),
          variables: action.variables
        } as IWorkflowAbortBallotActionClient);
        break;
      case 'meeting-abort':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingAbortActivityServer)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingAbortActivityServer).meetingParticipantsFieldName,
          replyTo: mapParameterTypeServerToClient((action as IWorkflowMeetingAbortActivityServer).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingAbortActivityServer).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingAbortActivityServer).speakersFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingAbortActivityServer).recorderEmailTemplateId,
          recorderFieldName: (action as IWorkflowMeetingAbortActivityServer).recorderFieldName,
          variables: action.variables
        } as IWorkflowMeetingAbortActivityClient);
        break;
      case 'meeting-invite':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingInviteActivityServer)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingInviteActivityServer).meetingParticipantsFieldName,
          replyTo: mapParameterTypeServerToClient((action as IWorkflowMeetingInviteActivityServer).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingInviteActivityServer).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingInviteActivityServer).speakersFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingInviteActivityServer).recorderEmailTemplateId,
          recorderFieldName: (action as IWorkflowMeetingInviteActivityServer).recorderFieldName,
          variables: action.variables
        } as IWorkflowMeetingInviteActivityClient);
        break;
      case 'meeting-protocol-approval':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .meetingParticipantsFieldName,
          replyTo: mapParameterTypeServerToClient((action as IWorkflowMeetingProtocolApprovalActivityServer).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityServer).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer).speakersFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityServer).recorderEmailTemplateId,
          recorderFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer).recorderFieldName,
          agendaWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityServer).agendaWorkflowActionName,
          agendaItemProtocolWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemProtocolWorkflowActionName,
          agendaItemTaskWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemTaskWorkflowActionName,
          agendaItemWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemWorkflowActionName,
          agendaSubmissionPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaSubmissionPermissionNamesFieldName,
          agendaItemPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemPermissionNamesFieldName,
          agendaItemProtocolPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemProtocolPermissionNamesFieldName,
          agendaSubmissionWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaSubmissionWorkflowActionName,
          agendaItemTaskPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityServer)
            .agendaItemTaskPermissionNamesFieldName,
          failureMode: (action as IWorkflowMeetingProtocolApprovalActivityServer).failureMode,
          variables: action.variables
        } as IWorkflowMeetingProtocolApprovalActivityClient);
        break;
      case 'send-mail':
        result.push({
          type: action.type,
          body: mapParameterTypeServerToClient((action as IWorkflowSendMailActionServer).body),
          isHtml: mapParameterTypeServerToClient((action as IWorkflowSendMailActionServer).isHtml),
          subject: mapParameterTypeServerToClient((action as IWorkflowSendMailActionServer).subject),
          to: mapParameterTypeServerToClient((action as IWorkflowSendMailActionServer).to),
          withInvite: (action as IWorkflowSendMailActionServer).withInvite,
          replyTo: mapParameterTypeServerToClient((action as IWorkflowSendMailActionServer).replyTo),
          variables: action.variables
        } as IWorkflowSendMailActionClient);
        break;
      case 'send-mail-template':
        result.push({
          type: action.type,
          to: mapParameterTypeServerToClient((action as IWorkflowSendMailTemplateActionServer).to),
          emailTemplateId: (action as IWorkflowSendMailTemplateActionServer).emailTemplateId,
          replyTo: mapParameterTypeServerToClient((action as IWorkflowSendMailTemplateActionServer).replyTo),
          variables: action.variables
        } as IWorkflowSendMailTemplateActionClient);
        break;
      case 'permission-script':
        result.push({
          type: action.type,
          script: mapParameterTypeServerToClient((action as IWorkflowPermissionScriptActionServer).script),
          variables: action.variables
        } as IWorkflowPermissionScriptActionClient);
        break;
      case 'create-decision':
        result.push({
          type: action.type,
          status: (action as IWorkflowCreateDecisionActionServer).status,
          decisionStampId: (action as IWorkflowCreateDecisionActionServer).decisionStampId,
          variableName: (action as IWorkflowCreateDecisionActionServer).variableName,
          variables: action.variables
        } as IWorkflowCreateDecisionActionClient);
        break;
      case 'update-decision-status':
        result.push({
          type: action.type,
          status: (action as IWorkflowUpdateDecisionStatusActionServer).status?.value,
          categories: (action as IWorkflowUpdateDecisionStatusActionServer).categories?.value,
          ignoreMissing: (action as IWorkflowUpdateDecisionStatusActionServer).ignoreMissing,
          variables: action.variables
        } as IWorkflowUpdateDecisionStatusActionClient);
        break;
      case 'update-decision-values':
        result.push({
          type: action.type,
          category: (action as IWorkflowUpdateDecisionValuesActionServer).category?.value,
          variableName: (action as IWorkflowUpdateDecisionValuesActionServer).variableName,
          variables: action.variables
        } as IWorkflowUpdateDecisionValuesActionClient);
        break;
      case 'delete-decision':
        result.push({
          type: action.type,
          categories: (action as IWorkflowDeleteDecisionActionServer).categories?.value,
          ignoreMissing: (action as IWorkflowDeleteDecisionActionServer).ignoreMissing,
          variables: action.variables
        } as IWorkflowDeleteDecisionActionClient);
        break;
      case 'modify-tag':
        {
          if ((action.variables as Record<string, string>)?.modificationTag === 'decision') {
            result.push({
              type:
                (action as IWorkflowModifyTagActionServer).preferAdd.value === true
                  ? 'create-decision-deprecated'
                  : 'remove-decision-deprecated',
              preferAdd: (action as IWorkflowModifyTagActionServer).preferAdd.value === true ? 'add' : 'remove',
              tags: (action as IWorkflowModifyTagActionServer).tags.value,
              variables: action.variables
            } as IWorkflowModifyTagActionClient);
          } else {
            result.push({
              type: action.type,
              preferAdd: (action as IWorkflowModifyTagActionServer).preferAdd.value === true ? 'add' : 'remove',
              tags: (action as IWorkflowModifyTagActionServer).tags.value,
              variables: action.variables
            } as IWorkflowModifyTagActionClient);
          }
        }

        break;
      case 'modify-name-field':
        {
          result.push({
            type: action.type,
            operation: (action as IWorkflowModifyNameFieldActivityServer).operation,
            source: mapParameterTypeServerToClient((action as IWorkflowModifyNameFieldActivityServer).source),
            destination: mapParameterTypeServerToClient((action as IWorkflowModifyNameFieldActivityServer).destination),
            variables: action.variables
          } as IWorkflowModifyNameFieldActivityClient);
        }
        break;
      case 'serial-number-peek':
        {
          result.push({
            type: 'serial-number-peek',
            serialNumberName: (action as IWorkflowPeekSerialNumberActivityServer).serialNumberName,
            target: mapParameterTypeServerToClient((action as IWorkflowPeekSerialNumberActivityServer).target),
            variables: action.variables
          } as IWorkflowPeekSerialNumberActivityClient);
        }
        break;
      case 'serial-number-consume':
        {
          result.push({
            type: 'serial-number-consume',
            serialNumberName: (action as IWorkflowConsumeSerialNumberActivityServer).serialNumberName,
            target: mapParameterTypeServerToClient((action as IWorkflowConsumeSerialNumberActivityServer).target),
            variables: action.variables
          } as IWorkflowConsumeSerialNumberActivityClient);
        }
        break;
      case 'execute-document-workflow':
        {
          result.push({
            type: 'execute-document-workflow',
            edgeName: mapParameterTypeServerToClient(
              (action as IWorkflowExecuteDocumentWorklfowActivityServer).edgeName
            ),
            selectors: (action as IWorkflowExecuteDocumentWorklfowActivityServer).selectors.map(s => ({
              type: s.type,
              filter: flattenSameOperationGroups(mapFilterSelectorServerToClient(s.filter)),
              documentIdPropertyName: s.documentIdPropertyName
            })),
            failureMode: mapFailureModeServerToClient(
              (action as IWorkflowExecuteDocumentWorklfowActivityServer).failureMode
            ),
            deferExecution: (action as IWorkflowExecuteDocumentWorklfowActivityServer).deferExecution,
            variables: action.variables
          } as IWorkflowExecuteDocumentWorkflowActivityClient);
        }
        break;
      case 'copy-permissions':
        {
          result.push({
            type: 'copy-permissions',
            targetSelectors: (action as IWorkflowCopyPermissionsActivityServer).targetSelectors.map(s => ({
              type: s.type,
              filter: flattenSameOperationGroups(mapFilterSelectorServerToClient(s.filter)),
              documentIdPropertyName: s.documentIdPropertyName
            })),
            source: mapCopyPermissionSourceServerToClient((action as IWorkflowCopyPermissionsActivityServer).source),
            sourceType: (action as IWorkflowCopyPermissionsActivityServer).source?.type || null,
            mode: (action as IWorkflowCopyPermissionsActivityServer).mode,
            variables: action.variables
          } as IWorkflowCopyPermissionsActivityClient);
        }
        break;

      case 'create-document':
        result.push({
          type: action.type,
          parentDocumentId: !!(action as IWorkflowCreateDocumentActionServer).parentDocumentId,
          documentTemplateId: (action as IWorkflowCreateDocumentActionServer).documentTemplateId,
          variableName: (action as IWorkflowCreateDocumentActionServer).variableName,
          cloneFields: action.variables['cloneFields'] as boolean,
          showEditor: action.variables['showEditor'] as boolean,
          variables: action.variables
        } as IWorkflowCreateDocumentActionClient);
        break;
      case 'error':
        result.push({
          type: action.type,
          errorMessage: (action as IWorkflowErrorActionServer).errorMessage,
          variables: action.variables
        } as IWorkflowErrorActionClient);
        break;
      case 'compare':
        result.push({
          type: action.type,
          operand1: mapParameterTypeServerToClient((action as IWorkflowCompareActionServer).operand1),
          operand2: mapParameterTypeServerToClient((action as IWorkflowCompareActionServer).operand2),
          operator: (action as IWorkflowCompareActionServer).operator,
          result: mapParameterTypeServerToClient((action as IWorkflowCompareActionServer).result),
          variables: action.variables
        } as IWorkflowCompareActionClient);
        break;
      default:
        result = null;
        break;
    }
  });
  return result;
};

export const mapFailureModeServerToClient = (
  value: TFailureModeExecuteDocumentWorkflow[]
): TFailureModeExecuteDocumentWorkflow[] => {
  if (!value || !value?.length) {
    return ['abort'];
  } else {
    return value;
  }
};

export const mapCopyPermissionSourceClientToServer = (
  value: TSourceCopyPermissionsActivityClient
): TSourceCopyPermissionsActivityServer => {
  if (!value) {
    return null;
  }
  switch (value.type) {
    case 'document-field': {
      const documentField = value as ISourceDocumentField;
      return {
        type: documentField.type,
        fieldName: documentField.fieldName,
        allows: documentField.allows,
        denies: documentField.denies
      } as ISourceDocumentField;
    }
    case 'document-selector': {
      const documentField = value as ISourceDocumentSelectorClient;
      return {
        type: documentField.type,
        selectors: documentField.selectors.map(s => ({
          type: s.type,
          filter: mapFilterSelectorClientToServer(s.filter),
          documentIdPropertyName: s.documentIdPropertyName
        })),
        entryTypes: documentField.entryTypes,
        requiredAllows: documentField.requiredAllows
      } as ISourceDocumentSelector;
    }
    case 'aggregate': {
      const documentField = value as ISourceAggregateClient;
      return {
        type: documentField.type,
        sources: documentField.sources?.map(s => mapCopyPermissionSourceClientToServer(s)),
        aggregation: documentField.aggregation
      } as ISourceAggregate;
    }

    default:
      return null;
  }
};
export const mapCopyPermissionSourceServerToClient = (
  value: TSourceCopyPermissionsActivityServer
): TSourceCopyPermissionsActivityClient => {
  if (!value) {
    return null;
  }
  switch (value.type) {
    case 'document-field': {
      const documentField = value as ISourceDocumentField;
      return {
        type: documentField.type,
        fieldName: documentField.fieldName,
        allows: documentField.allows,
        denies: documentField.denies
      } as ISourceDocumentField;
    }
    case 'document-selector': {
      const documentField = value as ISourceDocumentSelector;
      return {
        type: documentField.type,
        selectors: documentField.selectors.map(s => ({
          type: s.type,
          filter: flattenSameOperationGroups(mapFilterSelectorServerToClient(s.filter)),
          documentIdPropertyName: s.documentIdPropertyName
        })),
        entryTypes: documentField.entryTypes,
        requiredAllows: documentField.requiredAllows
      } as ISourceDocumentSelectorClient;
    }
    case 'aggregate': {
      const documentField = value as ISourceAggregate;
      return {
        type: documentField.type,
        sources: documentField.sources?.map(s => mapCopyPermissionSourceServerToClient(s)),
        aggregation: documentField.aggregation
      } as ISourceAggregateClient;
    }

    default:
      return null;
  }
};

export const flattenSameOperationGroups = (filter: FilterValue[]): FilterValue[] => {
  if (!filter || !Array.isArray(filter) || filter?.length < 3) {
    return filter;
  }

  const [left, operator, right] = filter;
  const groupOperations = ['and', 'or'];

  if (Array.isArray(left) && Array.isArray(right) && (left[1] === operator || right[1] === operator)) {
    const leftOperator = left[1] as string;
    const rightOperator = right[1] as string;
    if (groupOperations.includes(leftOperator) && groupOperations.includes(rightOperator)) {
      if (rightOperator === operator && leftOperator !== operator) {
        return [flattenSameOperationGroups(left), operator, ...flattenSameOperationGroups(right)];
      } else if (rightOperator !== operator && leftOperator === operator) {
        return [...flattenSameOperationGroups(left), operator, flattenSameOperationGroups(right)];
      } else {
        return [...flattenSameOperationGroups(left), operator, ...flattenSameOperationGroups(right)];
      }
    } else if (groupOperations.includes(leftOperator) && !groupOperations.includes(rightOperator)) {
      return [...flattenSameOperationGroups(left), operator, flattenSameOperationGroups(right)];
    } else if (!groupOperations.includes(leftOperator) && groupOperations.includes(rightOperator)) {
      return [flattenSameOperationGroups(left), operator, ...flattenSameOperationGroups(right)];
    } else {
      return [flattenSameOperationGroups(left), operator, flattenSameOperationGroups(right)];
    }
  }

  return [
    flattenSameOperationGroups(left as FilterValue[]),
    operator,
    flattenSameOperationGroups(right as FilterValue[])
  ];
};
export const mapFilterSelectorServerToClient = (value: TFilterSelecor): FilterValue[] => {
  let result: FilterValue[] = null;
  if (!value) {
    return null;
  }
  switch (value.type) {
    case 'tags-includes':
      {
        const f = value as IFilterTagIncludesSelectorExecuteDocumentWorkflow;
        result = ['document', 'tags-includes', f.tagName];
      }
      break;
    case 'workflow-status-equals':
      {
        const f = value as IFilterWfStatusSelectorExecuteDocumentWorkflow;
        result = ['document', 'workflow-status-equals', f.status];
      }
      break;
    case 'not':
      {
        const f = value as IFilterNotSelectorExecuteDocumentWorkflow;
        result = ['!', mapFilterSelectorServerToClient(f.argument)];
      }
      break;
    case 'and':
    case 'or':
      {
        const f = value as IFilterAndOrSelectorExecuteDocumentWorkflow;
        result = [mapFilterSelectorServerToClient(f.left), value.type, mapFilterSelectorServerToClient(f.right)];
      }
      break;

    default:
      break;
  }

  return result;
};
export const mapFilterSelectorClientToServer = (value: FilterValue[]): TFilterSelecor => {
  let result: TFilterSelecor = null;
  if (!value) {
    return null;
  }
  if (value && !Array.isArray(value[0])) {
    if (value[0] === '!') {
      result = {
        type: 'not',
        argument: mapFilterSelectorClientToServer(value[1] as string[])
      } as IFilterNotSelectorExecuteDocumentWorkflow;
    } else {
      result = createFiler(value);
    }
    return result;
  }
  let i = 0;
  while (i < value.length) {
    if (value && Array.isArray(value[i]) && value[i + 1]) {
      if (value[i + 1] === 'and' || value[i + 1] === 'or') {
        result = {
          type: value[i + 1],
          left: !result ? mapFilterSelectorClientToServer(value[i] as string[]) : result,
          right: mapFilterSelectorClientToServer(value[i + 2] as string[])
        } as IFilterAndOrSelectorExecuteDocumentWorkflow;
      } else {
        result = createFiler(value);
      }
    }
    i = i + 2;
  }

  return result;
};
export const createFiler = (
  value: FilterValue[]
): IFilterTagIncludesSelectorExecuteDocumentWorkflow | IFilterWfStatusSelectorExecuteDocumentWorkflow => {
  switch (value[1]) {
    case 'tags-includes':
      return { type: 'tags-includes', tagName: value[2] } as IFilterTagIncludesSelectorExecuteDocumentWorkflow;

    case 'workflow-status-equals':
      return { type: 'workflow-status-equals', status: value[2] } as IFilterWfStatusSelectorExecuteDocumentWorkflow;

    default:
      return null;
  }
};
export const mapActionsClientToServer = (actions: TActionClient[], labels?: ILabel[]): TActionServer[] => {
  let result: TActionServer[] = [];
  actions.forEach(action => {
    switch (action.type) {
      case 'set-value':
        result.push({
          type: 'set-value',
          source: mapParameterTypeClientToServer((action as IWorkflowSetValueActionClient).source),
          destination: mapParameterTypeClientToServer((action as IWorkflowSetValueActionClient).destination),
          variables: action.variables
        } as IWorkflowSetValueActionServer);
        break;
      case 'create-ballot':
        result.push({
          type: action.type,
          ballotIdStore: (action as IWorkflowCreateBallotActionClient).ballotIdStore as IParameterTypeDocument,
          ballotTemplateId: (action as IWorkflowCreateBallotActionClient).ballotTemplateId,
          caption: (action as IWorkflowCreateBallotActionClient).caption,
          description: (action as IWorkflowCreateBallotActionClient).description,
          documentWorkflowEdgeNameOnFinish: (action as IWorkflowCreateBallotActionClient)
            .documentWorkflowEdgeNameOnFinish,
          documentWorkflowEdgeNameOnAbort: (action as IWorkflowCreateBallotActionClient)
            .documentWorkflowEdgeNameOnAbort,
          voters: mapParameterTypeClientToServer((action as IWorkflowCreateBallotActionClient).voters),
          voteItems: mapParameterTypeClientToServer((action as IWorkflowCreateBallotActionClient).voteItems, 'json'),
          variables: action.variables
        } as IWorkflowCreateBallotActionServer);
        break;
      case 'abort-ballot':
        result.push({
          type: action.type,
          ballotId: mapParameterTypeClientToServer((action as IWorkflowAbortBallotActionClient).ballotId),
          variables: action.variables
        } as IWorkflowAbortBallotActionServer);
        break;
      case 'meeting-abort':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingAbortActivityClient)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingAbortActivityClient).meetingParticipantsFieldName,
          replyTo: mapParameterTypeClientToServer((action as IWorkflowMeetingAbortActivityClient).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingAbortActivityClient).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingAbortActivityClient).speakersFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingAbortActivityClient).recorderEmailTemplateId,
          recorderFieldName: (action as IWorkflowMeetingAbortActivityClient).recorderFieldName,
          variables: action.variables
        } as IWorkflowMeetingAbortActivityServer);
        break;
      case 'meeting-invite':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingInviteActivityClient)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingInviteActivityClient).meetingParticipantsFieldName,
          replyTo: mapParameterTypeClientToServer((action as IWorkflowMeetingInviteActivityClient).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingInviteActivityClient).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingInviteActivityClient).speakersFieldName,
          recorderFieldName: (action as IWorkflowMeetingInviteActivityClient).recorderFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingInviteActivityClient).recorderEmailTemplateId,
          variables: action.variables
        } as IWorkflowMeetingInviteActivityServer);
        break;
      case 'meeting-protocol-approval':
        result.push({
          type: action.type,
          meetingParticipantsEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .meetingParticipantsEmailTemplateId,
          meetingParticipantsFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .meetingParticipantsFieldName,
          replyTo: mapParameterTypeClientToServer((action as IWorkflowMeetingProtocolApprovalActivityClient).replyTo),
          speakersEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityClient).speakersEmailTemplateId,
          speakersFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient).speakersFieldName,
          recorderEmailTemplateId: (action as IWorkflowMeetingProtocolApprovalActivityClient).recorderEmailTemplateId,
          recorderFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient).recorderFieldName,
          agendaItemProtocolWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemProtocolWorkflowActionName,
          agendaItemTaskWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemTaskWorkflowActionName,
          agendaWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityClient).agendaWorkflowActionName,
          agendaItemWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemWorkflowActionName,
          agendaSubmissionPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaSubmissionPermissionNamesFieldName,
          agendaItemPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemPermissionNamesFieldName,
          agendaItemProtocolPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemProtocolPermissionNamesFieldName,
          agendaSubmissionWorkflowActionName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaSubmissionWorkflowActionName,
          agendaItemTaskPermissionNamesFieldName: (action as IWorkflowMeetingProtocolApprovalActivityClient)
            .agendaItemTaskPermissionNamesFieldName,
          failureMode: (action as IWorkflowMeetingProtocolApprovalActivityClient).failureMode,
          variables: action.variables
        } as IWorkflowMeetingProtocolApprovalActivityServer);
        break;
      case 'send-mail':
        result.push({
          type: action.type,
          body: mapParameterTypeClientToServer((action as IWorkflowSendMailActionClient).body),
          isHtml: mapParameterTypeClientToServer((action as IWorkflowSendMailActionClient).isHtml),
          subject: mapParameterTypeClientToServer((action as IWorkflowSendMailActionClient).subject),
          to: mapParameterTypeClientToServer((action as IWorkflowSendMailActionClient).to),
          replyTo: mapParameterTypeClientToServer((action as IWorkflowSendMailActionClient).replyTo),
          withInvite: (action as IWorkflowSendMailActionClient).withInvite,
          variables: action.variables
        } as IWorkflowSendMailActionServer);
        break;
      case 'send-mail-template':
        result.push({
          type: action.type,
          to: mapParameterTypeClientToServer((action as IWorkflowSendMailTemplateActionClient).to),
          emailTemplateId: (action as IWorkflowSendMailTemplateActionClient).emailTemplateId,
          replyTo: mapParameterTypeClientToServer((action as IWorkflowSendMailTemplateActionClient).replyTo),
          variables: action.variables
        } as IWorkflowSendMailTemplateActionServer);
        break;
      case 'permission-script':
        result.push({
          type: action.type,
          script: mapParameterTypeClientToServer((action as IWorkflowPermissionScriptActionClient).script),
          variables: action.variables
        } as IWorkflowPermissionScriptActionServer);
        break;
      case 'modify-tag':
        {
          const value = (action as IWorkflowModifyTagActionClient).preferAdd === 'add';
          const parameter = createParameterType('static', { value });
          result.push({
            type: action.type,
            preferAdd: mapParameterTypeClientToServer(parameter),
            tags: mapParameterTagsClientToServer((action as IWorkflowModifyTagActionClient).tags, labels),
            variables: action.variables
          } as IWorkflowModifyTagActionServer);
        }
        break;
      case 'modify-name-field':
        {
          result.push({
            type: action.type,
            operation: (action as IWorkflowModifyNameFieldActivityClient).operation,
            source: mapParameterTypeClientToServer((action as IWorkflowModifyNameFieldActivityClient).source),
            destination: mapParameterTypeClientToServer((action as IWorkflowModifyNameFieldActivityClient).destination),
            variables: action.variables
          } as IWorkflowModifyNameFieldActivityServer);
        }
        break;
      case 'create-decision':
        {
          result.push({
            type: action.type,
            status: (action as IWorkflowCreateDecisionActionClient).status,
            decisionStampId: (action as IWorkflowCreateDecisionActionClient).decisionStampId,
            variableName: (action as IWorkflowCreateDecisionActionClient).variableName,
            variables: action.variables
          } as IWorkflowCreateDecisionActionServer);
        }
        break;
      case 'update-decision-status':
        {
          result.push({
            type: action.type,
            status: createParameterType('static', {
              value: (action as IWorkflowUpdateDecisionStatusActionClient).status
            }),
            categories: createParameterType('static', {
              value: (action as IWorkflowUpdateDecisionStatusActionClient).categories
            }),
            ignoreMissing: (action as IWorkflowUpdateDecisionStatusActionClient).ignoreMissing,
            variables: action.variables
          } as IWorkflowUpdateDecisionStatusActionServer);
        }
        break;
      case 'update-decision-values':
        {
          result.push({
            type: action.type,
            category: createParameterType('static', {
              value: (action as IWorkflowUpdateDecisionValuesActionClient).category
            }),
            variableName: (action as IWorkflowUpdateDecisionValuesActionClient).variableName,
            variables: action.variables
          } as IWorkflowUpdateDecisionValuesActionServer);
        }
        break;
      case 'delete-decision':
        {
          result.push({
            type: action.type,
            categories: createParameterType('static', {
              value: (action as IWorkflowUpdateDecisionStatusActionClient).categories
            }),
            ignoreMissing: (action as IWorkflowUpdateDecisionStatusActionClient).ignoreMissing,
            variables: action.variables
          } as IWorkflowDeleteDecisionActionServer);
        }
        break;
      case 'create-decision-deprecated':
        {
          if (action.variables.modificationTag === 'decision') {
            const value = (action as IWorkflowCreateDecisionActionClientDeprecated).preferAdd === 'add';
            const parameter = createParameterType('static', { value });
            result.push({
              type: 'modify-tag',
              preferAdd: mapParameterTypeClientToServer(parameter),
              tags: { type: 'static', value: (action as IWorkflowCreateDecisionActionClientDeprecated).tags },
              variables: action.variables
            } as IWorkflowModifyTagActionServer);
          }
        }
        break;
      case 'remove-decision-deprecated':
        {
          if (action.variables.modificationTag === 'decision') {
            const value = (action as IWorkflowRemoveDecisionActionClientDeprecated).preferAdd !== 'remove';
            const parameter = createParameterType('static', { value });
            result.push({
              type: 'modify-tag',
              preferAdd: mapParameterTypeClientToServer(parameter),
              tags: { type: 'static', value: (action as IWorkflowRemoveDecisionActionClientDeprecated).tags },
              variables: action.variables
            } as IWorkflowModifyTagActionServer);
          }
        }
        break;

      case 'serial-number-peek':
        {
          result.push({
            type: 'serial-number-peek',
            serialNumberName: (action as IWorkflowPeekSerialNumberActivityClient).serialNumberName,
            target: mapParameterTypeClientToServer((action as IWorkflowPeekSerialNumberActivityClient).target),
            variables: action.variables
          } as IWorkflowPeekSerialNumberActivityServer);
        }
        break;
      case 'serial-number-consume':
        {
          result.push({
            type: 'serial-number-consume',
            serialNumberName: (action as IWorkflowConsumeSerialNumberActivityClient).serialNumberName,
            target: mapParameterTypeClientToServer((action as IWorkflowConsumeSerialNumberActivityClient).target),
            variables: action.variables
          } as IWorkflowConsumeSerialNumberActivityServer);
        }
        break;
      case 'execute-document-workflow':
        {
          result.push({
            type: 'execute-document-workflow',
            edgeName: mapParameterTypeClientToServer(
              (action as IWorkflowExecuteDocumentWorkflowActivityClient).edgeName
            ),
            selectors: (action as IWorkflowExecuteDocumentWorkflowActivityClient).selectors.map(s => ({
              type: s.type,
              filter: mapFilterSelectorClientToServer(s.filter),
              documentIdPropertyName: s.documentIdPropertyName
            })),
            failureMode: (action as IWorkflowExecuteDocumentWorkflowActivityClient).failureMode,
            deferExecution: (action as IWorkflowExecuteDocumentWorkflowActivityClient).deferExecution,
            variables: action.variables
          } as IWorkflowExecuteDocumentWorklfowActivityServer);
        }
        break;
      case 'copy-permissions':
        {
          result.push({
            type: 'copy-permissions',
            targetSelectors: (action as IWorkflowCopyPermissionsActivityClient).targetSelectors.map(s => ({
              type: s.type,
              filter: mapFilterSelectorClientToServer(s.filter),
              documentIdPropertyName: s.documentIdPropertyName
            })),
            source: mapCopyPermissionSourceClientToServer((action as IWorkflowCopyPermissionsActivityClient).source),
            mode: (action as IWorkflowCopyPermissionsActivityClient).mode,
            variables: action.variables
          } as IWorkflowCopyPermissionsActivityServer);
        }
        break;
      case 'create-document':
        {
          const variables = action.variables;
          variables['showEditor'] = (action as IWorkflowCreateDocumentActionClient).showEditor;
          variables['cloneFields'] = (action as IWorkflowCreateDocumentActionClient).cloneFields;
          result.push({
            type: action.type,
            parentDocumentId: (action as IWorkflowCreateDocumentActionClient).parentDocumentId
              ? { type: 'document', source: 'id' }
              : null,
            documentTemplateId: (action as IWorkflowCreateDocumentActionClient).documentTemplateId,
            variableName: (action as IWorkflowCreateDocumentActionClient).variableName,
            variables: variables
          } as IWorkflowCreateDocumentActionServer);
        }
        break;
      case 'error':
        {
          result.push({
            type: action.type,
            errorMessage: (action as IWorkflowErrorActionServer).errorMessage,
            variables: action.variables
          } as IWorkflowErrorActionClient);
        }
        break;
      case 'compare':
        result.push({
          type: action.type,
          operand1: mapParameterTypeClientToServer((action as IWorkflowCompareActionClient).operand1),
          operand2: mapParameterTypeClientToServer((action as IWorkflowCompareActionClient).operand2),
          operator: (action as IWorkflowCompareActionServer).operator,
          result: mapParameterTypeClientToServer((action as IWorkflowCompareActionClient).result),
          variables: action.variables
        } as IWorkflowCompareActionServer);
        break;
      default:
        result = null;
        break;
    }
  });
  return result;
};

export const updateNamesListDataSource = (edge: IEdgeClient): void => {
  let namesListValues: INameListValue[] = [];
  namesListValues.push({ label: 'Empfänger', value: 'assignee', source: 'Empfänger & Berechtigungen' });
  namesListValues.push({
    label: 'Aktueller Empfänger',
    value: 'current-assignee',
    source: 'Empfänger & Berechtigungen'
  });
  namesListValues.push({ label: 'Nächster Empfänger', value: 'next-assignee', source: 'Empfänger & Berechtigungen' });
  namesListValues.push({
    label: 'Vorheriger Empfänger',
    value: 'previous-assignee',
    source: 'Empfänger & Berechtigungen'
  });

  Object.keys(edge.customValues).forEach(key => {
    if (key.startsWith('namesList')) {
      const index = key.split('namesList')[1];
      namesListValues.push({ label: 'Namensliste' + index, value: key, source: 'Variablen' });
    }
    if (key.startsWith('wert')) {
      const index = key.split('wert')[1];
      namesListValues.push({ label: 'Text' + index, value: key, source: 'Variablen' });
    }
    if (key.startsWith('boolean')) {
      const index = key.split('boolean')[1];
      namesListValues.push({ label: 'Boolean' + index, value: key, source: 'Variablen' });
    }
    if (key.startsWith('number')) {
      const index = key.split('number')[1];
      namesListValues.push({ label: 'Zahl' + index, value: key, source: 'Variablen' });
    }
    if (key === 'dialogformularId') {
      namesListValues.push({
        label: 'DialogformularId',
        value: key,
        source: 'Dialogformular'
      });
    }
  });
  if (edge.userInputTemplate) {
    const formularFields = getUserInputTemplateFields(edge.userInputTemplate, 'Workflowformular');
    if (formularFields?.length) {
      namesListValues = namesListValues.concat(formularFields);
    }
  }
  if (edge.userInputTemplateLink) {
    const formularFields = getUserInputTemplateFields(edge.userInputTemplateLink, 'Dialogformular');
    if (formularFields?.length) {
      namesListValues = namesListValues.concat(formularFields);
    }
  }

  edge.namesListValues = sortBy([...namesListValues], 'label');
};

export const getUserInputTemplateFields = (
  template: TemplateClient,
  type: 'Dialogformular' | 'Workflowformular'
): INameListValue[] => {
  const fields: INameListValue[] = [];
  if (!isAssigneeTemplate(template)) {
    template.fields
      .filter(f => !['group', 'placeholder', 'empty'].includes(f.type))
      .forEach(key => {
        fields.push({ label: key.caption, value: key.name, source: type });
      });
  }
  return fields;
};
export const isAssigneeTemplate = (template: ITemplateServer | TemplateClient): boolean => {
  if (!template) {
    return false;
  }
  const tags = template.tags;
  return (Array.isArray(tags) && tags.includes(siamConst.siamAssigneeTag)) || template.name === 'user-fields';
};

export const isWorkflowFormular = (edge: IEdgeClient): boolean => {
  if (!edge?.userInputTemplate) {
    return false;
  }
  const template = edge.userInputTemplate;
  const tags = template.tags;
  if (Array.isArray(tags) && tags.includes(siamConst.siamAssigneeTag)) {
    return false;
  } else if (template.name === 'user-fields') {
    return false;
  }

  return template.fields?.length > 0;
};
export const isAssigneeFormular = (edge: IEdgeClient): boolean => {
  let result = false;
  if (!edge?.userInputTemplate) {
    result = false;
  }
  const template = edge.userInputTemplate;
  const tags = template.tags;
  if (Array.isArray(tags) && tags.includes(siamConst.siamAssigneeTag)) {
    result = false;
  } else if (template.name === 'user-fields') {
    result = false;
  }
  if (template?.fields?.length) {
    result = true;
  }
  if (edge?.userInputTemplateLinkId && edge.userInputTemplateLink) {
    if (edge.userInputTemplateLink?.fields?.length) {
      result = edge.userInputTemplateLink.fields.some(f => f.name === 'assignee' && f.type === 'dxTagBox');
    }
  }
  return result;
};

export const createPermissionTarget = (compositeId: string | unknown): IPermissionTarget => {
  if (typeof compositeId === 'object') {
    if ((compositeId as IPermission).role) {
      return {
        type: 'role',
        targetId: (compositeId as IPermission).role.id
      };
    }
    if ((compositeId as IPermission).user) {
      return {
        type: 'user',
        targetId: (compositeId as IPermission).user.id
      };
    }
  }
  if (typeof compositeId === 'string') {
    const [type, targetId] = compositeId.split(':', 2);
    switch (type) {
      case 'user':
      case 'role':
        return {
          type,
          targetId
        };
    }
  }
  throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
};
export const createCompositeId = (permission: IPermissionTarget): string => {
  if (typeof permission === 'object' && permission.type && permission.targetId) {
    return `${permission.type}:${permission.targetId}`;
  }
  if (
    typeof permission === 'string' &&
    ((permission as string).startsWith('user') || (permission as string).startsWith('role'))
  ) {
    return permission;
  }
  throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
};

export const setDataSourceBallot = (workflow: IWorkflow): INameListValueNode[] =>
  workflow.edges
    .reduce((output, edge) => {
      const createBallot = edge.actions.filter(currentAction => currentAction.type === 'create-ballot');
      createBallot.forEach((currentAction, index) => {
        output.push({
          label: getActionName(currentAction as IWorkflowCreateBallotActionClient, index + 1),
          value: (currentAction as IWorkflowCreateBallotActionServer).ballotIdStore.source,
          edgeLabel: 'WF-Schritt: ' + edge.label
        });
      });
      return output;
    }, [] as INameListValueNode[])
    .concat(
      workflow.vertices.reduce((output, edge) => {
        const createBallotEnter = edge.enterActions.filter(currentAction => currentAction.type === 'create-ballot');
        const createBallotLeave = edge.leaveActions.filter(currentAction => currentAction.type === 'create-ballot');
        createBallotEnter.concat(createBallotLeave).forEach((currentAction, index) => {
          output.push({
            label: getActionName(currentAction as IWorkflowCreateBallotActionClient, index + 1),
            value: (currentAction as IWorkflowCreateBallotActionServer).ballotIdStore.source,
            edgeLabel: 'Knoten: ' + edge.label
          });
        });
        return output;
      }, [] as INameListValueNode[])
    );

export const getActionName = (action: IWorkflowCreateBallotActionClient, index = 0): string => {
  if (action.caption) {
    return action.caption;
  }

  const selectedAction = selectableActions.find(ac => ac.value === action.type);
  const actionLabel = (selectedAction && selectedAction.label) || '';
  return index ? `${actionLabel} ${index}` : actionLabel;
};

export const getCreateDecisionActionName = (workflow: IWorkflowClient): string => {
  const name = 'create-decision';
  const index = getWorkflowActionFromType(workflow, 'create-decision')?.length || 0;
  return `${name}-${index + 1}`;
};
export const getUpdateDecisionActionName = (workflow: IWorkflowClient): string => {
  const name = 'update-decision-values';
  const index = getWorkflowActionFromType(workflow, 'update-decision-values')?.length || 0;
  return `${name}-${index + 1}`;
};

export const getWorkflowActionFromType = (workflow: IWorkflowClient, type: TActionType): TActionClient[] => {
  const actions: TActionClient[] = [];
  // Extract actions from edges
  for (const edge of workflow.edges) {
    for (const action of edge.actions) {
      if (action.type === type) {
        actions.push(action);
      }
    }
  }

  // Extract actions from vertices (enterActions and leaveActions)
  for (const vertex of workflow.vertices) {
    if (vertex.enterActions) {
      for (const action of vertex.enterActions) {
        if (action.type === type) {
          actions.push(action);
        }
      }
    }

    if (vertex.leaveActions) {
      for (const action of vertex.leaveActions) {
        if (action.type === type) {
          actions.push(action);
        }
      }
    }
  }

  return actions;
};
