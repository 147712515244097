import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  IEncryption,
  IListState,
  IPrintPageLayout,
  ISecureRoomConfigBrainLoop,
  ISecureRoomConfigFileSystem,
  TPageSetting,
  TSecureRoomActive
} from '@interfaces/siam';
import { LoggerService } from './logger.service';
import { getCurrentUser } from '@factories/user.factory';
import { clone, isFastDeepEqual } from '@factories/helpers';

interface ISecureRoomActiveResponse {
  active: TSecureRoomActive;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private readonly apiBaseUrl: string;
  private userConfig$ = new BehaviorSubject<Record<string, IListState>>(null);

  constructor(private http: HttpClient, private logger: LoggerService, @Inject('BASE_URL') private baseUrl: string) {
    this.apiBaseUrl = `${this.baseUrl}api/configuration`;
  }

  private get configKey(): string {
    const user = getCurrentUser();
    if (!user) {
      return null;
    }
    return user.id;
  }

  getAccentColor(): Observable<string> {
    return this.http.get<string>(`${this.apiBaseUrl}/accent-color`, { responseType: 'text' as 'json' }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehlermeldung des Servers beim Aufruf von getAccentColors: {@error}', error);
        }
      })
    );
  }

  setBackground(file: File): Observable<File> {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      params: new HttpParams().set('scope', 'global'),
      reportProgress: true
    };
    return this.http.post<File>(`${this.apiBaseUrl}/background-image`, formData, options).pipe(
      tap({
        next: background => {
          this.logger.info('Set background from service = {@background}', background);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern der Hintergrund: {@error}', error);
        }
      })
    );
  }

  setPrintLogo(file: File): Observable<File> {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      params: new HttpParams(),
      reportProgress: true
    };
    return this.http.post<File>(`${this.apiBaseUrl}/print-logo`, formData, options).pipe(
      tap({
        next: logo => {
          this.logger.info('Set printlogo = {@logo}', logo);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern der Drucklogo: {@error}', error);
        }
      })
    );
  }

  getEncryption(): Observable<IEncryption> {
    return this.http.get<IEncryption>(`${this.apiBaseUrl}/av-workaround-encryption`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Encryption: {@error}', error);
        }
      })
    );
  }

  getPrintLogo(): Observable<string> {
    return this.http.get<string>(`${this.apiBaseUrl}/print-logo`, { responseType: 'blob' as 'json' }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Druck-Logo: {@error}', error);
        }
      }),
      filter(response => !!response),
      map(() => {
        const milliseconds = new Date().getTime();
        return `${this.apiBaseUrl}/print-logo?time=${milliseconds}`;
      })
    );
  }

  getBackgroundImage(): Observable<string> {
    const params = new HttpParams().set('scope', 'global');
    return this.http
      .get<string>(`${this.apiBaseUrl}/background-image`, { params, responseType: 'blob' as 'json' })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Erhalten des Hintergrundbild: {@error}', error);
          }
        }),
        map(response => {
          if (response) {
            const milliseconds = new Date().getTime();
            return `${this.apiBaseUrl}/background-image?scope=global&time=${milliseconds}`;
          }
          return null;
        })
      );
  }

  deletePrintLogo(): Observable<File> {
    return this.http.delete<File>(`${this.apiBaseUrl}/print-logo`).pipe(
      tap({
        next: logo => {
          this.logger.info('delete AccentColor from service = {@logo}', logo);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen der Drucklogo: {@error}', error);
        }
      })
    );
  }

  deleteBackgroundImage(): Observable<File> {
    const params = new HttpParams().set('scope', 'global');
    return this.http.delete<File>(`${this.apiBaseUrl}/background-image`, { params }).pipe(
      tap({
        next: background => {
          this.logger.info('delete background image from service = {@background}', background);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen der Hintergrundbild: {@error}', error);
        }
      })
    );
  }

  setWebAppDesign(design: string, scope: 'global' | 'user'): Observable<string> {
    const params = new HttpParams().set('scope', scope);
    const body = new HttpParams().set('design', design);

    return this.http.post<string>(`${this.apiBaseUrl}/web-app-design`, body, {
      headers: this.jsonHeaders(),
      params
    }).pipe(
      tap({
        next: webDesign => {
          this.logger.info('Set Web-App-Design from service = {@webDesign}', webDesign);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des Web-App-Design : {@error}', error);
        }
      })
    );
  }

  getWebAppDesign(scope: 'global' | 'user'): Observable<string> {
    const params = new HttpParams().set('scope', scope);
    return this.http
      .get<string>(`${this.apiBaseUrl}/web-app-design`, {
        headers: this.jsonHeaders(),
        params,
        responseType: 'text' as 'json'
      }).pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Aufruf von getWebAppDesign: {@error}', error);
          }
        }));
  }

  setWebAppBackgroundImageName(bgImageName: string, scope: 'global' | 'user'): Observable<string> {
    const params = new HttpParams().set('scope', scope);
    const body = new HttpParams().set('name', bgImageName);

    return this.http.post<string>(`${this.apiBaseUrl}/web-app-background-image-name`, body, {
      headers: this.jsonHeaders(),
      params
    }).pipe(
      tap({
        next: backgroundImg => {
          this.logger.info('Set Web-App-Background-Image-Name from service = {@backgroundImg}', backgroundImg);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des Web-App-Background-Image-Name : {@error}', error);
        }
      })
    );
  }

  getWebAppBackgroundImageName(scope: 'global' | 'user'): Observable<string> {
    const params = new HttpParams().set('scope', scope);
    return this.http
      .get<string>(`${this.apiBaseUrl}/web-app-background-image-name`, {
        headers: this.jsonHeaders(),
        params,
        responseType: 'text' as 'json'
      })
      .pipe(
        tap({
          next: backgroundImg => {
            this.logger.info('Set Web-App-Background-Image-Name from service = {@backgroundImg}', backgroundImg);
          },
          error: error => {
            this.logger.error('Fehler beim Aufruf von getWebAppBackgroundImageName: {@error}', error);
          }
        }));
  }

  resolveAppBackgroud(): Observable<string> {
    return this.getWebAppBackgroundImageName('user').pipe(map(backgroud => backgroud || 'pattern'));
  }

  setWebAppDesignGlobalOnly(globalOnly: boolean): Observable<boolean> {
    const body = new HttpParams().set('globalOnly', JSON.stringify(globalOnly));

    return this.http.post<boolean>(`${this.apiBaseUrl}/web-app-design-global-only`, body, { headers: this.jsonHeaders() })
      .pipe(
        tap({
          next: webDesign => {
            this.logger.info('Set Web-App-Design-Global-Only from service = {@webDesign}', webDesign);
          },
          error: error => {
            this.logger.error('Fehler beim Speichern des Web-App-Design-Global-Only : {@error}', error);
          }
        }));
  }

  getWebAppDesignGlobalOnly(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiBaseUrl}/web-app-design-global-only`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehlermeldung des Servers beim Aufruf von Web-App-Design-Global-Only: {@error}', error);
        }
      })
    );
  }

  setAppTitle(title: string): Observable<string> {
    let body = new HttpParams();
    if (title) {
      body = body.set('appTitle', title);
    }

    return this.http.post<string>(`${this.apiBaseUrl}/app-title`, body, { headers: this.jsonHeaders() }).pipe(
      tap({
        next: appTitle => {
          this.logger.info('Set App-Title from service = {@appTitle}', appTitle);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des App-Title : {@error}', error);
        }
      })
    );
  }

  getAppTitle(): Observable<string> {
    return this.http.get<string>(`${this.apiBaseUrl}/app-title`, {
      headers: this.jsonHeaders(),
      responseType: 'text' as 'json'
    }).pipe(
      tap({
        next: appTitle => {
          this.logger.info('delete App-Title from service = {@appTitle}', appTitle);
        },
        error: error => {
          this.logger.error('Fehler beim Aufruf von getAppTitle: {@error}', error);
        }
      }));
  }

  getDefaultUserRole(): Observable<string> {
    return this.http
      .get<string>(`${this.apiBaseUrl}/account-default-user-role-name`, { responseType: 'text' as 'json' })
      .pipe(
        tap({
          next: appTitle => {
            this.logger.info('Get DefaultUserRole from service = {@appTitle}', appTitle);
          },
          error: error => {
            this.logger.error('Fehler beim Erhalten des DefaultUserRole: {@error}', error);
          }
        })
      );
  }

  setFeedbackEmail(email: string): Observable<string> {
    const body = new HttpParams().set('email', email);

    return this.http.post<string>(`${this.apiBaseUrl}/feedback-email`, body, { headers: this.jsonHeaders() }).pipe(
      tap({
        next: feedbackEmail => {
          this.logger.info('Set Feedback-Email from service = {@feedbackEmail}', feedbackEmail);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des Feedback-Email : {@error}', error);
        }
      })
    );
  }

  getFeedbackEmail(): Observable<string> {
    return this.http.get<string>(`${this.apiBaseUrl}/feedback-email`, {
      headers: this.jsonHeaders(),
      responseType: 'text' as 'json'
    })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Aufruf von getFeedbackEmail: {@error}', error);
          }
        })
      );
  }

  deletefeedbackEmail(): Observable<string> {
    return this.http.delete<string>(`${this.apiBaseUrl}/feedback-email`, { responseType: 'text' as 'json' }).pipe(
      tap({
        next: feedbackEmail => {
          this.logger.info('delete Feedback-Email from service = {@appTitle}', feedbackEmail);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Feedback-Email: {@error}', error);
        }
      })
    );
  }

  loadListStates(): Observable<Record<string, IListState>> {
    const states = this.userConfig$.getValue();
    if (states) {
      return of(null);
    }

    const configKey = this.configKey;
    if (!configKey) {
      return of(null);
    }

    return of({});

    // -------- START temporary disabled ---------

    // const headers = new HttpHeaders({
    //   /* eslint-disable */
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   Accept: 'application/json',
    //   /* eslint-enable */
    // });
    // const params = new HttpParams().set('scope', 'user');
    // return this.http.get<Record<string, IListState>>(`${this.apiBaseUrl}/json/${configKey}`, {
    //   headers,
    //   params
    // })
    //   .pipe(
    //     tap({next:config => {
    //       this.userConfig$.next(config);
    //     },
    //     error: error => {
    //        this.logger.error('Fehler beim Aufruf von getWebAppBackgroundImageName: {@error}', error);
    //     }
    //     })
    //   );

    // -------- END temporary disabled ---------
  }

  loadListState(listName: string): IListState {
    const states = this.userConfig$.getValue();
    if (states) {
      return clone<IListState>(states[listName]);
    }
    return null;
  }

  saveListState(listName: string, state: IListState): Observable<void> {
    const configKey = this.configKey;
    if (!configKey) {
      return of(null);
    }

    let states = this.userConfig$.getValue();
    if (states) {
      const currentState = states[listName];
      if (isFastDeepEqual(currentState, state)) {
        return of(null);
      }
    } else {
      states = {};
    }
    delete state.grid?.selectedRowKeys;
    states[listName] = clone<IListState>(state);
    this.userConfig$.next(states);

    return of(null);

    // -------- START temporary disabled ---------

    // const headers = new HttpHeaders({
    //   accept: 'application/json',
    // });
    //
    // return this.http.post<void>(`${this.apiBaseUrl}/json/${configKey}?scope=user`, states, { headers })
    //   .pipe(
    //         tap({
    //           error: error => {
    //             this.logger.error('Fehler beim Speichern des Feedback-Email: {@error}', error);
    //           }
    //         })
    //   );

    // -------- END temporary disabled ---------
  }

  setPrintPageLayout(printPageLayout: IPrintPageLayout): Observable<IPrintPageLayout> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    return this.http.post<IPrintPageLayout>(`${this.apiBaseUrl}/print-page-layout`, printPageLayout, { headers }).pipe(
      tap({
        next: pageLayout => {
          this.logger.info('Set print-page-layout from service = {@pageLayout}', pageLayout);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des print-page-layout : {@error}', error);
        }
      })
    );
  }

  getPrintPageLayout(): Observable<IPrintPageLayout> {
    return this.http.get<IPrintPageLayout>(`${this.apiBaseUrl}/print-page-layout`, { headers: this.jsonHeaders() }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Aufruf von getPrintPageLayout: {@error}', error);
        }
      })
    );
  }

  deletePrintPageLayout(): Observable<IPrintPageLayout> {
    return this.http.delete<IPrintPageLayout>(`${this.apiBaseUrl}/print-page-layout`).pipe(
      tap({
        next: pageLayout => {
          this.logger.info('delete print-page-layout from service = {@pageLayout}', pageLayout);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des print-page-layout: {@error}', error);
        }
      })
    );
  }

  saveJson(configKey: string, scope: 'global' | 'user', settings: TPageSetting): Observable<unknown> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    const params = new HttpParams().set('scope', scope);
    return this.http.post<TPageSetting>(`${this.apiBaseUrl}/json/${configKey}`, settings, { headers, params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Speichern des JSON-Object: {@error}', error);
        }
      })
    );
  }

  getJson(configKey: string, scope: 'global' | 'user'): Observable<TPageSetting> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    const params = new HttpParams().set('scope', scope);
    return this.http.get<TPageSetting>(`${this.apiBaseUrl}/json/${configKey}`, { headers, params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Aufruf von JSON-Object: {@error}', error);
        }
      })
    );
  }

  setSignatureBackgroundImage(file: File): Observable<File> {
    const formData = new FormData();
    formData.append('image', file);
    const options = {
      params: new HttpParams(),
      reportProgress: true
    };
    return this.http.post<File>(`${this.apiBaseUrl}/signature-background-image`, formData, options).pipe(
      tap({
        next: logo => {
          this.logger.info('Set Signature Background Image = {@logo}', logo);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern der Signature Background Image: {@error}', error);
        }
      })
    );
  }

  getSignatureBackgroundImage(): Observable<string> {
    return this.http
      .get<string>(`${this.apiBaseUrl}/signature-background-image`, { responseType: 'blob' as 'json' })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Erhalten des Signature-Background-Image: {@error}', error);
          }
        }),
        filter(response => !!response),
        map(() => {
          const milliseconds = new Date().getTime();
          return `${this.apiBaseUrl}/signature-background-image?time=${milliseconds}`;
        })
      );
  }

  deleteSignatureBackgroundImage(): Observable<File> {
    return this.http.delete<File>(`${this.apiBaseUrl}/signature-background-image`).pipe(
      tap({
        next: logo => {
          this.logger.info('delete Signature Background Image from service = {@logo}', logo);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen der Signature Background Image: {@error}', error);
        }
      })
    );
  }

  getSecureRoomActive(): Observable<TSecureRoomActive> {
    return this.http.get<ISecureRoomActiveResponse>(`${this.apiBaseUrl}/secure-dataroom`)
      .pipe(map(response => response.active));
  }

  getSecureRoomConfig(active: TSecureRoomActive): Observable<ISecureRoomConfigBrainLoop | ISecureRoomConfigFileSystem> {
    switch (active) {
      case 'brainloop':
        return this.http.get<ISecureRoomConfigBrainLoop>(`${this.apiBaseUrl}/brainloop`);

      case 'fileSystem':
        return this.http.get<ISecureRoomConfigFileSystem>(`${this.apiBaseUrl}/filesystem-dataroom`);
    }
  }

  saveSecureRoomConfig(active: TSecureRoomActive, config: ISecureRoomConfigBrainLoop | ISecureRoomConfigFileSystem): Observable<void> {
    return this.setSecureRoomActive(active).pipe(
      switchMap(() => {
        switch (active) {
          case 'brainloop':
            return this.http.post<void>(`${this.apiBaseUrl}/brainloop`, config);

          case 'fileSystem':
            return this.http.post<void>(`${this.apiBaseUrl}/filesystem-dataroom`, config);
        }
      })
    );
  }

  private setSecureRoomActive(active: TSecureRoomActive): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/secure-dataroom`, { active });
  }

  private jsonHeaders(): HttpHeaders {
    return new HttpHeaders({
      /* eslint-disable */
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json'
      /* eslint-enable */
    });
  }
}
